<template>
  <VDialog v-model="modelVisible">
    <div class="add-schedule-price-pop-up">
      <price-option
        :price-option="priceOption"
        :translations="translations"
        :tour="tour"
        :step="0"
        @back="modelVisible = false"
        @save="save"
        @close="modelVisible = false"
      />
    </div>
    <no-members :translations="translations" :no-members="noMembers" :slug="tour.slug" @close="modelVisible = false" />
  </VDialog>
</template>

<script>
import { mapState } from 'vuex'
import { mapMembers, getAvailableDays, getAvailableOpeningTimes } from '@/utils/price'
import VDialog from '@/components/common/VDialog.vue'
import PriceOption from '@/components/price/PriceOption.vue'
import NoMembers from '@/components/price/NoMembers.vue'
import { getPriceDayNames } from '~/utils/date'

export default {
  components: {
    VDialog,
    PriceOption,
    NoMembers
  },
  props: {
    isActive: { type: Boolean, default: false },
    tour: { type: Object, default: () => ({}) },
    tourLang: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) },
    scheduleId: { type: Number, default: 0 },
    addTimes: { type: Boolean, default: false },
    scheduleType: { type: Object, default: () => ({}) },
    openingHours: { type: Boolean, default: false }
  },
  data: () => ({
    priceOption: {
      members: [
        {
          groupCount: null,
          price: null,
          age_category: {
            name: ''
          }
        }
      ],
      addTimes: false,
      openingHours: false,
      anotherTimes: [{ time: null, is_active: false }],
      openingScheduleTimes: [{ startTime: null, endTime: null }],
      days: [
        { name: 'Mon', day: '1', is_active: false },
        { name: 'Tue', day: '2', is_active: false },
        { name: 'Wed', day: '3', is_active: false },
        { name: 'Thu', day: '4', is_active: false },
        { name: 'Fri', day: '5', is_active: false },
        { name: 'Sat', day: '6', is_active: false },
        { name: 'Sun', day: '7', is_active: false }
      ],
      activeDaysCount: 0,
      allDaysChecked: false,
      tieredPricing: false
    },
    noMembers: false,
    lang: ''
  }),
  async fetch() {
    this.lang = this.$cookies.get('i18n_redirected')
		this.priceOption.days = getPriceDayNames(this.lang);
    this.priceOption.members = await this.$axios.$get(
      `/api/v1/get-tour-members/?tour_id=${this.tour.id}&language=${this.tourLang}`
    )
    this.priceOption.addTimes = this.addTimes
    this.priceOption.openingHours = this.openingHours

    this.priceOption.members.sort(function (a, b) {
      return b.max_age - a.max_age
    })
    if (this.priceOption.members.length !== 0) {
      this.priceOption.members = mapMembers(
        this.priceOption.members,
        this.tour.is_group_pricing,
        this.tour.max_travelers,
        await this.$axios.$get(`/api/v1/pages/lk-popup-prices?language=${this.tourLang}`),
      )
    } else {
      this.noMembers = true
    }
  },

  computed: {
    ...mapState(['userInfo']),

    modelVisible: {
      get() {
        return this.isActive
      },
      set(visible) {
        this.$emit('update:isActive', visible)
      }
    }
  },

  methods: {
    getAvailableDays,
    getAvailableOpeningTimes,
    async save(data) {
      this.priceOption = data
      this.$emit('loading')

      const weekSchedule = await this.$axios.$put(`/api/v1/set-week-schedule/`, null, {
        params: {
          week_schedule_id: this.scheduleId,
          available_time: this.getAvailableOpeningTimes(
            this.priceOption.openingHours,
            this.priceOption.addTimes,
            this.priceOption.openingScheduleTimes,
            this.priceOption.anotherTimes
          ),
          weekdays: this.getAvailableDays(this.priceOption.days)
        }
      })

      if (this.tour.is_group_pricing) {
        await this.$axios.$put(
          `/api/v1/set-week-schedule/?week_schedule_id=${weekSchedule.id}&max_travelers=${this.priceOption.members[0].groupCount}`
        )
        if (this.tour.travelers.length > 0) {
          this.$axios.$post(
            `/api/v1/add-tour-schedule-member/?week_schedule_id=${weekSchedule.id}&member_id=${this.tour.travelers[0].id}&price=${this.priceOption.members[0].price}`
          )
        } else {
          const getGroupMembers = await this.$axios.$get(`/api/v1/get-members/?tour_id=${this.tour.id}&language=${this.lang}`)
          await this.$axios.$post(
            `/api/v1/add-tour-member/?tour_id=${this.tour.id}&age_category_id=${getGroupMembers[0].id}&min_age=1&max_age=100`
          )
          await this.$axios.$post(
            `/api/v1/add-tour-schedule-member/?week_schedule_id=${weekSchedule.id}&member_id=${this.priceOption.members[0].id}&price=${this.priceOption.members[0].price}`
          )
        }
      } else if (!this.priceOption.tieredPricing) {
        for (const i in this.priceOption.members) {
          if (this.priceOption.members[i].is_active) {
            this.$axios.$post(
              `/api/v1/add-tour-schedule-member/?week_schedule_id=${weekSchedule.id}&member_id=${this.priceOption.members[i].id}&price=${this.priceOption.members[i].price}`
            )
          }
        }
      } else {
        for (const i in this.priceOption.members) {
          if (this.priceOption.members[i].is_active) {
            for (const ti in this.priceOption.members[i].ageTieredGroups) {
              this.$axios.$post(
                `/api/v1/add-tour-schedule-member/?week_schedule_id=${weekSchedule.id}&member_id=${this.priceOption.members[i].id}&price=${this.priceOption.members[i].ageTieredGroups[ti].price}&count_to=${this.priceOption.members[i].ageTieredGroups[ti].maxAge}&count_from=${this.priceOption.members[i].ageTieredGroups[ti].minAge}`
              )
            }
          }
        }
      }

      this.$router.go(this.localePath('/'))
    }
  }
}
</script>

<style lang="scss">
.add-schedule-price-pop-up {
  position: relative;
  background: $white;
  width: 800px;
}
</style>
