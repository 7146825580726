<template>
  <div v-if="highlights.length" id="basic-info" class="preview__section">
    <h3 class="preview__title">
      {{ translations.highlights.text }}
    </h3>
    <template v-if="tourHighlights.more">
      <div v-for="highlight in tourHighlights.start" :key="`highlight-${highlight.id}`" class="preview__include">
        <div class="preview__dot preview__dot--blue" />
        <span class="preview__include-text">{{ highlight.description }}</span>
      </div>
      <span v-if="!showTourHighlights" class="preview__read-more" @click="showTourHighlights = true">{{ translations.textReadMore.text }}</span>
      <template v-if="showTourHighlights">
        <div v-for="highlight in tourHighlights.more" :key="`highlight-${highlight.id}`" class="preview__include">
          <div class="preview__dot preview__dot--blue" />
          <span class="preview__include-text">{{ highlight.description }}</span>
        </div>
        <span class="preview__read-more" @click="showTourHighlights = false">{{ translations.textHide.text }}</span>
      </template>
    </template>
    <template v-else>
      <div v-for="highlight in tourHighlights" :key="`highlight-${highlight.id}`" class="preview__include">
        <div class="preview__dot preview__dot--blue" />
        <span class="preview__include-text">{{ highlight.description }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HighlightsPreview',
  props: {
    highlights: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      showTourHighlights: false
    }
  },

  computed: {
    tourHighlights () {
      return this.highlights?.length > 4
        ? { start: this.highlights.slice(0, 4), more: this.highlights.slice(4) }
        : this.highlights
    }
  }
}
</script>

<style lang="scss">
</style>
