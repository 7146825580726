var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-price-page" },
    [
      _vm.loadingPage
        ? _c(
            "div",
            [
              _c("local-loader"),
              _vm._v(" "),
              _c("div", { staticClass: "index-price-page__loading-title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.translations.loader.text) + "\n    "
                ),
              ]),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.isBuild ? _c("BackLink") : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.options, function (option, optionIndex) {
                return _c(
                  "div",
                  {
                    key: `option-${optionIndex}`,
                    staticClass: "index-price-page__tickets",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "index-price-page__tickets--title" },
                      [_vm._v("\n        " + _vm._s(option.name) + "\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(option.additional_info, function (info, infoIndex) {
                      return _c(
                        "div",
                        {
                          key: `info-${infoIndex}`,
                          staticClass: "index-price-page__additional-info",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "index-price-page__additional-info--name",
                            },
                            [_vm._v(_vm._s(info.name) + ":")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "index-price-page__additional-info--description",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(info.description) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "index-price-page__settings" },
                      [
                        _vm.options.length > 1 && !_vm.isSpacialOfferNow
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "index-price-page__settings--del-option",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteOption(optionIndex)
                                  },
                                },
                              },
                              [
                                _c("base-icon", {
                                  attrs: { "icon-name": "Trash" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("v-btn", {
                          staticClass: "edit-option",
                          attrs: {
                            outline: "",
                            name: _vm.translations.editBtn.text,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openEditOption(option.id, optionIndex)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(option.schedules, function (ticket, ticketIndex) {
                      return _c(
                        "div",
                        {
                          key: `ticket-${ticketIndex}`,
                          staticClass: "index-price-page__price",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "index-price-page__price--header" },
                            [
                              ticket.end_date <= "2098-03-12" &&
                              ticket.end_date <= "2098-02-12"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "index-price-page__price--date",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.showPriceDate(ticket.start_date)
                                          ) +
                                          "\n            " +
                                          _vm._s(
                                            _vm.showPriceDate(ticket.end_date)
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "index-price-page__price--date",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.showPriceDate(ticket.start_date)
                                          ) +
                                          "\n            "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.translations.noEndDate.text
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.editTicketSchedule(
                                        optionIndex,
                                        ticketIndex
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("base-icon", {
                                    staticClass:
                                      "index-price-page__price--edit",
                                    attrs: { "icon-name": "Edit" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.tour.is_group_pricing
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "index-price-page__price--type",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.translations.maxPerBooking.text
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm.tour.max_travelers_per_booking
                                          ) +
                                          ")\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            ticket.price_schedules,
                            function (priceSchedule, priceScheduleIndex) {
                              return _c(
                                "div",
                                {
                                  key: `priceSchedule-${priceScheduleIndex}`,
                                  staticClass: "index-price-page__price--body",
                                  on: {
                                    mouseleave: function ($event) {
                                      _vm.showEditMenu = null
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "index-price-page__row" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.getDays(priceSchedule.weekdays)
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "index-price-page__row" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.getTimes(
                                              priceSchedule.available_time,
                                              ticket.schedule_type.option
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    priceSchedule.members,
                                    function (member, memberIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: `member-${memberIndex}`,
                                          staticClass:
                                            "index-price-page__member",
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.tour.is_group_pricing
                                                    ? _vm.translations
                                                        .travelerGroup.text
                                                    : member.member.age_category
                                                        .name
                                                ) +
                                                "\n            "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          member.count_to != 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "index-price-page__member--count",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              (" +
                                                      _vm._s(
                                                        member.count_from
                                                      ) +
                                                      "-" +
                                                      _vm._s(member.count_to) +
                                                      ")\n            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            "\n            : " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  member.price_without_discount,
                                                  _vm.lang
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.tour.is_group_pricing
                                    ? _c("div", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.translations.maxPerBooking
                                                .text
                                            ) +
                                            " (" +
                                            _vm._s(
                                              priceSchedule.max_travelers
                                            ) +
                                            ")\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openTicketSettings(
                                            optionIndex,
                                            ticketIndex,
                                            priceScheduleIndex
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("base-icon", {
                                        staticClass:
                                          "index-price-page__edit-btn",
                                        attrs: { "icon-name": "Edit" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.showEditMenu ==
                                  `ticket-${optionIndex}-${ticketIndex}-${priceScheduleIndex}`
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "index-price-page__menu",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "index-price-page__menu--item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editTicketPrices(
                                                    optionIndex,
                                                    ticketIndex,
                                                    priceScheduleIndex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.translations.kebabItem1
                                                      .text
                                                  ) +
                                                  "\n            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          priceScheduleIndex == 0 &&
                                          _vm.addNewPricingWork
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "index-price-page__menu--item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addNewSchedulePrices(
                                                        option.id,
                                                        ticket.schedule_type
                                                          .title,
                                                        ticketIndex,
                                                        priceScheduleIndex,
                                                        optionIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.translations
                                                          .kebabItem2.text
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          option.schedules.length > 1 &&
                                          priceScheduleIndex == 0 &&
                                          !_vm.isSpacialOfferNow
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "index-price-page__menu--item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteTicket(
                                                        optionIndex,
                                                        ticketIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.translations
                                                          .kebabItem3.text
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          priceScheduleIndex != 0 &&
                                          !_vm.isSpacialOfferNow
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "index-price-page__menu--item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deletePrices(
                                                        priceSchedule.id,
                                                        priceScheduleIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.translations
                                                          .kebabItem4.text
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            }
                          ),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "index-price-page__add-ticket",
                        on: {
                          click: function ($event) {
                            return _vm.addNewSchedule(option.id, option.name)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.translations.addSchedule.text) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "index-price-page__tickets--note" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.translations.addScheduleNote.text) +
                            "\n      "
                        ),
                      ]
                    ),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "index-price-page__options" },
                [
                  _c(
                    "div",
                    { staticClass: "index-price-page__options--info" },
                    [
                      _c(
                        "div",
                        { staticClass: "index-price-page__options--title" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.addOption.text) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.translations.addOptionDescription.text) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-btn", {
                    attrs: {
                      outline: "",
                      name: _vm.translations.addOptionBtn.text,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getAddOptionTranslate()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.errorEmpty
                ? _c("div", { staticClass: "validation-error" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.translations.descriptionSection.text) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isBuild
                ? _c("v-btn", {
                    attrs: {
                      base: "",
                      name: _vm.isBuild
                        ? _vm.translations.btnSaveAndCont.text
                        : "Save",
                    },
                    on: { click: _vm.nextStep },
                  })
                : _vm._e(),
            ],
            2
          ),
      _vm._v(" "),
      _c("v-confirm-dialog", {
        ref: "deleteDialog",
        attrs: {
          title: _vm.translations.contentDelete.text,
          text: _vm.translations.deleteDescription.text,
          "confirm-text": _vm.translations.yesBtn.text,
          "cancel-text": _vm.translations.noBtn.text,
        },
      }),
      _vm._v(" "),
      _vm.editSchedule
        ? _c("edit-schedule", {
            attrs: {
              id: _vm.editTicketInfo.id,
              "is-active": _vm.editSchedule,
              tour: _vm.tour,
              "tour-lang": _vm.lang,
              "tour-start-time": _vm.editTicketInfo.startTime,
              "tour-end-time": _vm.editTicketInfo.endTime,
              "edit-translate": _vm.scheduleEditPageFields,
            },
            on: {
              "update:isActive": function ($event) {
                _vm.editSchedule = $event
              },
              "update:is-active": function ($event) {
                _vm.editSchedule = $event
              },
              close: function ($event) {
                _vm.editSchedule = !_vm.editSchedule
              },
              loading: function ($event) {
                _vm.loading = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.editPriceKey > 0 && _vm.editPrices
        ? _c("edit-prices", {
            attrs: {
              "is-active": _vm.editPrices,
              tour: _vm.tour,
              "tour-lang": _vm.lang,
              "tour-members": _vm.editTicketInfo.members,
              "ticket-index": _vm.editTicketInfo.index,
              "price-schedule": _vm.editTicketInfo.priceSchedule,
              "schedule-type": _vm.editTicketInfo.scheduleType,
              translations: _vm.pricePageFields,
            },
            on: {
              "update:isActive": function ($event) {
                _vm.editPrices = $event
              },
              "update:is-active": function ($event) {
                _vm.editPrices = $event
              },
              close: function ($event) {
                _vm.editPrices = !_vm.editPrices
              },
              loading: function ($event) {
                _vm.loading = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.addSchedule
        ? _c("add-schedule", {
            attrs: {
              "is-active": _vm.addSchedule,
              tour: _vm.tour,
              "tour-lang": _vm.lang,
              "option-id": _vm.optionId,
              "option-title": _vm.optionTitle,
              "schedule-translate": _vm.scheduleAddPageFields,
              "prices-translate": _vm.pricePageFields,
              "improve-options-translate": _vm.improveOptionsPageFields,
            },
            on: {
              "update:isActive": function ($event) {
                _vm.addSchedule = $event
              },
              "update:is-active": function ($event) {
                _vm.addSchedule = $event
              },
              close: function ($event) {
                _vm.addSchedule = !_vm.addSchedule
              },
              loading: function ($event) {
                _vm.loading = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.addOption
        ? _c("add-option", {
            attrs: {
              "is-active": _vm.addOption,
              tour: _vm.tour,
              "tour-lang": _vm.lang,
              translations: _vm.optionsPageFields,
              "schedule-translate": _vm.scheduleAddPageFields,
              "prices-translate": _vm.pricePageFields,
              "improve-options-translate": _vm.improveOptionsPageFields,
            },
            on: {
              "update:isActive": function ($event) {
                _vm.addOption = $event
              },
              "update:is-active": function ($event) {
                _vm.addOption = $event
              },
              close: function ($event) {
                _vm.addOption = !_vm.addOption
              },
              loading: function ($event) {
                _vm.loading = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.editOption
        ? _c("edit-option", {
            attrs: {
              "is-active": _vm.editOption,
              tour: _vm.tour,
              "option-id": _vm.optionId,
              "edit-option-info": _vm.editOptionInfo,
              "tour-lang": _vm.lang,
              translations: _vm.optionsPageFields,
            },
            on: {
              "update:isActive": function ($event) {
                _vm.editOption = $event
              },
              "update:is-active": function ($event) {
                _vm.editOption = $event
              },
              close: function ($event) {
                _vm.editOption = !_vm.editOption
              },
              loading: function ($event) {
                _vm.loading = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.addSchedulePrices
        ? _c("add-schedule-prices", {
            attrs: {
              "is-active": _vm.addSchedulePrices,
              tour: _vm.tour,
              "tour-lang": _vm.lang,
              "schedule-id": _vm.scheduleId,
              "add-times":
                _vm.editScheduleTypeName == _vm.translations.scheduleType1.text,
              "opening-hours":
                _vm.editScheduleTypeName == _vm.translations.scheduleType2.text,
              translations: _vm.pricePageFields,
              "ticket-index": _vm.ticketIndex,
            },
            on: {
              "update:isActive": function ($event) {
                _vm.addSchedulePrices = $event
              },
              "update:is-active": function ($event) {
                _vm.addSchedulePrices = $event
              },
              close: function ($event) {
                _vm.addSchedulePrices = !_vm.addSchedulePrices
              },
              loading: function ($event) {
                _vm.loading = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }