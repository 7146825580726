var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "_container" }, [
          _c("div", { staticClass: "header__inner" }, [
            _c(
              "div",
              { staticClass: "nav" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "logo",
                    attrs: { to: _vm.localePath("/"), tag: "div" },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/header/logo.svg"),
                        alt: "logo",
                      },
                    }),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/bookings") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerBookings.text) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/finance") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerFinance.text) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/product") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerProduct.text) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/availability") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerAvailability.text) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/performance") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerPerformance.text) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/reviews") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerReviews.text) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header__right" },
              [
                _c(
                  "div",
                  {
                    staticClass: "account",
                    on: {
                      click: function ($event) {
                        _vm.showMenu = !_vm.showMenu
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "account__name" }, [
                      _vm._v(
                        _vm._s(_vm.getFirstName) +
                          " (" +
                          _vm._s(_vm.userInfo?.display_id ?? "") +
                          ")"
                      ),
                    ]),
                    _vm._m(0),
                    _vm.showMenu
                      ? _c(
                          "div",
                          {
                            staticClass: "account__menu",
                            on: {
                              mouseleave: function ($event) {
                                _vm.showMenu = false
                              },
                            },
                          },
                          [
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "account-edit",
                                attrs: {
                                  to: _vm.localePath(
                                    !_vm.userInfo.is_seller_company
                                      ? "/account/private-information"
                                      : "/account/business-information"
                                  ),
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "gear",
                                  attrs: {
                                    src: require("@/assets/img/svg/profile.svg"),
                                    alt: "profile",
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.headerTranslate.headerKebabAccount
                                        .text
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "language",
                                on: {
                                  click: function ($event) {
                                    _vm.selectLanguage = !_vm.selectLanguage
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "language__image",
                                  attrs: {
                                    src: require("@/assets/img/svg/language.svg"),
                                    alt: "language",
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.headerTranslate.headerKebabLanguage
                                        .text
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "logout",
                                attrs: { to: _vm.localePath("/logout") },
                              },
                              [
                                _c("img", {
                                  staticClass: "exit",
                                  attrs: {
                                    src: require("@/assets/img/svg/logout.svg"),
                                    alt: "logout",
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.headerTranslate.headerKebabLogout.text
                                    ) +
                                    "                  \n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "nav__item",
                    attrs: { to: _vm.localePath("/help") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.headerTranslate.headerHelp.text) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("Nuxt", { staticClass: "default-wrapper" }),
      _c("selectLanguage", {
        attrs: {
          "is-active": _vm.selectLanguage,
          "header-translate": _vm.headerTranslate,
        },
        on: {
          close: function ($event) {
            _vm.selectLanguage = !_vm.selectLanguage
          },
        },
      }),
      _c("PortalTarget", { attrs: { name: "popups", multiple: "" } }),
      _c("notification-view"),
      _c("screenResolutionNotice"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "arrow" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/svg/small-arrow.svg"),
          alt: "small-arrow",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }