var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-setup-password" }, [
          _c("div", { staticClass: "pop-up-setup-password__content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.headerTranslate.dearSupplier.text) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.headerTranslate.pleaseCreatePasswordBefore.text) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content__text" }, [
                _vm._v(
                  _vm._s(_vm.headerTranslate.thisIsYourUsername.text) + ", "
                ),
                _c("span", { staticClass: "content__text--bold" }, [
                  _vm._v(_vm._s(_vm.headerTranslate.youCanChangeItLater.text)),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "content__password",
                  style: { marginBottom: "50px" },
                },
                [
                  _c("input-fields", {
                    attrs: { value: _vm.userInfo.email || "", "read-only": "" },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "content__text" }, [
                _vm._v(_vm._s(_vm.headerTranslate.pleaseCreatePassword.text)),
              ]),
              _c(
                "div",
                { staticClass: "content__password" },
                [
                  _c("input-fields", {
                    class: {
                      "input-error": _vm.$v.passwordForm.newPassword.$error,
                    },
                    attrs: {
                      type: _vm.passwordTypes[0].type,
                      placeholder:
                        _vm.headerTranslate.changePasswordInputPlaceholder1
                          .text,
                    },
                    model: {
                      value: _vm.$v.passwordForm.newPassword.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.passwordForm.newPassword,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.passwordForm.newPassword.$model",
                    },
                  }),
                  _vm.passwordTypes[0].type === "text"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/svg/password-show.svg"),
                          alt: "show-password",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePasswordType(0)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.passwordTypes[0].type === "password"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/svg/password-hide.svg"),
                          alt: "show-password",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePasswordType(0)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.$v.passwordForm.newPassword.$error
                ? _c(
                    "div",
                    {
                      staticClass: "error-text",
                      staticStyle: { padding: "6px", "margin-top": "-10px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.headerTranslate
                              .changePasswordNewPasswordInputError.text
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "content__password" },
                [
                  _c("input-fields", {
                    class: {
                      "input-error":
                        _vm.$v.passwordForm.confirmNewPassword.$error,
                    },
                    attrs: {
                      type: _vm.passwordTypes[1].type,
                      placeholder:
                        _vm.headerTranslate.changePasswordInputPlaceholder2
                          .text,
                    },
                    model: {
                      value: _vm.$v.passwordForm.confirmNewPassword.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.passwordForm.confirmNewPassword,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.passwordForm.confirmNewPassword.$model",
                    },
                  }),
                  _vm.passwordTypes[1].type == "text"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/svg/password-show.svg"),
                          alt: "show-password",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePasswordType(1)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.passwordTypes[1].type == "password"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/svg/password-hide.svg"),
                          alt: "show-password",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changePasswordType(1)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.$v.passwordForm.confirmNewPassword.$error
                ? _c(
                    "div",
                    {
                      staticClass: "error-text",
                      staticStyle: { padding: "6px", "margin-top": "-10px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.headerTranslate
                              .changePasswordConfirmNewPasswordInputError.text
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "save",
                on: {
                  click: function ($event) {
                    return _vm.savePassword()
                  },
                },
              },
              [
                _c("Btn", {
                  attrs: { base: "", name: _vm.headerTranslate.saveBtn.text },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }