var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-prices-pop-up" },
    [
      _c(
        "div",
        {
          staticClass: "pop-up__close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/img/svg/close.svg"), alt: "close" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "add-prices-pop-up__title" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.isEdit
                ? _vm.translations.titleEdit.text
                : _vm.translations.titleAdd.text
            ) +
            "\n\t"
        ),
      ]),
      _vm._v(" "),
      _vm.isSpacialOfferNow
        ? _c("ErrorBanner", {
            staticClass: "add-prices-pop-up__info-banner",
            attrs: { errors: [_vm.getInfoBannerText] },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.errors || _vm.isHaveMinMaxError
        ? _c(
            "div",
            { staticClass: "add-prices-pop-up__errors" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "add-prices-pop-up__errors--title" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.translations.errorsTitle.text) +
                    "\n\t\t"
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.errorsArray, function (error, index) {
                return _c(
                  "div",
                  {
                    key: `error-${index}`,
                    staticClass: "add-prices-pop-up__errors--error-type",
                  },
                  [
                    _c("div", {
                      staticClass:
                        "dot dot_bold add-prices-pop-up__errors--dot_red",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-prices-pop-up__errors--description" },
                      [_vm._v("\n\t\t\t\t" + _vm._s(error.text) + "\n\t\t\t")]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.isHaveMinMaxError
                ? _c("div", { staticClass: "error-type" }, [
                    _c("div", { staticClass: "dot dot_bold dot_red" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(_vm._s(_vm.translations.bookingInfo.text)),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "add-prices-pop-up__pricing-title" }, [
        _vm._v(
          "\n\t\t" + _vm._s(_vm.translations.contentPricing.text) + "\n\t"
        ),
      ]),
      _vm._v(" "),
      !_vm.tour.is_group_pricing
        ? _c("div", { staticClass: "add-prices-pop-up__tiered-pricing" }, [
            _c(
              "div",
              {
                staticClass: "switcher",
                on: {
                  click: function ($event) {
                    _vm.priceOptionLocal.tieredPricing =
                      !_vm.priceOptionLocal.tieredPricing
                  },
                },
              },
              [
                _c("Checkbox", {
                  attrs: {
                    switcher: "",
                    checked: _vm.priceOptionLocal.tieredPricing,
                  },
                }),
              ],
              1
            ),
            _vm._v("\n\t\t" + _vm._s(_vm.translations.switcher.text) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "add-prices-pop-up__block" },
        [
          _vm.isHavePriceError ||
          _vm.isHaveMaxTravelersError ||
          _vm.isHaveMinPriceError ||
          _vm.isHaveMaxPriceError ||
          _vm.isHaveAgeError ||
          _vm.isHaveAgeIntervalError
            ? _c("ErrorBanner", {
                staticClass:
                  "pop-up-add-prices__error-banner pop-up-add-prices__error-banner--tiered",
                attrs: { errors: _vm.errorList },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.tour.is_group_pricing && !_vm.priceOptionLocal.tieredPricing
            ? _c(
                "div",
                { staticClass: "price-option__members-table" },
                [
                  _c("div", { staticClass: "price-option__members-row" }, [
                    _c("div", { staticClass: "price-option__members-cell" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-option__members-cell" }, [
                      _vm.someMmeberIsActive
                        ? _c(
                            "div",
                            { staticClass: "price-option__members-subrow" },
                            [
                              _c(
                                "div",
                                { staticClass: "price-option__members-cell" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.translations.pricingRetail.text
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                  _c("v-question-info", {
                                    attrs: {
                                      description:
                                        _vm.translations.pricingRetailQuestion
                                          .text,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "price-option__members-cell" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.translations.pricingGetPaid.text
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                  _c("v-question-info", {
                                    staticClass: "add-prices-pop-up__get-paid",
                                    attrs: {
                                      description:
                                        _vm.translations.pricingGetPaidQuestion
                                          .text,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.priceOptionLocal.members, function (age, index) {
                    return _c(
                      "div",
                      {
                        key: `age-${index}`,
                        staticClass: "price-option__members-row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "price-option__members-cell" },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeMember(index)
                                  },
                                },
                              },
                              [
                                _c("Checkbox", {
                                  attrs: {
                                    id: `member-${index}`,
                                    default: "",
                                    name: age.age_category.name,
                                    checked: age.is_active,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        age.is_active
                          ? _c(
                              "div",
                              { staticClass: "price-option__members-cell" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "price-option__members-subrow",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "price-option__members-cell",
                                      },
                                      [
                                        _c("v-input", {
                                          attrs: {
                                            error:
                                              _vm.$v.priceOptionLocal.members
                                                .$each.$iter[index].price
                                                .$error,
                                            type: "number",
                                            onpaste: "return false;",
                                            "prepend-text": "EUR",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.changePrice(index)
                                            },
                                            blur: function ($event) {
                                              return _vm.blurPrice(index)
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.$v.priceOptionLocal.members
                                                .$each.$iter[index].price
                                                .$model,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.$v.priceOptionLocal.members
                                                  .$each.$iter[index].price,
                                                "$model",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n\t\t\t\t\t\t\t\t\t$v.priceOptionLocal.members.$each.$iter[index]\n\t\t\t\t\t\t\t\t\t\t.price.$model\n\t\t\t\t\t\t\t\t",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "price-option__members-cell",
                                      },
                                      [
                                        _c("v-input", {
                                          attrs: {
                                            "prepend-text": "EUR",
                                            "is-disabled": "",
                                          },
                                          model: {
                                            value:
                                              _vm.priceOptionLocal.members[
                                                index
                                              ].feePrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.priceOptionLocal.members[
                                                  index
                                                ],
                                                "feePrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "priceOptionLocal.members[index].feePrice",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "price-option__members-cell" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "price-option__member-description",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(age.description) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ]
                    )
                  }),
                ],
                2
              )
            : !_vm.tour.is_group_pricing
            ? _c(
                "div",
                { staticClass: "price-option__members-table" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "price-option__members-row price-option__members-row--tiered",
                    },
                    [
                      _c("div", { staticClass: "price-option__members-cell" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "price-option__members-cell price-option__members-cell--tiered",
                        },
                        [
                          _vm.someMmeberIsActive
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "price-option__members-tiered-subrow",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price-option__members-cell",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.translations.pricingMinMax.text
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price-option__members-cell",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.translations.pricingRetail.text
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                      _c("v-question-info", {
                                        attrs: {
                                          description:
                                            _vm.translations
                                              .pricingRetailQuestion.text,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "price-option__members-cell",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.translations.pricingGetPaid.text
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                      _c("v-question-info", {
                                        staticClass:
                                          "add-prices-pop-up__get-paid",
                                        attrs: {
                                          description:
                                            _vm.translations
                                              .pricingRetailQuestion.text,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.priceOptionLocal.members, function (age, index) {
                    return _c(
                      "div",
                      {
                        key: `age-${index}`,
                        staticClass:
                          "price-option__members-row price-option__members-row--tiered",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "price-option__members-cell" },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeMember(index)
                                  },
                                },
                              },
                              [
                                _c("Checkbox", {
                                  attrs: {
                                    id: `member-${index}`,
                                    default: "",
                                    name: age.age_category.name,
                                    checked: age.is_active,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        age.is_active
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "price-option__members-cell price-option__members-cell--tiered",
                              },
                              [
                                _vm._l(
                                  age.ageTieredGroups,
                                  function (ageTieredGroup, ageTieredIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: `ageTieredGroup-${ageTieredIndex}`,
                                        staticClass:
                                          "price-option__members-tiered-subrow",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "price-option__members-cell",
                                          },
                                          [
                                            _c("v-input", {
                                              attrs: {
                                                error:
                                                  _vm.getTieredModel(
                                                    index,
                                                    ageTieredIndex
                                                  ).minAge.$error ||
                                                  ageTieredGroup.minAgeError,
                                                type: "number",
                                                onkeypress:
                                                  "return event.charCode >= 48 && event.charCode <= 57",
                                                onpaste: "return false;",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkMinAgeCount(
                                                    index,
                                                    ageTieredIndex
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.getTieredModel(
                                                  index,
                                                  ageTieredIndex
                                                ).minAge.$model,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.getTieredModel(
                                                      index,
                                                      ageTieredIndex
                                                    ).minAge,
                                                    "$model",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n\t\t\t\t\t\t\t\t\tgetTieredModel(index, ageTieredIndex).minAge\n\t\t\t\t\t\t\t\t\t\t.$model\n\t\t\t\t\t\t\t\t",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("-")]),
                                            _vm._v(" "),
                                            _c("v-input", {
                                              attrs: {
                                                error:
                                                  _vm.getTieredModel(
                                                    index,
                                                    ageTieredIndex
                                                  ).maxAge.$error ||
                                                  ageTieredGroup.maxAgeError,
                                                type: "number",
                                                onkeypress:
                                                  "return event.charCode >= 48 && event.charCode <= 57",
                                                onpaste: "return false;",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkMinAgeCount(
                                                    index,
                                                    ageTieredIndex
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.getTieredModel(
                                                  index,
                                                  ageTieredIndex
                                                ).maxAge.$model,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.getTieredModel(
                                                      index,
                                                      ageTieredIndex
                                                    ).maxAge,
                                                    "$model",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n\t\t\t\t\t\t\t\t\tgetTieredModel(index, ageTieredIndex).maxAge\n\t\t\t\t\t\t\t\t\t\t.$model\n\t\t\t\t\t\t\t\t",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "price-option__members-cell",
                                          },
                                          [
                                            _c("v-input", {
                                              attrs: {
                                                error: _vm.getTieredModel(
                                                  index,
                                                  ageTieredIndex
                                                ).price.$error,
                                                type: "number",
                                                "prepend-text": "EUR",
                                                onpaste: "return false;",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changePriceTiered(
                                                    index,
                                                    ageTieredIndex
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.blurPrice(
                                                    index,
                                                    ageTieredIndex
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.getTieredModel(
                                                  index,
                                                  ageTieredIndex
                                                ).price.$model,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.getTieredModel(
                                                      index,
                                                      ageTieredIndex
                                                    ).price,
                                                    "$model",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n\t\t\t\t\t\t\t\t\tgetTieredModel(index, ageTieredIndex).price.$model\n\t\t\t\t\t\t\t\t",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "price-option__members-cell",
                                          },
                                          [
                                            _c("v-input", {
                                              attrs: {
                                                "prepend-text": "EUR",
                                                "is-disabled": "",
                                              },
                                              model: {
                                                value: ageTieredGroup.feePrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    ageTieredGroup,
                                                    "feePrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ageTieredGroup.feePrice",
                                              },
                                            }),
                                            _vm._v(" "),
                                            ageTieredIndex != 0
                                              ? _c("img", {
                                                  staticClass:
                                                    "add-prices-pop-up__del-price",
                                                  attrs: {
                                                    src: require("@/assets/img/svg/del_cross.svg"),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeField(
                                                        ageTieredIndex,
                                                        age.ageTieredGroups
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "add-prices-pop-up__add-input",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addAgeGroupField(
                                          age,
                                          age.ageTieredGroups
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.translations.pricingAddTier.text
                                        ) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "price-option__members-cell price-option__members-cell--tiered",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "price-option__member-description",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(age.description) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("div", { staticClass: "price-option__members-table" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "price-option__members-row price-option__members-row--group",
                  },
                  [
                    _c("div", { staticClass: "price-option__members-cell" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.translations.maxTravelersPerBooking.text) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-option__members-cell" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.translations.pricingRetail.text) +
                            "\n\t\t\t\t\t"
                        ),
                        _c("v-question-info", {
                          attrs: {
                            description:
                              _vm.translations.retailPriceQuestionMark.text,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-option__members-cell" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.translations.pricingGetPaid.text) +
                            "\n\t\t\t\t\t"
                        ),
                        _c("v-question-info", {
                          staticClass: "add-prices-pop-up__get-paid",
                          attrs: {
                            description:
                              _vm.translations.getPaidQuestionMark.text,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "price-option__members-row price-option__members-row--group",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "price-option__members-cell" },
                      [
                        _c("v-input", {
                          staticClass: "input-group input-group_count",
                          attrs: {
                            error: _vm.groupPriceCountError,
                            onkeypress:
                              "return event.charCode >= 48 && event.charCode <= 57",
                            type: "number",
                          },
                          on: { blur: _vm.blurMaxTravelers },
                          model: {
                            value: _vm.priceOptionLocal.members[0].groupCount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.priceOptionLocal.members[0],
                                "groupCount",
                                $$v
                              )
                            },
                            expression:
                              "priceOptionLocal.members[0].groupCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-option__members-cell" },
                      [
                        _c("v-input", {
                          attrs: {
                            error:
                              _vm.$v.priceOptionLocal.members.$each.$iter[0]
                                .price.$error,
                            type: "number",
                            "prepend-text": "EUR",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changePrice(0)
                            },
                            blur: function ($event) {
                              return _vm.blurPrice(0)
                            },
                          },
                          model: {
                            value:
                              _vm.$v.priceOptionLocal.members.$each.$iter[0]
                                .price.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.priceOptionLocal.members.$each.$iter[0]
                                  .price,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "\n\t\t\t\t\t\t\t$v.priceOptionLocal.members.$each.$iter[0].price.$model\n\t\t\t\t\t\t",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-option__members-cell" },
                      [
                        _c("v-input", {
                          attrs: { "prepend-text": "EUR", "is-disabled": "" },
                          model: {
                            value: _vm.priceOptionLocal.members[0].feePrice,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.priceOptionLocal.members[0],
                                "feePrice",
                                $$v
                              )
                            },
                            expression: "priceOptionLocal.members[0].feePrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "add-prices-pop-up__apply-days" }, [
        _c("div", { staticClass: "add-prices-pop-up__apply-days--title" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.translations.contentDaysApply.text) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { on: { click: _vm.selectAllDays } },
          [
            _c("Checkbox", {
              attrs: {
                id: "select-all",
                default: "",
                name: _vm.translations.daysApplySelectAll.text,
                checked: _vm.priceOptionLocal.allDaysChecked,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add-prices-pop-up__apply-days-content" },
          _vm._l(_vm.priceOptionLocal.days, function (day, index) {
            return _c(
              "div",
              {
                key: `day-${index}`,
                staticClass: "add-prices-pop-up__apply-days-content--item",
                on: {
                  click: function ($event) {
                    return _vm.changeDate(index)
                  },
                },
              },
              [
                _c("Checkbox", {
                  attrs: {
                    id: `day-${index}`,
                    button: "",
                    name: day.name,
                    checked: day.is_active,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.priceOptionLocal.addTimes
        ? _c("div", { staticClass: "add-prices-pop-up__apply-time" }, [
            _c("div", { staticClass: "add-prices-pop-up__apply-time--title" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.translations.contentTimeApply.text) +
                  "\n\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "add-prices-pop-up__apply-time--note" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.translations.timeApplyNote.text) +
                  "\n\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.priceOptionLocal.anotherTimes, function (time, index) {
                return _c(
                  "div",
                  {
                    key: `time-interval-${index}`,
                    staticClass: "add-prices-pop-up__apply-time-item row",
                  },
                  [
                    _c("date-picker", {
                      staticClass: "custom-detepicker",
                      attrs: {
                        type: "time",
                        lang: _vm.lang,
                        "time-picker-options": _vm.timePickerOptions,
                        format: _vm.timeFormat,
                        placeholder: _vm.translations.timeApplyPlaceholder.text,
                        editable: false,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.newValueApplyTime(index)
                        },
                      },
                      model: {
                        value: time.time,
                        callback: function ($$v) {
                          _vm.$set(time, "time", $$v)
                        },
                        expression: "time.time",
                      },
                    }),
                    _vm._v(" "),
                    index != 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "add-prices-pop-up__apply-time-item--delete",
                            on: {
                              click: function ($event) {
                                return _vm.removeField(
                                  index,
                                  _vm.priceOptionLocal.anotherTimes
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/svg/del_cross.svg"),
                                alt: "del_cross",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    time.error
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "add-prices-pop-up__apply-time-item--error",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.translations.timeApplyError.text) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.priceOptionLocal.anotherTimes.length < 48
              ? _c(
                  "span",
                  {
                    staticClass: "add-prices-pop-up__add-time",
                    on: {
                      click: function ($event) {
                        return _vm.addAnotherTimeField(
                          _vm.priceOptionLocal.anotherTimes
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.translations.timeApplyAdd.text) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.priceOptionLocal.openingHours
        ? _c(
            "div",
            { staticClass: "add-prices-pop-up__opening-time" },
            [
              _c(
                "div",
                { staticClass: "add-prices-pop-up__opening-time--title" },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.translations.contentTimeApply.text) +
                      "\n\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(
                _vm.priceOptionLocal.openingScheduleTimes,
                function (openingScheduleTime, index) {
                  return _c(
                    "div",
                    {
                      key: `openingScheduleTime-${index}`,
                      staticClass:
                        "add-prices-pop-up__opening-time--edit-expand",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "add-prices-pop-up__edit-expand--item" },
                        [
                          _c("date-picker", {
                            staticClass: "custom-detepicker",
                            attrs: {
                              type: "time",
                              lang: _vm.lang,
                              "time-picker-options": _vm.timePickerOptions,
                              format: _vm.timeFormat,
                              "show-second": false,
                              placeholder:
                                _vm.translations.openingTimePlaceholderFrom
                                  .text,
                              editable: false,
                            },
                            model: {
                              value: openingScheduleTime.startTime,
                              callback: function ($$v) {
                                _vm.$set(openingScheduleTime, "startTime", $$v)
                              },
                              expression: "openingScheduleTime.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._m(1, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "edit-expand__item" },
                        [
                          _c("date-picker", {
                            staticClass: "custom-detepicker",
                            attrs: {
                              type: "time",
                              lang: _vm.lang,
                              "time-picker-options": _vm.timePickerOptions,
                              format: _vm.timeFormat,
                              "show-second": false,
                              placeholder:
                                _vm.translations.openingTimePlaceholderTo.text,
                              editable: false,
                            },
                            model: {
                              value: openingScheduleTime.endTime,
                              callback: function ($$v) {
                                _vm.$set(openingScheduleTime, "endTime", $$v)
                              },
                              expression: "openingScheduleTime.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      index != 0
                        ? _c("img", {
                            staticClass:
                              "add-prices-pop-up__del-opening-schedule-times",
                            attrs: {
                              src: require("@/assets/img/svg/del_cross.svg"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeField(
                                  index,
                                  _vm.priceOptionLocal.openingScheduleTimes
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }
              ),
              _vm._v(" "),
              false
                ? _c(
                    "span",
                    {
                      staticClass: "add-prices-pop-up__add-time",
                      on: {
                        click: function ($event) {
                          return _vm.addField(
                            _vm.priceOptionLocal.openingScheduleTimes
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.translations.timeApplyAdd.text) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.spacialOfferError
        ? _c("div", { staticClass: "add-prices-pop-up__spacial-offer-error" }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm.translations.pricesCannotBeSaved.text) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "add-prices-pop-up__nav" },
        [
          _c("v-btn", {
            attrs: { outline: "", name: _vm.translations.backBtn.text },
            on: {
              click: function ($event) {
                return _vm.$emit("back")
              },
            },
          }),
          _vm._v(" "),
          _vm.step > 0
            ? _c("div", { staticClass: "pagination row" }, [
                _vm.step == 3
                  ? _c("div", { staticClass: "dot dot_page" })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "dot dot_page" }),
                _vm._v(" "),
                _c("div", { staticClass: "dot dot_page dot_page_active" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-btn", {
            attrs: {
              base: "",
              name: _vm.translations.saveBtn.text,
              disabled: _vm.isSpacialOfferNow,
            },
            on: { click: _vm.save },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "add-prices-pop-up__errors--icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/svg/error.svg"), alt: "info" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "edit-expand__item" }, [
      _c("div", { staticClass: "dash" }, [_vm._v("-")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }