var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-learn-more" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "pop-up-learn-more__title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.confirmationTranslate.title.text) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-learn-more__subtitle" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.confirmationTranslate.description.text) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-learn-more__content" }, [
            _c("div", { staticClass: "instant" }, [
              _c("div", { staticClass: "instant__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.instantTitle.text) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "instant__description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.instantDescription.text) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "instant__example" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.instantNote.text) +
                    "\n          "
                ),
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/instant-confirmation.jpg"),
                    alt: "instant",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "instant-and-manual" }, [
              _c("div", { staticClass: "instant-and-manual__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.instantManualTitle.text) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "instant-and-manual__description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.confirmationTranslate.instantManualDescription.text
                    ) +
                    "\n        "
                ),
              ]),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "manual" }, [
              _c("div", { staticClass: "manual__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.manualTitle.text) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "manual__description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.manualDescription.text) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "manual__example" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.confirmationTranslate.manualNote.text) +
                    "\n          "
                ),
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/manual-confirmation.jpg"),
                    alt: "one-set-time",
                  },
                }),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "pop-up-learn-more__nav",
              on: { click: _vm.closeWindow },
            },
            [
              _c("Btn", {
                attrs: {
                  base: "",
                  name: _vm.confirmationTranslate.understoodBtn.text,
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "instant-and-manual__example" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/instant-and-manual-confirmation.jpg"),
          alt: "instant-and-manual",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }