var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview__photos" }, [
    _c("div", { staticClass: "photos" }, [
      _c("div", { staticClass: "photos__big" }, [
        _c("img", { attrs: { src: _vm.bigPhoto.get_image } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "photos__small" },
        _vm._l(_vm.smallPhotos, function (image, key) {
          return _c("div", { key: key, staticClass: "photos__small-image" }, [
            _c("img", { attrs: { src: image.get_small_image } }),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "photos__show-all" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "8",
              height: "8",
              viewBox: "0 0 8 8",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              attrs: { width: "2", height: "2", fill: "currentColor" },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: { x: "3", width: "2", height: "2", fill: "currentColor" },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "3",
                y: "3",
                width: "2",
                height: "2",
                fill: "currentColor",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: { y: "3", width: "2", height: "2", fill: "currentColor" },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: { x: "6", width: "2", height: "2", fill: "currentColor" },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "6",
                y: "3",
                width: "2",
                height: "2",
                fill: "currentColor",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "3",
                y: "6",
                width: "2",
                height: "2",
                fill: "currentColor",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: { y: "6", width: "2", height: "2", fill: "currentColor" },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "6",
                y: "6",
                width: "2",
                height: "2",
                fill: "currentColor",
              },
            }),
          ]
        ),
        _vm._v(
          "\n      " + _vm._s(_vm.translates.btnShowPhotos.text) + "\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }