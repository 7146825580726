var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bank-details" }, [
    _c(
      "div",
      { staticClass: "_container" },
      [
        _vm.hasBackButton
          ? _c("VBreadcrumbs", {
              attrs: { title: _vm.translations.backStep.text },
              on: { click: _vm.backStep },
            })
          : _vm._e(),
        _c("div", { staticClass: "bank-details__content" }, [
          _c(
            "div",
            { staticClass: "billing-address" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.translations.billingTitle.text) +
                    "\n        "
                ),
              ]),
              _c(
                "VField",
                {
                  staticClass: "bank-details__field",
                  attrs: {
                    label: _vm.translations.selectionCountry.text,
                    error: _vm.countryError,
                  },
                },
                [
                  _c(
                    "client-only",
                    [
                      _c("VSelectCountry", {
                        attrs: {
                          country: _vm.country,
                          "country-name": false,
                          usei18n: false,
                          error: _vm.countryError,
                        },
                        model: {
                          value: _vm.country,
                          callback: function ($$v) {
                            _vm.country = $$v
                          },
                          expression: "country",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "VField",
                {
                  staticClass: "bank-details__field",
                  attrs: {
                    label: _vm.translations.streetAddress.text,
                    error: _vm.$v.localAddressForm.address.$error,
                    "error-message": _vm.getErrorMessage(
                      _vm.translations.streetAddress.text
                    ),
                  },
                },
                [
                  _c("VInput", {
                    attrs: {
                      error: _vm.$v.localAddressForm.address.$error,
                      placeholder:
                        _vm.translations.inputAddressPlaceholder1.text,
                      "max-length": 128,
                      "hide-counter": "",
                    },
                    model: {
                      value: _vm.$v.localAddressForm.address.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.localAddressForm.address,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.localAddressForm.address.$model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "VField",
                {
                  staticClass: "bank-details__field",
                  attrs: {
                    label: _vm.translations.city.text,
                    error: _vm.$v.localAddressForm.city.$error,
                    "error-message": _vm.getErrorMessage(
                      _vm.translations.city.text
                    ),
                  },
                },
                [
                  _c("VInput", {
                    attrs: {
                      error: _vm.$v.localAddressForm.city.$error,
                      placeholder: _vm.translations.inputCityPlaceholder.text,
                      "max-length": 128,
                      "hide-counter": "",
                    },
                    model: {
                      value: _vm.$v.localAddressForm.city.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.localAddressForm.city,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.localAddressForm.city.$model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "VField",
                {
                  staticClass: "bank-details__field",
                  attrs: {
                    label: _vm.translations.zipCode.text,
                    error: _vm.$v.localAddressForm.postcode.$error,
                    "error-message": _vm.getErrorMessage(
                      _vm.translations.zipCode.text
                    ),
                  },
                },
                [
                  _c("VInput", {
                    attrs: {
                      error: _vm.$v.localAddressForm.postcode.$error,
                      placeholder:
                        _vm.translations.inputZipCodePlaceholder.text,
                      "max-length": 128,
                      "hide-counter": "",
                    },
                    model: {
                      value: _vm.$v.localAddressForm.postcode.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.localAddressForm.postcode,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.localAddressForm.postcode.$model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "next", on: { click: _vm.saveStep } },
                [
                  _c("Btn", {
                    attrs: { base: "", name: _vm.translations.btnNext.text },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }