<template>
  <div v-if="optionsFrom != null && optionsTo != null">
    <!-- Schedules & Pricing -->
    <div class="translation-block">
      <div class="translation-block__title">{{ translations.schedulesPricing.text }}</div>
      <div
        v-for="(item, index) in optionsSchedule"
        :key="'options-schedule' + index"
        :class="[notLastBlock(index) ? 'translation-block__schedules' : '']"
      >
        <!-- Schedule name -->
        <div class="translation-row translation-block__container">
          <div class="translation-row__english">
            <div>{{ translations.scheduleName.text }}</div>
            <VTextarea v-model="optionsFrom[index].name" disabled rows="2" />
          </div>
          <div class="translation-row__new-lang">
            <div>{{ '\xa0' }}</div>
            <VTextarea v-model="optionsTo[index].name" :max-length="250" rows="2" />
          </div>
        </div>
        <!-- Additional info -->
        <div v-for="(info, indexInfo) in item.additional_info" :key="'additional-info' + indexInfo">
          <div class="translation-additional-info__title">
            {{ optionsSchedule[index].additional_info[indexInfo].info_type.title }}
          </div>
          <!-- Name -->
          <div class="translation-row translation-block__container">
            <div class="translation-row__english">
              <div>{{ translations.nameItem.text }}</div>
              <VTextarea v-model="optionsFrom[index].additional_info[indexInfo].name" disabled rows="1" />
            </div>
            <div class="translation-row__new-lang">
              <div>{{ '\xa0' }}</div>
              <VTextarea v-model="optionsTo[index].additional_info[indexInfo].name" :max-length="100" rows="1" />
            </div>
          </div>
          <!-- Description -->
          <div class="translation-row translation-block__container">
            <div class="translation-row__english">
              <div>{{ translations.description.text }}</div>
              <VTextarea v-model="optionsFrom[index].additional_info[indexInfo].description" disabled rows="2" />
            </div>
            <div class="translation-row__new-lang">
              <div>{{ '\xa0' }}</div>
              <VTextarea v-model="optionsTo[index].additional_info[indexInfo].description" :max-length="250" rows="2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-btn base :name="translations.saveContinue.text" :loading="loading" loading-name="..." @click="saveTranslate" />
    <loader v-if="loading" />
  </div>
</template>

<script>
import VTextarea from '@/components/controls/VTextarea.vue';
import VBtn from '@/components/controls/VBtn.vue';
import Loader from '@/components/Loader';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'StepSchedulesPricing',
  components: {
    VTextarea,
    VBtn,
    Loader,
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    optionsSchedule: { type: Array, default: () => [] },
    optionsScheduleTranslate: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) },
    newQuery: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
    optionsFrom: null,
    optionsTo: null,
  }),

  validations: {
    optionsTo: {
      required: requiredIf(function (model) {
        return this.optionsFrom != null && this.optionsTo != null;
      }),
    },
  },
  mounted() {
    this.optionsFrom = JSON.parse(JSON.stringify(this.optionsSchedule));
    this.optionsTo = JSON.parse(JSON.stringify(this.optionsScheduleTranslate));
  },
  methods: {
    notLastBlock(index) {
      return this.optionsSchedule.length - 1 !== index;
    },
    async saveTranslate() {
      this.loading = true;
      for (const option of this.optionsTo) {
        await this.$axios.$post(
          `/api/v1/add-tour-schedule-option/`,
          {},
          {
            params: {
              option_id: option.id,
              name: option.name,
              tour_id: this.tour.id,
              language: this.newQuery.lang,
              is_pick_up_included: option.is_pick_up_include_in_price,
              edit_language: this.newQuery.lang,
            },
          }
        );

        for (const info of option.additional_info) {
          await this.$axios.$post(
            `/api/v1/act-option-additional-info/`,
            {},
            {
              params: {
                info_id: info.id,
                name: info.name,
                type_id: info.info_type.id,
                description: info.description,
                edit_language: this.newQuery.lang,
              },
            }
          );
        }
      }
      this.loading = false;
      this.$router.push(this.localePath(`/product/${this.tour.slug}/translations`));
    },
  },
};
</script>
