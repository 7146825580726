<template>
  <div id="full-description" class="preview__section">
    <div class="preview__title">
      {{ translations.descriptionFull.text }}
    </div>
    <VExpansionText :text="description" :translations="translations" class="about-tour-list__text" />
  </div>
</template>

<script>
import VExpansionText from '~/components/common/VExpansionText.vue'

export default {
  name: 'FullDescription',
  components: {
    VExpansionText
  },
  props: {
    description: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) }
  }
}
</script>
