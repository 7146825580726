var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      staticClass: "attractions-create-modal",
      attrs: {
        size: "extra-large",
        title: _vm.translates.attractionTitle.text,
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _c(
        "form",
        { staticClass: "attractions-create-modal" },
        [
          _c(
            "div",
            { staticClass: "attractions-create-modal__field" },
            [
              _c("p", { staticClass: "attractions-create-modal__label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translates.locationText.text) +
                    "\n      "
                ),
              ]),
              _c("GoogleAutocomplete", {
                staticClass:
                  "attractions-create-modal__input attractions-create-modal__input--search",
                attrs: {
                  error: _vm.addressError,
                  placeholder:
                    "e.g. Timanfaya National Park, 35570 Yaiza, Lanzarote, Spain",
                },
                on: { "update:point": _vm.handlePlaceChanged },
                model: {
                  value: _vm.$v.address.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.address, "$model", $$v)
                  },
                  expression: "$v.address.$model",
                },
              }),
              _vm.addressError
                ? _c("p", { staticClass: "validation-error" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translates.locationError.text) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("VTextarea", {
            staticClass:
              "attractions-create-modal__description attractions-create-modal__field",
            attrs: {
              "max-length": 1000,
              label: _vm.translates.describeText.text,
              error: _vm.$v.localAttraction.description.$error,
              "error-message": _vm.translates.describeError.text,
            },
            model: {
              value: _vm.$v.localAttraction.description.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.localAttraction.description, "$model", $$v)
              },
              expression: "$v.localAttraction.description.$model",
            },
          }),
          _c(
            "div",
            { staticClass: "attractions-create-modal__field" },
            [
              _c("p", { staticClass: "attractions-create-modal__label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translates.durationText.text) +
                    "\n      "
                ),
              ]),
              _c("VRadioGroup", {
                attrs: { name: "isFlexTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "attractions-create-modal__radio",
                                attrs: {
                                  label: _vm.translates.SetDurationText.text,
                                  value: false,
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            !_vm.isFlexTime
                              ? [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.translates.SetDurationText.text
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "attractions-create-modal__duration",
                                    },
                                    [
                                      _c("VInput", {
                                        staticClass:
                                          "attractions-create-modal__duration-input attractions-create-modal__duration-input--right",
                                        attrs: {
                                          "max-length": 3,
                                          "hide-counter": "",
                                          onkeypress:
                                            "return event.charCode >= 48 && event.charCode <= 57",
                                          onpaste: "return false;",
                                          error:
                                            _vm.$v.localAttraction.durationTime
                                              .$error,
                                        },
                                        model: {
                                          value:
                                            _vm.$v.localAttraction.durationTime
                                              .$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.localAttraction
                                                .durationTime,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.localAttraction.durationTime.$model",
                                        },
                                      }),
                                      _c("VSelect", {
                                        staticClass:
                                          "attractions-create-modal__duration-select",
                                        class: {
                                          "attractions-create-modal__select--error":
                                            _vm.$v.localAttraction.durationTime
                                              .$error,
                                        },
                                        attrs: {
                                          items: _vm.$options.durations,
                                          translates: _vm.durationTranslates,
                                        },
                                        model: {
                                          value:
                                            _vm.localAttraction
                                              .durationTimeUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localAttraction,
                                              "durationTimeUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localAttraction.durationTimeUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.$v.localAttraction.durationTime.$error
                                    ? _c(
                                        "p",
                                        { staticClass: "validation-error" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.translates.SetDurationError
                                                  .text
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "attractions-create-modal__radio",
                                attrs: {
                                  label:
                                    _vm.translates.FlexibleDurationText.text,
                                  value: true,
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm.isFlexTime
                              ? [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.translates.FlexibleDurationText.text
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "attractions-create-modal__duration",
                                    },
                                    [
                                      _c("VInput", {
                                        staticClass:
                                          "attractions-create-modal__duration-input",
                                        attrs: {
                                          "max-length": 3,
                                          "hide-counter": "",
                                          onkeypress:
                                            "return event.charCode >= 48 && event.charCode <= 57",
                                          onpaste: "return false;",
                                          error:
                                            _vm.$v.localAttraction
                                              .durationTimeFrom.$error,
                                        },
                                        model: {
                                          value:
                                            _vm.$v.localAttraction
                                              .durationTimeFrom.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.localAttraction
                                                .durationTimeFrom,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.localAttraction.durationTimeFrom.$model",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "attractions-create-modal__duration-label",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.translates.toText.text)
                                          ),
                                        ]
                                      ),
                                      _c("VInput", {
                                        staticClass:
                                          "attractions-create-modal__duration-input attractions-create-modal__duration-input--right",
                                        attrs: {
                                          "max-length": 3,
                                          "hide-counter": "",
                                          onkeypress:
                                            "return event.charCode >= 48 && event.charCode <= 57",
                                          onpaste: "return false;",
                                          error:
                                            _vm.$v.localAttraction
                                              .durationTimeTo.$error,
                                        },
                                        model: {
                                          value:
                                            _vm.$v.localAttraction
                                              .durationTimeTo.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.localAttraction
                                                .durationTimeTo,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.localAttraction.durationTimeTo.$model",
                                        },
                                      }),
                                      _c("VSelect", {
                                        staticClass:
                                          "attractions-create-modal__duration-select",
                                        class: {
                                          "attractions-create-modal__select--error":
                                            _vm.$v.localAttraction
                                              .durationTimeTo.$error,
                                        },
                                        attrs: {
                                          items: _vm.$options.durations,
                                          translates: _vm.durationTranslates,
                                        },
                                        model: {
                                          value:
                                            _vm.localAttraction
                                              .durationTimeUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localAttraction,
                                              "durationTimeUnit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localAttraction.durationTimeUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.durationIntervalError
                                    ? _c(
                                        "p",
                                        { staticClass: "validation-error" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.durationIntervalError
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.isFlexTime,
                  callback: function ($$v) {
                    _vm.isFlexTime = $$v
                  },
                  expression: "isFlexTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "attractions-create-modal__field" },
            [
              _c("p", { staticClass: "attractions-create-modal__label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translates.AdmissionText.text) +
                    "\n      "
                ),
              ]),
              _c("VRadioGroup", {
                attrs: { name: "ticketRedemption" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label: _vm.translates.AdmissionChoice1.text,
                                  value: "Admission is free",
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label: _vm.translates.AdmissionChoice2.text,
                                  value: "Admission ticket is included",
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label: _vm.translates.AdmissionChoice3.text,
                                  value: "Admission ticket is not included",
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.$v.localAttraction.ticketRedemption.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.localAttraction.ticketRedemption,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.localAttraction.ticketRedemption.$model",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "attractions-create-modal__field" },
            [
              _c("p", { staticClass: "attractions-create-modal__label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translates.skipTheLineText.text) +
                    "\n      "
                ),
              ]),
              _c("VRadioGroup", {
                attrs: { name: "isSkipLine" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label: _vm.translates.yesText.text,
                                  value: true,
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label: _vm.translates.noText.text,
                                  value: false,
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.localAttraction.isSkipLine,
                  callback: function ($$v) {
                    _vm.$set(_vm.localAttraction, "isSkipLine", $$v)
                  },
                  expression: "localAttraction.isSkipLine",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "attractions-create-modal__footer" },
            [
              _c(
                "VButton",
                { attrs: { outline: "" }, on: { click: _vm.close } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translates.cancelAttractionBtn.text) +
                      "\n      "
                  ),
                ]
              ),
              _c("VButton", { attrs: { base: "" }, on: { click: _vm.save } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translates.saveAttractionBtn.text) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }