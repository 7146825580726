var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "regular-intervals-wrapper wrapper" }, [
        _c("div", { staticClass: "pop-up-regular-intervals" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "pop-up-regular-intervals__title" }, [
            _vm._v("\n      Add times at regular intervals\n    "),
          ]),
          _c("div", { staticClass: "pop-up-regular-intervals__options" }, [
            _c("div", { staticClass: "value" }, [
              _c("div", { staticClass: "value__title" }, [
                _vm._v("\n          Every\n        "),
              ]),
              _c(
                "div",
                { staticClass: "value__input" },
                [
                  _c("input-fields", {
                    attrs: {
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      onpaste: "return false;",
                      type: "number",
                    },
                    on: { input: _vm.emitInterval },
                    model: {
                      value: _vm.intervalTime,
                      callback: function ($$v) {
                        _vm.intervalTime = $$v
                      },
                      expression: "intervalTime",
                    },
                  }),
                  _c("selection", {
                    attrs: {
                      default: "",
                      "default-name": _vm.timeName,
                      items: _vm.time,
                    },
                    on: { name: _vm.changeTime, change: _vm.emitInterval },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "date-from" },
              [
                _c("div", { staticClass: "date-from__title" }, [
                  _vm._v("\n          From\n        "),
                ]),
                _c("date-picker", {
                  staticClass: "custom-detepicker",
                  attrs: {
                    type: "time",
                    "time-picker-options": {
                      start: "00:00",
                      step: "00:30",
                      end: "23:30",
                    },
                    format: "hh:mm A",
                    placeholder: "Time",
                    use12h: true,
                    editable: false,
                  },
                  model: {
                    value: _vm.time1,
                    callback: function ($$v) {
                      _vm.time1 = $$v
                    },
                    expression: "time1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "date-until" },
              [
                _c("div", { staticClass: "date-until__title" }, [
                  _vm._v("\n          Until\n        "),
                ]),
                _c("date-picker", {
                  staticClass: "custom-detepicker",
                  attrs: {
                    type: "time",
                    "time-picker-options": {
                      start: "00:00",
                      step: "00:30",
                      end: "23:30",
                    },
                    format: "hh:mm A",
                    placeholder: "Time",
                    use12h: true,
                    editable: false,
                  },
                  model: {
                    value: _vm.time2,
                    callback: function ($$v) {
                      _vm.time2 = $$v
                    },
                    expression: "time2",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "pop-up-regular-intervals__nav" }, [
            _c(
              "div",
              { staticClass: "cancel", on: { click: _vm.closeWindow } },
              [_c("Btn", { attrs: { outline: "", name: "Cancel" } })],
              1
            ),
            _c(
              "div",
              { staticClass: "Save", on: { click: _vm.saveInterval } },
              [_c("Btn", { attrs: { base: "", name: "Save" } })],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }