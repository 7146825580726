var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tourTo != null && _vm.tourFrom != null
    ? _c(
        "div",
        [
          _c("div", { staticClass: "translation-block" }, [
            _c("div", { staticClass: "translation-block__title" }, [
              _vm._v(_vm._s(_vm.translations.productTitle.text)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "translation-row translation-block__container" },
              [
                _c(
                  "div",
                  { staticClass: "translation-row__english" },
                  [
                    _c("VTextarea", {
                      attrs: { disabled: "", rows: "2" },
                      model: {
                        value: _vm.tourFrom.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.tourFrom, "name", $$v)
                        },
                        expression: "tourFrom.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "translation-row__new-lang" },
                  [
                    _c("VTextarea", {
                      attrs: {
                        error: _vm.$v.tourTo.name.$error,
                        "max-length": 250,
                        rows: "2",
                      },
                      model: {
                        value: _vm.$v.tourTo.name.$model,
                        callback: function ($$v) {
                          _vm.$set(_vm.$v.tourTo.name, "$model", $$v)
                        },
                        expression: "$v.tourTo.name.$model",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "translation-block" }, [
            _c("div", { staticClass: "translation-block__title" }, [
              _vm._v(_vm._s(_vm.translations.meetingPickup.text)),
            ]),
            _vm._v(" "),
            ["MEETING", "MIXED"].includes(_vm.tour.meeting_type)
              ? _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.meetingInstructions.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "4" },
                          model: {
                            value: _vm.tourFrom.meeting_point.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourFrom.meeting_point,
                                "description",
                                $$v
                              )
                            },
                            expression: "tourFrom.meeting_point.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v(_vm._s("\xa0"))]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error:
                              _vm.$v.tourTo.meeting_point.description.$error,
                            "max-length": 400,
                            rows: "4",
                          },
                          model: {
                            value:
                              _vm.$v.tourTo.meeting_point.description.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.meeting_point.description,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.tourTo.meeting_point.description.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tour.pick_up_description != null &&
            _vm.tour.pick_up_description != "" &&
            _vm.tour.pick_up_description != "null"
              ? _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.pickupInstructions.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "4" },
                          model: {
                            value: _vm.tourFrom.pick_up_description,
                            callback: function ($$v) {
                              _vm.$set(_vm.tourFrom, "pick_up_description", $$v)
                            },
                            expression: "tourFrom.pick_up_description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v(_vm._s("\xa0"))]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error: _vm.$v.tourTo.pick_up_description.$error,
                            "max-length": 400,
                            rows: "4",
                          },
                          model: {
                            value: _vm.$v.tourTo.pick_up_description.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.pick_up_description,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.tourTo.pick_up_description.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tour.end_point != null &&
            _vm.tour.end_point.description != null &&
            _vm.tour.end_point.description != ""
              ? _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.endPointInstructions.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "4" },
                          model: {
                            value: _vm.tourFrom.end_point.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourFrom.end_point,
                                "description",
                                $$v
                              )
                            },
                            expression: "tourFrom.end_point.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v(_vm._s("\xa0"))]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error: _vm.$v.tourTo.end_point.description.$error,
                            "max-length": 400,
                            rows: "4",
                          },
                          model: {
                            value: _vm.$v.tourTo.end_point.description.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.end_point.description,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.tourTo.end_point.description.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.tour.tour_important_info != null &&
          _vm.tour.tour_important_info != ""
            ? _c("div", { staticClass: "translation-block" }, [
                _c("div", { staticClass: "translation-block__title" }, [
                  _vm._v(_vm._s(_vm.translations.informationDisplayed.text)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.contentImportantinfo.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "4" },
                          model: {
                            value: _vm.tourFrom.tour_important_info,
                            callback: function ($$v) {
                              _vm.$set(_vm.tourFrom, "tour_important_info", $$v)
                            },
                            expression: "tourFrom.tour_important_info",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v(_vm._s("\xa0"))]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error: _vm.$v.tourTo.tour_important_info.$error,
                            "max-length": 400,
                            rows: "4",
                          },
                          model: {
                            value: _vm.$v.tourTo.tour_important_info.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.tour_important_info,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.tourTo.tour_important_info.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.tour.tour_leaving != null && _vm.tour.tour_leaving != ""
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "translation-row translation-block__container",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "translation-row__english" },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations.informationBeforeLeaving.text
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("VTextarea", {
                              attrs: { disabled: "", rows: "4" },
                              model: {
                                value: _vm.tourFrom.tour_leaving,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tourFrom, "tour_leaving", $$v)
                                },
                                expression: "tourFrom.tour_leaving",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "translation-row__new-lang" },
                          [
                            _c("div", [_vm._v(_vm._s("\xa0"))]),
                            _vm._v(" "),
                            _c("VTextarea", {
                              attrs: {
                                error: _vm.$v.tourTo.tour_leaving.$error,
                                "max-length": 400,
                                rows: "4",
                              },
                              model: {
                                value: _vm.$v.tourTo.tour_leaving.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.tourTo.tour_leaving,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.tourTo.tour_leaving.$model",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.tour.ticket_redemption_instruction != null &&
          _vm.tour.ticket_redemption_instruction != ""
            ? _c("div", { staticClass: "translation-block" }, [
                _c("div", { staticClass: "translation-block__title" }, [
                  _vm._v(_vm._s(_vm.translations.ticketsRedemption.text)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "translation-row translation-block__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "translation-row__english" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.translations.specialInstructions.text)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: { disabled: "", rows: "4" },
                          model: {
                            value: _vm.tourFrom.ticket_redemption_instruction,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tourFrom,
                                "ticket_redemption_instruction",
                                $$v
                              )
                            },
                            expression:
                              "tourFrom.ticket_redemption_instruction",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "translation-row__new-lang" },
                      [
                        _c("div", [_vm._v(_vm._s("\xa0"))]),
                        _vm._v(" "),
                        _c("VTextarea", {
                          attrs: {
                            error:
                              _vm.$v.tourTo.ticket_redemption_instruction
                                .$error,
                            "max-length": 400,
                            rows: "4",
                          },
                          model: {
                            value:
                              _vm.$v.tourTo.ticket_redemption_instruction
                                .$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.tourTo.ticket_redemption_instruction,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.tourTo.ticket_redemption_instruction.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-btn", {
            attrs: {
              base: "",
              name: _vm.translations.saveContinue.text,
              loading: _vm.loading,
              "loading-name": "...",
            },
            on: { click: _vm.saveTranslate },
          }),
          _vm._v(" "),
          _vm.loading ? _c("loader") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }