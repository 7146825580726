var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-attributes-price-pop-up" }, [
    _c(
      "div",
      {
        staticClass: "pop-up__close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [
        _c("img", {
          attrs: { src: require("@/assets/img/svg/close.svg"), alt: "close" },
        }),
      ]
    ),
    _c("div", { staticClass: "add-attributes-price-pop-up__title" }, [
      _vm._v(_vm._s(_vm.translations.title.text)),
    ]),
    _c(
      "div",
      { ref: "option-name", staticClass: "add-attributes-price-pop-up__block" },
      [
        _c(
          "div",
          { staticClass: "add-attributes-price-pop-up__block--title" },
          [_vm._v(_vm._s(_vm.translations.contentOptionName.text))]
        ),
        _c(
          "div",
          { staticClass: "add-attributes-price-pop-up__block--name" },
          [
            _c("v-input", {
              attrs: {
                placeholder: _vm.translations.optionNamePlaceholder.text,
                error: _vm.optionNameError,
                "max-length": 250,
              },
              model: {
                value: _vm.attributesLocal.optionName,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.attributesLocal,
                    "optionName",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "attributesLocal.optionName",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.tour.languages.length != 0
      ? _c(
          "div",
          {
            ref: "option-languages",
            staticClass: "add-attributes-price-pop-up__block",
          },
          [
            _c(
              "div",
              { staticClass: "add-attributes-price-pop-up__block--title" },
              [_vm._v(_vm._s(_vm.translations.contentLanguages.text))]
            ),
            _c(
              "div",
              {
                staticClass: "add-attributes-price-pop-up__swapper",
                on: {
                  click: function ($event) {
                    return _vm.optionUseAllLang()
                  },
                },
              },
              [
                _c("Checkbox", {
                  attrs: {
                    switcher: "",
                    checked: _vm.attributesLocal.hideLanguages,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "add-attributes-price-pop-up__swapper--name" },
                  [_vm._v(_vm._s(_vm.translations.switcher.text))]
                ),
              ],
              1
            ),
            !_vm.attributesLocal.hideLanguages
              ? _c(
                  "div",
                  { staticClass: "add-attributes-price-pop-up__langs" },
                  [
                    _vm.optionLangsError
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "add-attributes-price-pop-up__langs--error",
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.translations.mustSelectLanguage.text
                                ) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "add-attributes-price-pop-up__langs--header",
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.headerLang.text) +
                              "\n        "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.headerPerson.text) +
                              "\n        "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { class: { "error-box": _vm.optionLangsError } },
                      _vm._l(
                        _vm.attributesLocal.tourLangs,
                        function (lang, index) {
                          return _c(
                            "div",
                            {
                              key: `lang-${index}`,
                              staticClass:
                                "add-attributes-price-pop-up__langs--item",
                              on: {
                                click: function ($event) {
                                  lang.is_active = !lang.is_active
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v(_vm._s(lang.language))]),
                              _c("Checkbox", {
                                attrs: {
                                  id: `lang-${index}`,
                                  default: "",
                                  checked: lang.is_active,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "add-attributes-price-pop-up__block" },
      [
        _c(
          "div",
          { staticClass: "add-attributes-price-pop-up__block--title" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.contentPickupIncluded.text) +
                "\n    "
            ),
          ]
        ),
        _c("VRadioGroup", {
          attrs: { name: "optionPickupIncluded" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "VRadioButton",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.translations.pickupIncludedYesRadio.text,
                            value: true,
                          },
                        },
                        "VRadioButton",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                  _c(
                    "VRadioButton",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.translations.pickupIncludedNoRadio.text,
                            value: false,
                          },
                        },
                        "VRadioButton",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.attributesLocal.optionPickupIncluded,
            callback: function ($$v) {
              _vm.$set(_vm.attributesLocal, "optionPickupIncluded", $$v)
            },
            expression: "attributesLocal.optionPickupIncluded",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { ref: "options", staticClass: "add-attributes-price-pop-up__block" },
      [
        _c(
          "div",
          { staticClass: "add-attributes-price-pop-up__block--title" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.contentOptionDetails.text) +
                "\n    "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "add-attributes-price-pop-up__block--subtitle" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.optionDetailsDescription.text) +
                "\n    "
            ),
          ]
        ),
        _vm._l(_vm.attributesLocal.options, function (option, index) {
          return _c(
            "div",
            {
              key: `option-${index}`,
              staticClass: "add-attributes-price-pop-up__option",
            },
            [
              _c(
                "div",
                {
                  staticClass: "checked",
                  on: {
                    click: function ($event) {
                      return _vm.changeActiveOption(index)
                    },
                  },
                },
                [
                  _c("Checkbox", {
                    attrs: {
                      default: "",
                      name: option.title,
                      checked: option.is_active,
                    },
                  }),
                ],
                1
              ),
              option.id == 4 && option.is_active
                ? _c(
                    "div",
                    {
                      staticClass:
                        "add-attributes-price-pop-up__option--details",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "add-attributes-price-pop-up__option--duration",
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              onkeypress:
                                "return event.charCode >= 48 && event.charCode <= 57",
                              onpaste: "return false;",
                              error: option.name_error,
                              type: "number",
                            },
                            on: { input: _vm.checkMaxDuration },
                            model: {
                              value: _vm.attributesLocal.durationTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.attributesLocal,
                                  "durationTime",
                                  $$v
                                )
                              },
                              expression: "attributesLocal.durationTime",
                            },
                          }),
                          _c("selection", {
                            attrs: {
                              default: "",
                              "default-name": _vm.attributesLocal.durationName,
                              items: _vm.durations,
                            },
                            on: { name: _vm.changeDuration },
                          }),
                        ],
                        1
                      ),
                      _c("VTextarea", {
                        attrs: {
                          "max-length": 250,
                          error: option.description_error,
                          placeholder:
                            _vm.translations.optionDetailsDescriptionPlaceholder
                              .text,
                          rows: "4",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkDescriptionName(index)
                          },
                        },
                        model: {
                          value: option.description,
                          callback: function ($$v) {
                            _vm.$set(option, "description", $$v)
                          },
                          expression: "option.description",
                        },
                      }),
                    ],
                    1
                  )
                : option.is_active
                ? _c(
                    "div",
                    {
                      staticClass:
                        "add-attributes-price-pop-up__option--details",
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder:
                            _vm.translations.optionDetailsNamePlaceholder.text,
                          error: option.name_error,
                          "max-length": 100,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkOptionName(index)
                          },
                        },
                        model: {
                          value: option.name,
                          callback: function ($$v) {
                            _vm.$set(
                              option,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "option.name",
                        },
                      }),
                      _c("VTextarea", {
                        attrs: {
                          "max-length": 250,
                          error: option.description_error,
                          placeholder:
                            _vm.translations.optionDetailsDescriptionPlaceholder
                              .text,
                          rows: "4",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkDescriptionName(index)
                          },
                        },
                        model: {
                          value: option.description,
                          callback: function ($$v) {
                            _vm.$set(option, "description", $$v)
                          },
                          expression: "option.description",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "add-attributes-price-pop-up__nav" },
      [
        _c("v-btn", {
          attrs: { outline: "", name: _vm.translations.cancelBtn.text },
          on: {
            click: function ($event) {
              return _vm.$emit("back")
            },
          },
        }),
        _vm.step == 3
          ? _c("div", { staticClass: "pagination row" }, [
              _c("div", { staticClass: "dot dot_page dot_page_active" }),
              _c("div", { staticClass: "dot dot_page" }),
              _c("div", { staticClass: "dot dot_page" }),
            ])
          : _vm._e(),
        _c("v-btn", {
          attrs: { base: "", name: _vm.textBtnSave },
          on: {
            click: function ($event) {
              return _vm.save()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }