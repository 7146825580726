import { format } from '@/utils/date';
import { parseTranslate } from '@/utils/translations';
import { getLocaleByLanguage } from './timeHelper';

export function checkAge(data, index) {
  let minAgeError = null;
  let maxAgeError = null;
  if (index !== 0) {
    if (Number(data[index].minAge) <= Number(data[index - 1].maxAge))
      minAgeError = true;
    else
      maxAgeError = false;

    if (Number(data[index].maxAge) <= Number(data[index].minAge))
      maxAgeError = true;
    else
      maxAgeError = false;
  } else if (Number(data[index].maxAge) <= Number(data[index].minAge)) {
    minAgeError = true;
    maxAgeError = true;
  } else {
    minAgeError = false;
    maxAgeError = false;
  }
  return { minAgeError, maxAgeError };
}

export function getDateSpan(oldSchedule, translations) {
  const startDate = new Date(oldSchedule.start_date).toDateString().slice(4);
  const endDate = translations.popupPersonThirdPageNoEndDate.text;
  if (oldSchedule.end_date <= '2098-03-12' && oldSchedule.end_date <= '2098-02-12')
    return `${startDate} ${new Date(oldSchedule.end_date).toDateString().slice(4)}`;
  return `${startDate} ${endDate}`;
}

export function isAdultOrSenior(member) {
  return member.id === 1 || member.id === 2;
}

export function getPriceFee(price, fee) {
  return (price - price * (fee / 100));
}

export const getAgeTieredGroupsDefault = ageCategoryId => ({
  minAge: null,
  minAgeError: false,
  maxAge: null,
  maxAgeError: false,
  price: '0',
  feePrice: '0',
  ageCategoryId
});

export function getAvailableDays(days) {
  let availableDays = '';
  let savedDate = null;
  for (const i in days) {
    if (days[i].is_active) {
      if (days[i].day === '7') {
        savedDate = '7';
      }
      availableDays += `${days[i].day},`;
    }
  }
  availableDays = availableDays.substring(0, availableDays.length - 1);
  if (savedDate != null) availableDays = availableDays !== savedDate ? `${availableDays},${savedDate}` : `${savedDate}`;

  return availableDays;
}
export function getAvailableOpeningTimes(openingHours, addTimes, openingScheduleTimes, anotherTimes) {
  if (openingHours) {
    return openingScheduleTimes.map(times =>
      `${format(new Date(times.startTime), 'HH:mm')},${format(new Date(times.endTime), 'HH:mm')}`
    ).join(',');
  }
  if (addTimes) {
    return anotherTimes.map(time => format(new Date(time.time), 'HH:mm:ss')).join(',');
  }

  return '';
}

export const FREE_CHILDREN_CATEGORIES = new Set([3,4,5]);

export function mapMembers(members, isGroupPricing, maxTravelers, rawTranslations) {
  const trans = rawTranslations.fields;
  return members.map(member => {
    let description = trans.RawNotAvailable.text;
    if (FREE_CHILDREN_CATEGORIES.has(member.age_category.id)) {
      const part = parseTranslate(trans.RawFreeAllowed.text, member.age_category.name?.toLowerCase());
      description += ' ' + part;
    }
    return {
      ...member,
      is_active: isGroupPricing,
      price: '0.00',
      feePrice: '0.00',
      groupCount: maxTravelers.toString(),
      ageTieredGroups: [getAgeTieredGroupsDefault(member.age_category.id)],
      description
    };
  });
}

export function formatPrice(price, lang, currency) {
  // Выбор локали на основе языка или установка локали по умолчанию
  const locale = getLocaleByLanguage(lang.toLowerCase()); 

  const formatter = currency ? new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    useGrouping: true
  }) : new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  });
  let formattedPrice = formatter.format(price);

  // Принудительное использование символа фунта для es-ES + GBP
  if (lang.toLowerCase() === 'es' && currency === 'GBP') {
    formattedPrice = formattedPrice.replace('GBP', '£');
  }

  return formattedPrice;
}