var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      staticClass: "booking-amended-confirm",
      attrs: {
        title: _vm.title,
        "dialog-classes": "booking-amended-confirm__dialog",
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("div", { staticClass: "booking-amended-confirm" }, [
        _vm.text
          ? _c("p", { staticClass: "booking-amended-confirm__text" }, [
              _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "booking-amended-confirm__footer" },
          [
            _c(
              "VButton",
              {
                staticClass: "booking-amended-confirm__button",
                attrs: { "orange-outline": "" },
                on: { click: _vm.cancel },
              },
              [_vm._v("\n          " + _vm._s(_vm.cancelText) + "\n        ")]
            ),
            _c(
              "VButton",
              {
                staticClass: "booking-amended-confirm__button",
                attrs: { base: "" },
                on: { click: _vm.confirm },
              },
              [_vm._v("\n          " + _vm._s(_vm.confirmText) + "\n        ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }