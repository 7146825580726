var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "18",
        viewBox: "0 0 20 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.2716 16.2852V14.6316C14.2716 13.7544 13.9438 12.9132 13.3604 12.2929C12.7769 11.6727 11.9856 11.3242 11.1605 11.3242H4.93827C4.11315 11.3242 3.32182 11.6727 2.73837 12.2929C2.15493 12.9132 1.82715 13.7544 1.82715 14.6316V16.2852",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.04959 8.01702C9.76782 8.01702 11.1607 6.53628 11.1607 4.70968C11.1607 2.88309 9.76782 1.40234 8.04959 1.40234C6.33137 1.40234 4.93848 2.88309 4.93848 4.70968C4.93848 6.53628 6.33137 8.01702 8.04959 8.01702Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.9378 16.2871V14.6334C18.9373 13.9006 18.7079 13.1888 18.2856 12.6096C17.8632 12.0305 17.2719 11.6168 16.6045 11.4336",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.4941 1.51172C14.1634 1.69387 14.7565 2.10762 15.1801 2.68773C15.6037 3.26785 15.8336 3.98133 15.8336 4.7157C15.8336 5.45008 15.6037 6.16356 15.1801 6.74367C14.7565 7.32379 14.1634 7.73754 13.4941 7.91969",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }