<template>
  <div class="index-price-page">
    <div v-if="loadingPage">
      <local-loader />
      <div class="index-price-page__loading-title">
        {{ translations.loader.text }}
      </div>
    </div>
    <div v-else>
      <BackLink v-if="isBuild" />
      <div v-for="(option, optionIndex) in options" :key="`option-${optionIndex}`" class="index-price-page__tickets">
        <div class="index-price-page__tickets--title">
          {{ option.name }}
        </div>
        <div
          v-for="(info, infoIndex) in option.additional_info"
          :key="`info-${infoIndex}`"
          class="index-price-page__additional-info"
        >
          <div class="index-price-page__additional-info--name">{{ info.name }}:</div>
          <div class="index-price-page__additional-info--description">
            {{ info.description }}
          </div>
        </div>
        <div class="index-price-page__settings">
          <div
            v-if="options.length > 1 && !isSpacialOfferNow"
            class="index-price-page__settings--del-option"
            @click="deleteOption(optionIndex)"
          >
            <base-icon icon-name="Trash" />
          </div>
          <v-btn
            class="edit-option"
            outline
            :name="translations.editBtn.text"
            @click="openEditOption(option.id, optionIndex)"
          />
        </div>
        <div
          v-for="(ticket, ticketIndex) in option.schedules"
          :key="`ticket-${ticketIndex}`"
          class="index-price-page__price"
        >
          <div class="index-price-page__price--header">
            <div
              v-if="ticket.end_date <= '2098-03-12' && ticket.end_date <= '2098-02-12'"
              class="index-price-page__price--date"
            >
              {{ showPriceDate(ticket.start_date) }}
              {{ showPriceDate(ticket.end_date) }}
            </div>
            <div v-else class="index-price-page__price--date">
              {{ showPriceDate(ticket.start_date) }}
              <span>{{ translations.noEndDate.text }}</span>
            </div>
            <div @click="editTicketSchedule(optionIndex, ticketIndex)">
              <base-icon icon-name="Edit" class="index-price-page__price--edit" />
            </div>
            <div v-if="!tour.is_group_pricing" class="index-price-page__price--type">
              {{ translations.maxPerBooking.text }} ({{ tour.max_travelers_per_booking }})
            </div>
          </div>
          <div
            v-for="(priceSchedule, priceScheduleIndex) in ticket.price_schedules"
            :key="`priceSchedule-${priceScheduleIndex}`"
            class="index-price-page__price--body"
            @mouseleave="showEditMenu = null"
          >
            <div class="index-price-page__row">
              {{ getDays(priceSchedule.weekdays) }}
            </div>
            <div class="index-price-page__row">
              {{ getTimes(priceSchedule.available_time, ticket.schedule_type.option) }}
            </div>
            <div
              v-for="(member, memberIndex) in priceSchedule.members"
              :key="`member-${memberIndex}`"
              class="index-price-page__member"
            >
              <div>
                {{ tour.is_group_pricing ? translations.travelerGroup.text : member.member.age_category.name }}
              </div>
              <p v-if="member.count_to != 0" class="index-price-page__member--count">
                ({{ member.count_from }}-{{ member.count_to }})
              </p>
              : {{ formatCurrency(member.price_without_discount, lang) }}
            </div>
            <div v-if="tour.is_group_pricing">
              {{ translations.maxPerBooking.text }} ({{ priceSchedule.max_travelers }})
            </div>
            <div @click="openTicketSettings(optionIndex, ticketIndex, priceScheduleIndex)">
              <base-icon icon-name="Edit" class="index-price-page__edit-btn" />
            </div>
            <div
              v-if="showEditMenu == `ticket-${optionIndex}-${ticketIndex}-${priceScheduleIndex}`"
              class="index-price-page__menu"
            >
              <div
                class="index-price-page__menu--item"
                @click="editTicketPrices(optionIndex, ticketIndex, priceScheduleIndex)"
              >
                {{ translations.kebabItem1.text }}
              </div>

              <div
                v-if="priceScheduleIndex == 0 && addNewPricingWork"
                class="index-price-page__menu--item"
                @click="
                  addNewSchedulePrices(
                    option.id,
                    ticket.schedule_type.title,
                    ticketIndex,
                    priceScheduleIndex,
                    optionIndex
                  )
                "
              >
                {{ translations.kebabItem2.text }}
              </div>
              <div
                v-if="option.schedules.length > 1 && priceScheduleIndex == 0 && !isSpacialOfferNow"
                class="index-price-page__menu--item"
                @click="deleteTicket(optionIndex, ticketIndex)"
              >
                {{ translations.kebabItem3.text }}
              </div>
              <div
                v-if="priceScheduleIndex != 0 && !isSpacialOfferNow"
                class="index-price-page__menu--item"
                @click="deletePrices(priceSchedule.id, priceScheduleIndex)"
              >
                {{ translations.kebabItem4.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="index-price-page__add-ticket" @click="addNewSchedule(option.id, option.name)">
          {{ translations.addSchedule.text }}
        </div>
        <div class="index-price-page__tickets--note">
          {{ translations.addScheduleNote.text }}
        </div>
      </div>
      <div class="index-price-page__options">
        <div class="index-price-page__options--info">
          <div class="index-price-page__options--title">
            {{ translations.addOption.text }}
          </div>
          <div>
            {{ translations.addOptionDescription.text }}
          </div>
        </div>
        <v-btn outline :name="translations.addOptionBtn.text" @click="getAddOptionTranslate()" />
      </div>
      <div v-if="errorEmpty" class="validation-error">
        {{ translations.descriptionSection.text }}
      </div>
      <v-btn v-if="isBuild" base :name="isBuild ? translations.btnSaveAndCont.text : 'Save'" @click="nextStep" />
    </div>
    <v-confirm-dialog
      ref="deleteDialog"
      :title="translations.contentDelete.text"
      :text="translations.deleteDescription.text"
      :confirm-text="translations.yesBtn.text"
      :cancel-text="translations.noBtn.text"
    />
    <edit-schedule
      v-if="editSchedule"
      :id="editTicketInfo.id"
      :is-active.sync="editSchedule"
      :tour="tour"
      :tour-lang="lang"
      :tour-start-time="editTicketInfo.startTime"
      :tour-end-time="editTicketInfo.endTime"
      :edit-translate="scheduleEditPageFields"
      @close="editSchedule = !editSchedule"
      @loading="loading = true"
    />
    <edit-prices
      v-if="editPriceKey > 0 && editPrices"
      :is-active.sync="editPrices"
      :tour="tour"
      :tour-lang="lang"
      :tour-members="editTicketInfo.members"
      :ticket-index="editTicketInfo.index"
      :price-schedule="editTicketInfo.priceSchedule"
      :schedule-type="editTicketInfo.scheduleType"
      :translations="pricePageFields"
      @close="editPrices = !editPrices"
      @loading="loading = true"
    />
    <add-schedule
      v-if="addSchedule"
      :is-active.sync="addSchedule"
      :tour="tour"
      :tour-lang="lang"
      :option-id="optionId"
      :option-title="optionTitle"
      :schedule-translate="scheduleAddPageFields"
      :prices-translate="pricePageFields"
      :improve-options-translate="improveOptionsPageFields"
      @close="addSchedule = !addSchedule"
      @loading="loading = true"
    />
    <add-option
      v-if="addOption"
      :is-active.sync="addOption"
      :tour="tour"
      :tour-lang="lang"
      :translations="optionsPageFields"
      :schedule-translate="scheduleAddPageFields"
      :prices-translate="pricePageFields"
      :improve-options-translate="improveOptionsPageFields"
      @close="addOption = !addOption"
      @loading="loading = true"
    />
    <edit-option
      v-if="editOption"
      :is-active.sync="editOption"
      :tour="tour"
      :option-id="optionId"
      :edit-option-info="editOptionInfo"
      :tour-lang="lang"
      :translations="optionsPageFields"
      @close="editOption = !editOption"
      @loading="loading = true"
    />
    <add-schedule-prices
      v-if="addSchedulePrices"
      :is-active.sync="addSchedulePrices"
      :tour="tour"
      :tour-lang="lang"
      :schedule-id="scheduleId"
      :add-times="editScheduleTypeName == translations.scheduleType1.text"
      :opening-hours="editScheduleTypeName == translations.scheduleType2.text"
      :translations="pricePageFields"
      :ticket-index="ticketIndex"
      @close="addSchedulePrices = !addSchedulePrices"
      @loading="loading = true"
    />
    <loader v-if="loading" />
  </div>
</template>
<script>
import VBtn from '@/components/controls/VBtn.vue';
import EditSchedule from '@/components/pop-up/price/EditSchedule.vue';
import EditPrices from '@/components/pop-up/price/EditPrices.vue';
import AddSchedule from '@/components/pop-up/price/AddSchedule.vue';
import AddOption from '@/components/pop-up/price/AddOption.vue';
import editOption from '@/components/pop-up/price/EditOption.vue';
import addSchedulePrices from '@/components/pop-up/price/AddSchedulePrices.vue';
import BaseIcon from '@/components/common/BaseIcon.vue';
import VConfirmDialog from '@/components/VConfirmDialog.vue';
import LocalLoader from '@/components/common/LocalLoader';
import Loader from '@/components/Loader';
import BackLink from '@/components/Product/BackLink.vue';

import { formatCurrency, formatTime } from '@/utils/utils';
import { getDayNames } from '~/utils/date';
import { getLocaleByLanguage } from '~/utils/timeHelper';

export default {
  components: {
    VBtn,
    EditSchedule,
    EditPrices,
    AddSchedule,
    AddOption,
    editOption,
    addSchedulePrices,
    BaseIcon,
    VConfirmDialog,
    LocalLoader,
    Loader,
    BackLink,
  },
  props: {
    isBuild: { type: Boolean, default: false },
    tourOb: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    params: { type: Object, default: () => ({}) },
    lang: { type: String, default: '' },
  },
  data: () => ({
    optionId: 0,
    priceScheduleIndex: null,
    ticketIndex: null,
    addNewPricingWork: true,
    editOptionInfo: [],
    optionTitle: '',
    editSchedule: false,
    editPrices: false,
    addSchedule: false,
    addSchedulePrices: false,
    addOption: false,
    editOption: false,
    editPriceKey: 0,
    editTicketInfo: {
      id: null,
      members: [],
      intervals: [],
      available_days: '',
      startTime: null,
      endTime: null,
      ticketAmount: 8,
    },
    editScheduleTypeName: null,
    scheduleId: null,
    loading: false,
    scheduleEditPageFields: [],
    pricePageFields: [],
    scheduleAddPageFields: [],
    improveOptionsPageFields: [],
    optionsPageFields: [],
    tour: {},
    options: [],
    errorEmpty: false,
    loadingPage: false,
    showEditMenu: null,
  }),
  async fetch() {
    this.loadingPage = true;
    this.tour = this.tourOb;
    this.options = await this.getOptions();
    this.loadingPage = false;
  },

  computed: {
    isSpacialOfferNow() {
      const dateNow = new Date();
      dateNow.setHours(0, 0, 0, 0);
      return dateNow <= new Date(this.tour.discount_info[0]?.discount_end_date);
    },
  },

  methods: {
    formatCurrency,
    formatTime,
    async getOptions() {
      return await this.$axios.$get(`/api/v1/get-tour-schedule-options/`, {
        params: {
          tour_id: this.tour.id,
          language: this.lang,
        },
      });
    },
    showPriceDate(input) {
      const locale = getLocaleByLanguage(this.lang);
      return new Date(input).toLocaleString(locale, { dateStyle: 'medium' });
    },
    getDays(weekdays) {
      const names = getDayNames(this.lang);
      const days = weekdays.map((raw) => names[(raw.day_number - 1)]);
      return days.join(', ');
    },
    getTimes(availableTime, scheduleType) {
      const flattenedTimes = availableTime.flat();
      return formatTime({
        time: flattenedTimes,
        lang: this.lang,
        scheduleType: scheduleType,
      });
    },
    openTicketSettings(optionIndex, ticketIndex, priceSchedulesIndex) {
      this.addNewPricingWork = true;
      this.showEditMenu =
        this.showEditMenu === null ? `ticket-${optionIndex}-${ticketIndex}-${priceSchedulesIndex}` : null;
      let weekdaysCount = 0;
      for (const p in this.options[optionIndex].schedules[ticketIndex].price_schedules) {
        weekdaysCount += this.options[optionIndex].schedules[ticketIndex].price_schedules[p].weekdays.length;
      }
      if (weekdaysCount > 6) {
        this.addNewPricingWork = false;
      }
    },
    closeTicketSettings() {
      this.showEditMenu = null;
    },
    async deleteTicket(optionIndex, index) {
      this.loading = true;
      await this.$axios.$delete(
        `/api/v1/delete-tour-schedule/?schedule_id=${this.options[optionIndex].schedules[index].id}`
      );
      this.tour = await this.$axios.$get(`/api/v1/tour/${this.params.product}?language=${this.lang}`);
      this.options = await this.getOptions();
      this.loading = false;
    },
    async deletePrices(id, index) {
      this.loading = true;
      await this.$axios.$put(`/api/v1/set-week-schedule/?week_schedule_id=${id}&is_delete=true`);
      this.loading = false;
    },
    async deleteOption(index) {
      const needDelete = await this.$refs.deleteDialog.open();
      if (needDelete) {
        this.loading = true;
        await this.$axios.$delete(`/api/v1/delete-tour-schedule-option/?option_id=${this.options[index].id}`);
        this.isDelOption = false;
        this.options = await this.getOptions();
        this.loading = false;
      }
    },
    editTicket(optionIndex, ticketIndex, priceSchedulesIndex = null) {
      this.editTicketInfo.available_days = this.options[optionIndex].schedules[ticketIndex].available_days;
      this.editTicketInfo.startTime = this.options[optionIndex].schedules[ticketIndex].start_date;
      this.editTicketInfo.endTime = this.options[optionIndex].schedules[ticketIndex].end_date;
      this.editTicketInfo.id = this.options[optionIndex].schedules[ticketIndex].id;
      this.editTicketInfo.scheduleType = this.options[optionIndex].schedules[ticketIndex].schedule_type;
      this.editTicketInfo.optionIndex = optionIndex;
      this.editTicketInfo.ticketIndex = ticketIndex;
      if (priceSchedulesIndex != null) {
        this.editTicketInfo.members = this.options[optionIndex].schedules[ticketIndex].price_schedules[
          priceSchedulesIndex
        ].members;
        this.editTicketInfo.priceSchedule = this.options[optionIndex].schedules[ticketIndex].price_schedules[
          priceSchedulesIndex
        ];
        this.editTicketInfo.priceSchedulesIndex = priceSchedulesIndex;
      }
    },
    async openEditOption(id, index) {
      this.optionId = id;
      this.editOptionInfo = this.options[index];
      this.loading = true;
      await this.getOptionTranslate();
      this.loading = false;
      this.editOption = !this.editOption;
    },
    async editTicketSchedule(optionIndex, ticketIndex) {
      this.editTicket(optionIndex, ticketIndex);
      this.loading = true;
      if (this.scheduleEditPageFields.length === 0) {
        const scheduleEditPageTranslate = await this.$axios.$get(
          `/api/v1/pages/lk-popup-edit-schedule?language=${this.lang}`
        );
        this.scheduleEditPageFields = scheduleEditPageTranslate.fields;
      }
      this.loading = false;
      this.editSchedule = !this.editSchedule;
    },
    async editTicketPrices(optionIndex, ticketIndex, priceSchedulesIndex) {
      this.editTicket(optionIndex, ticketIndex, priceSchedulesIndex);
      this.editPriceKey += 1;
      this.loading = true;
      await this.getPricesTranslate();
      this.loading = false;
      this.editPrices = !this.editPrices;
    },
    async addNewSchedule(id, title) {
      this.optionId = id;
      this.optionTitle = title;
      this.loading = true;
      await this.getImproveOptionsTranslate();
      await this.getScheduleTranslate();
      await this.getPricesTranslate();
      this.loading = false;
      this.addSchedule = !this.addSchedule;
      this.errorEmpty = false;
    },
    async addNewSchedulePrices(optionId, scheduleTypeName, ticketIndex, priceScheduleIndex, optionIndex) {
      this.optionId = optionId;
      this.ticketIndex = ticketIndex;
      this.editScheduleTypeName = scheduleTypeName;
      this.scheduleId = this.options[optionIndex].schedules[ticketIndex].price_schedules[priceScheduleIndex].id;
      this.loading = true;
      await this.getPricesTranslate();
      this.loading = false;
      this.addSchedulePrices = !this.addSchedulePrices;
    },
    async getAddOptionTranslate() {
      this.loading = true;
      await this.getOptionTranslate();
      await this.getImproveOptionsTranslate();
      await this.getScheduleTranslate();
      await this.getPricesTranslate();
      this.loading = false;
      this.addOption = !this.addOption;
    },
    // Translations
    async getOptionTranslate() {
      if (this.optionsPageFields.length === 0) {
        const optionPageTranslate = await this.$axios.$get(`/api/v1/pages/lk-popup-option?language=${this.lang}`);
        this.optionsPageFields = optionPageTranslate.fields;
      }
    },
    async getImproveOptionsTranslate() {
      if (this.improveOptionsPageFields.length === 0) {
        const improveOptionPageTranslate = await this.$axios.$get(
          `/api/v1/pages/lk-popup-improve-options?language=${this.lang}`
        );
        this.improveOptionsPageFields = improveOptionPageTranslate.fields;
      }
    },
    async getScheduleTranslate() {
      if (this.scheduleAddPageFields.length === 0) {
        const scheduleOptionPageTranslate = await this.$axios.$get(
          `/api/v1/pages/lk-popup-add-schedule?language=${this.lang}`
        );
        this.scheduleAddPageFields = scheduleOptionPageTranslate.fields;
      }
    },
    async getPricesTranslate() {
      if (this.pricePageFields.length === 0) {
        const pricePageTranslate = await this.$axios.$get(`/api/v1/pages/lk-popup-prices?language=${this.lang}`);
        this.pricePageFields = pricePageTranslate.fields;
      }
    },
    // Next
    async nextStep() {
      this.errorEmpty = !this.tour.get_tour_schedule.length > 0;
      if (!this.errorEmpty) {
        this.loading = true;
        if (this.isBuild) {
          const productStepBody = new FormData();
          productStepBody.append('schedules', true);
          await this.$axios.$put(`api/v1/set-tour-creation-steps/${this.tour.id}`, productStepBody).then(() => {
            this.$router.push(this.localePath(`/product/${this.tour.slug}/build/booking-and-tickets/booking-process`));
          });
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.index-price-page {
  padding-bottom: 25px;

  .v-local-loader {
    margin-top: 150px;
  }

  &__loading-title {
    text-align: center;
    margin-top: 30px;
  }

  &__tickets {
    max-width: 718px;
    position: relative;
    width: fit-content;
    padding: 26px 20px 15px 20px;
    background: #f9f9f9;
    margin-bottom: 49px;
    border: 1px solid #dcdfe7;

    &--title {
      max-width: 540px;
      font-weight: bold;
      word-break: break-word;
      word-wrap: anywhere;
      margin-bottom: 5px;
    }

    &--note {
      border-top: 1px solid #ececec;
      padding-top: 21px;
      color: #888a93;
    }
  }

  &__additional-info {
    width: 700px;
    display: flex;
    flex-wrap: wrap;

    &--name {
      font-size: 16px;
      font-weight: 600;
      margin-right: 5px;
    }

    &--description {
      max-width: 670px;
      word-wrap: break-word;
      font-size: 16px;
    }
  }

  &__settings {
    display: flex;
    position: absolute;
    top: 15px;
    right: 20px;

    .v-btn {
      width: 70px;
      height: 40px;
    }

    &--del-option {
      z-index: 9;
      cursor: pointer;
      margin-right: 10px;
      width: 40px;
      height: 40px;
      background: $white;
      opacity: 1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray;
      transition: all 0.3s ease-out;

      &:hover {
        background: $orange;

        svg {
          color: white;
        }
      }
    }
  }

  &__price {
    background: $white;
    width: 676px;
    margin-top: 25px;
    padding: 26px 20px 15px 20px;
    border-radius: 4px;
    border: 1px solid #dcdfe7;
    box-shadow: 0px 2px 8px rgba(30, 40, 67, 0.1);

    &--header {
      position: relative;
      margin-bottom: 23px;
    }

    &--body {
      position: relative;
      border-top: 1px solid #ececec;
      padding-left: 35px;
      padding-top: 17px;
    }

    &--date {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      padding-left: 35px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-image: url('~/assets/img/svg/calendar.svg');
        left: 0;
      }

      span {
        margin-left: 10px;
        color: $gray-three;
        font-size: 14px;
      }
    }

    &--edit {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--type {
      padding-left: 35px;
    }
  }

  &__edit-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 17px;
  }

  &__row {
    margin-bottom: 8px;
  }

  &__member {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &--count {
      margin-left: 5px;
      margin-right: 2px;
      font-size: 14px;
      color: $light-gray;
    }
  }

  &__menu {
    left: 367px;
    top: 40px;
    position: absolute;
    background: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 254px;
    z-index: 10;

    &--item {
      cursor: pointer;
      padding: 11px 71px 10px 14px;

      &:hover {
        background: #999999;
      }
    }
  }

  &__add-ticket {
    margin-top: 25px;
    margin-bottom: 21px;
    cursor: pointer;
    font-weight: bold;
    color: $primary;
  }

  &__options {
    padding: 26px 20px 29px 20px;
    border-radius: 4px;
    border: 1px solid #dcdfe7;
    width: 718px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &--info {
      margin-right: 50px;
      width: 466px;
    }

    &--title {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .v-btn {
      min-width: 136px;
      height: 40px;
    }
  }

  &__max-per-booking {
    &--title {
      font-weight: bold;
    }

    .v-input {
      margin-top: 0px;
      margin-bottom: 15px;

      input {
        width: 80px;
        height: 48px;
      }
    }
  }
}
</style>
