var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpenModal
    ? _c("div", { staticClass: "wrapper", on: { click: _vm.closeWindow } }, [
        _c("div", { staticClass: "pop-up-screen-resolution-notice" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "pop-up-screen-resolution-notice__title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.screenResolutionNotice?.name?.text) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-screen-resolution-notice__text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.screenResolutionNotice?.text?.text) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-screen-resolution-notice__nav" }, [
            _c(
              "div",
              { staticClass: "continue", on: { click: _vm.closeWindow } },
              [
                _c("Btn", {
                  attrs: {
                    base: "",
                    name: _vm.screenResolutionNotice?.understoodBtn?.text,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }