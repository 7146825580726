var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "map" },
    [
      !_vm.isOpenBigMap
        ? _c(
            "span",
            {
              staticClass: "open",
              on: {
                click: function ($event) {
                  _vm.isOpenBigMap = true
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/full.svg"),
                  alt: "full-arrow",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("base-map", {
        attrs: {
          id: _vm.id,
          "location-map": _vm.locationMap,
          "circle-list": _vm.circleList,
        },
        on: {
          setPoint: _vm.setPoint,
          moveMarker: _vm.moveMarker,
          addMarker: function ($event) {
            return _vm.$emit("addMarker", $event)
          },
        },
      }),
      _c("big-map", {
        attrs: {
          id: _vm.id,
          "is-active": _vm.isOpenBigMap,
          "location-map": _vm.locationMap,
          "circle-list": _vm.circleList,
        },
        on: {
          setPoint: _vm.setPoint,
          moveMarker: _vm.moveMarker,
          close: function ($event) {
            _vm.isOpenBigMap = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }