<template>
  <div class="categories-form">
    <CategoriesSelector
      ref="categoriesSelector"
      :categories="categories"
      :tags="tags"
      :subcategories="subcategories"
      :category-id="categoryId"
      :translations="translations"
      @update="updateCategories"
    />

    <VField
      class="categories-form__field"
      :label="translations.placeLabel.text"
      :description="translations.placeDescription.text"
    >
      <GoogleAutocomplete
        v-model="city"
        :error="$v.cityPoint.$error"
        :placeholder="translations.palcePlaceholder.text"
        :options="{ types: ['(cities)'] }"
        @update:point="updateCity"
      />
    </VField>

    <VBtn class="categories-form__button" :loading="loading" base :name="saveBtnText" @click="save" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import VField from '@/components/controls/VField.vue';
import GoogleAutocomplete from '@/components/GoogleAutocomplete.vue';
import VBtn from '@/components/controls/VBtn.vue';
import CategoriesSelector from '@/components/CategoriesSelector.vue';

export default {
  name: 'CategoryForm',
  components: { VBtn, GoogleAutocomplete, VField, CategoriesSelector },

  props: {
    categoryId: { type: Number, default: null },
    tags: { type: Array, default: () => [] },
    subcategories: { type: Array, default: () => [] },
    place: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) },
    saveBtnText: { type: String, default: '' },
  },

  data() {
    return {
      city: '',
      cityPoint: null,
      categories: [],
      selectedSubcategories: [],
      selectedCategoryId: null,
      selectedTags: [],
    };
  },

  async fetch() {
    const lang = this.$cookies.get('i18n_redirected');
    this.categories = await this.$axios.$get(`/api/v1/get-categories-list/?language=${lang}`);

    if (this.place) {
      this.city = this.place;
      this.cityPoint = this.place;
    }
  },

  validations() {
    return {
      cityPoint: { required },
    };
  },

  methods: {
    updateCity(point) {
      this.city = point.address;
      this.cityPoint = point;
    },

    updateCategories({ subcategories, tags, categoryId }) {
      this.selectedSubcategories = subcategories;
      this.selectedTags = tags;
      this.selectedCategoryId = categoryId;
    },

    save() {
      const form = this.$refs.categoriesSelector;
      form.$v.$touch();
      this.$v.$touch();

      if (this.$v.$invalid || form.$v.$invalid) {
        return;
      }

      this.$emit('save', {
        category: this.selectedCategoryId,
        subcategories: this.selectedSubcategories.map((subcategory) => subcategory.id).join(','),
        tags: this.selectedTags.map((tag) => tag.id).join(','),
        place: this.city,
      });
    },
  },
};
</script>

<style lang="scss">
.google-autocomplete {
  background: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDE5QzE1LjQxODMgMTkgMTkgMTUuNDE4MyAxOSAxMUMxOSA2LjU4MTcyIDE1LjQxODMgMyAxMSAzQzYuNTgxNzIgMyAzIDYuNTgxNzIgMyAxMUMzIDE1LjQxODMgNi41ODE3MiAxOSAxMSAxOVoiIHN0cm9rZT0iIzFFMjg0MyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTIwLjk5ODQgMjEuMDAwNEwxNi42NDg0IDE2LjY1MDQiIHN0cm9rZT0iIzFFMjg0MyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 20px;
}

.categories-form {
  &__field {
    margin-top: 32px;
  }

  &__button {
    margin-top: 72px;
  }
}
</style>
