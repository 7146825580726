var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-examples" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "pop-up-examples__title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.examplesTranslate.examplesPopupTitle.text) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-examples__content" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "dot dot_orange" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.examplesTranslate.examplesPopupItem1.text) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "dot dot_orange" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.examplesTranslate.examplesPopupItem2.text) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "dot dot_orange" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.examplesTranslate.examplesPopupItem3.text) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "dot dot_orange" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.examplesTranslate.examplesPopupItem4.text) +
                  "\n      "
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }