var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      staticClass: "provide-missing-information",
      attrs: {
        size: "large-plus",
        title: _vm.translations.provideMissingInformation.text,
      },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c("p", { staticClass: "provide-missing-information__content" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.translations.performanceLinkDescription.text) +
            "\n  "
        ),
      ]),
      _c(
        "VButton",
        {
          staticClass: "provide-missing-information__button",
          attrs: {
            base: "",
            to: _vm.localePath({ name: "performance" }),
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(_vm.translations.performanceLink.text))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }