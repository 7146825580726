<template>
  <div class="preview__photos">
    <div class="photos">
      <div class="photos__big">
        <img :src="bigPhoto.get_image">
      </div>
      <div class="photos__small">
        <div v-for="(image, key) in smallPhotos" :key="key" class="photos__small-image">
          <img :src="image.get_small_image" />
        </div>
      </div>
      <div class="photos__show-all">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="2" height="2" fill="currentColor"></rect> <rect x="3" width="2" height="2" fill="currentColor"></rect> <rect x="3" y="3" width="2" height="2" fill="currentColor"></rect> <rect y="3" width="2" height="2" fill="currentColor"></rect> <rect x="6" width="2" height="2" fill="currentColor"></rect> <rect x="6" y="3" width="2" height="2" fill="currentColor"></rect> <rect x="3" y="6" width="2" height="2" fill="currentColor"></rect> <rect y="6" width="2" height="2" fill="currentColor"></rect> <rect x="6" y="6" width="2" height="2" fill="currentColor"></rect></svg>
        {{ translates.btnShowPhotos.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotoList',
  props: {
    tour: { type: Object, default: () => ({}) },
    translates: { type: Object, default: () => ({}) },
  },
  computed: {
    photos() {
      return this.tour.get_slider;
    },
    bigPhoto() {
      return this.tour.get_slider[0];
    },
    smallPhotos() {
      return this.tour.get_slider.slice(1,5);
    }
  }
}
</script>

<style lang="scss">
.photos {
  position: relative;
  border-radius: 12px;
  display: flex;
  gap: 7px;
  overflow: hidden;

  &__big > img {
    width: 595px;
    height: 407px;
    object-fit: none;
  }
  &__small {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7px;

    &-image > img {
      height: 200px;
    }
  }
  &__show-all {
    position: absolute;
    pointer-events: none;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 16px;
    line-height: 19px;
    margin-left: auto;
    padding: 7px 17px 5px 10px;

    svg {
      color: #00a1ff;
      height: 12px;
      margin-right: 12px;
      width: 12px;
    }
  }
}
</style>
