var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-field" },
    [
      _vm.label || _vm.description
        ? _c("div", { staticClass: "v-field__info" }, [
            _vm.label
              ? _c("label", { staticClass: "v-field__label" }, [
                  _vm._v(_vm._s(_vm.label) + " "),
                  _vm.optional
                    ? _c("span", { staticClass: "v-field__label-optional" }, [
                        _vm._v("(" + _vm._s(_vm.optional) + ")"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.description
              ? _c(
                  "p",
                  { staticClass: "v-field__description" },
                  [
                    _vm._t("description", function () {
                      return [_vm._v(_vm._s(_vm.description))]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._t("default"),
      _vm.error
        ? _c("p", { staticClass: "v-field__error error-text" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }