var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.meetingPoint !== null
    ? _c("div", { staticClass: "preview__section" }, [
        _c("div", { staticClass: "preview__title" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.translations.meetingPointTitle.text) + "\n  "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "preview__text" }, [
          _vm._v(_vm._s(_vm.meetingPoint.address)),
        ]),
        _vm._v(" "),
        _vm.meetingPoint.description ? _c("br") : _vm._e(),
        _vm._v(" "),
        _vm.meetingPoint.description
          ? _c("p", { staticClass: "preview__text" }, [
              _vm._v(_vm._s(_vm.meetingPoint.description)),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }