<template>
  <VDialog v-model="modelVisible" :title="fields.title.text">
    <div class="pop-up-insurance-information">
      <div class="form">
        <div class="form__have-insurance">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentNavePolicy.text }}
          </div>
          <div class="have-insurance__radioButtons">
            <VRadioGroup v-model="newPolicyInformation.havePolicy" name="havePolicy">
              <template #default="{ on, attrs }">
                <VRadioButton :label="fields.yesBtn.text" :value="true" v-bind="attrs" v-on="on" />
                <VRadioButton :label="fields.noBtn.text" :value="false" v-bind="attrs" v-on="on" />
              </template>
            </VRadioGroup>
          </div>
        </div>
        <div v-if="newPolicyInformation.havePolicy" class="form__policy-number">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentNumber.text }}
          </div>
          <div class="form__policy-number__hint">
            {{ fields.contentNumberSubtitle.text }}
          </div>
          <div class="form__policy-number__input">
            <InputFields
              v-model.trim="$v.newPolicyInformation.policyNumber.$model"
              :class="{ 'input-error': $v.newPolicyInformation.policyNumber.$error }"
              :maxlength="40"
              :placeholder="fields.contentNumberPlaceholder.text"
            />
            <div v-if="$v.newPolicyInformation.policyNumber.$error" class="error-text" style="padding: 6px; margin-top: -4px">
              {{ fields.errorFieldEmpty.text }}
            </div>
          </div>
        </div>
        <div v-if="newPolicyInformation.havePolicy" class="form__select-currency">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentCurrency.text }}
          </div>
          <VSelect
            v-model="newPolicyInformation.currency"
            class="form__select-currency__selection"
            :items="normalizedCurrencies"
          />
        </div>
        <div v-if="newPolicyInformation.havePolicy" class="form__amount">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentAmountCovered.text }}
          </div>
          <div class="form__amount__input">
            <InputFields
              v-model.trim="$v.newPolicyInformation.amountCovered.$model"
              :maxlength="14"
              :class="{ 'input-error': $v.newPolicyInformation.amountCovered.$error }"
              :placeholder="fields.contentAmountCoveredPlaceholder.text"
            />
            <div v-if="$v.newPolicyInformation.amountCovered.$error" class="error-text" style="padding: 6px; margin-top: -4px">
              <template v-if="!$v.newPolicyInformation.amountCovered.numeric">{{ fields.errorOnlyNumbers.text }}</template>
              <template v-else>{{ fields.errorFieldEmpty.text }}</template>
            </div>
          </div>
        </div>
        <div v-if="newPolicyInformation.havePolicy" class="form__amount-occurrence">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentAmountOccurrence.text }}
          </div>
          <div class="form__amount-occurrence__input">
            <InputFields
              v-model.trim="$v.newPolicyInformation.amountPerOccurrence.$model"
              :maxlength="14"
              :class="{ 'input-error': $v.newPolicyInformation.amountPerOccurrence.$error }"
              :placeholder="fields.contentAmountOccurrencePlaceholder.text"
            />
            <div
              v-if="$v.newPolicyInformation.amountPerOccurrence.$error"
              class="error-text"
              style="padding: 6px; margin-top: -4px"
            >
              <template v-if="!$v.newPolicyInformation.amountPerOccurrence.numeric">{{ fields.errorOnlyNumbers.text }}</template>
              <template v-else>{{ fields.errorFieldEmpty.text }}</template>
            </div>
          </div>
        </div>
        <div v-if="newPolicyInformation.havePolicy" class="form__expire-date">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentPolicyExpire.text }}
          </div>
          <div class="form__expire-date__date-picker">
            <date-picker
              v-model="newPolicyInformation.expirationDate"
              v-model.trim="$v.newPolicyInformation.expirationDate.$model"
              :placeholder="fields.contentPolicyExpirePlaceholder.text"
							value-type="YYYY-MM-DD"
							:lang="calendarExtra.lang"
							:format="calendarExtra.format"
              :editable="false"
              :disabled-date="disabledBedoreToday"
            />
            <div v-if="$v.newPolicyInformation.expirationDate.$error" class="error-text" style="padding: 6px; margin-top: -4px">
              {{ fields.errorFieldEmpty.text }}
            </div>
          </div>
        </div>
        <div v-if="newPolicyInformation.havePolicy" class="form__upload">
          <div class="pop-up-insurance-information__subtitles">
            {{ fields.contentInsurance.text }}
          </div>
          <VFileInput
            v-if="!insuranceFile || !insuranceFile.name"
            :accept="$options.INPUT_ACCESS_FILES"
            :error="$v.insuranceFile.$error"
            @change="uploadFile"
          >
            <div>
              <div class="form__upload__file__text">
                {{ fields.contentInsurancePlaceholder.text }}
              </div>
              <div class="form__upload__file__hint">
                {{ fields.contentInsurancePlaceholderSize.text }}
              </div>
            </div>
          </VFileInput>
          <div v-else class="form__upload__file form__upload__file--with-file">
            <div class="form__upload__file__name">
              {{ insuranceFile.name }}
            </div>
            <div class="form__upload__file__delete-icon" @click="deleteInsurance">
              <img src="@/assets/img/svg/delete-icon.svg" alt="Delete insurance" />
            </div>
          </div>
          <div v-if="$v.insuranceFile.$error" class="error-text" style="padding: 6px; margin-top: -4px">
            <template v-if="!$v.insuranceFile.required">{{ fields.errorFileEmpty.text }}</template>
            <template v-else-if="!$v.insuranceFile.maxFileSize">{{ fields.errorFileSize.text }}</template>
            <template v-else-if="!$v.insuranceFile.fileFormat">{{
              `${fields.errorFileFormat.text} (${$options.INPUT_ACCESS_FILES})`
            }}</template>
          </div>
        </div>
      </div>
      <div class="actions">
        <div @click="closePopup">
          <Btn outline :name="fields.cancelBtn.text" class="actions__button" />
        </div>
        <div @click="saveInsurance">
          <Btn base :name="fields.saveBtn.text" class="actions__button" />
        </div>
      </div>
    </div>
  </VDialog>
</template>

<script>
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import Btn from '@/components/controls/Btn'
import InputFields from '@/components/controls/Input-fields'
import VSelect from '@/components/controls/VSelect'
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VFileInput from '@/components/controls/VFileInput.vue'
import VDialog from '@/components/common/VDialog.vue'
import { calendarExtra } from '~/utils/componentHelpers'

const MEGABYTE_IN_BYTES = 1000000
const MAX_FILE_SIZE = 5 * MEGABYTE_IN_BYTES
const ACCESS_FILES = ['image/jpeg', 'image/png', 'application/pdf']
const INPUT_ACCESS_FILES = '.jfif, .jpj, .jpg, .jpeg, .pjpeg, .pdf, .png'

export default {
  name: 'PopupInsurance',
  INPUT_ACCESS_FILES,
  ACCESS_FILES,
  components: {
    VRadioButton,
    VRadioGroup,
    VFileInput,
    Btn,
    InputFields,
    VSelect,
    DatePicker,
    VDialog
  },
  props: {
    open: { type: Boolean, default: false },
    fields: { type: Object, default: () => ({}) },
    insurance: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      newPolicyInformation: {
        havePolicy: null,
        policyNumber: null,
        amountCovered: null,
        amountPerOccurrence: null,
        expirationDate: null,
        copyOfInsurance: null,
        currency: ''
      },
      insuranceFile: null
    }
  },
  validations() {
    return {
      newPolicyInformation: {
        policyNumber: {
          required,
          maxLength: maxLength(40)
        },
        amountCovered: {
          required,
          numeric: value => /^([0-9])+(\.([0-9])+)?$/g.test(value),
          maxLength: maxLength(14)
        },
        amountPerOccurrence: {
          required,
          numeric: value => /^([0-9])+(\.([0-9])+)?$/g.test(value),
          maxLength: maxLength(40)
        },
        expirationDate: {
          required
        }
      },
      insuranceFile: {
        required: requiredIf(() => !this.newPolicyInformation.copyOfInsurance),
        maxFileSize: this.maxFileSize(MAX_FILE_SIZE),
        fileFormat: this.isFileFormat(ACCESS_FILES)
      }
    }
  },
  computed: {
    ...mapState(['currencies']),
    calendarExtra,

    normalizedCurrencies() {
      return this.currencies.map(currency => ({
        ...currency,
        originName: currency.name,
        name: `${currency.symbol} ${currency.name}`
      }))
    },

    selectedCurrency() {
      return this.currencies.find(currency => this.newPolicyInformation.currency.split(' ')[0] === currency.symbol)
    },

    modelVisible: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },

  watch: {
    open() {
      if (this.open) {
        const selectedCurrency = this.normalizedCurrencies.find(({ id }) => Number(this.insurance.currency) === Number(id))
        this.newPolicyInformation = {
          ...this.insurance,
          copyOfInsurance: this.insurance.copyOfInsurance?.split('uploads/')[1],
          expirationDate: this.insurance.expirationDate,
          currency: selectedCurrency ? selectedCurrency.name : this.normalizedCurrencies[0].name
        }

        this.insuranceFile = { name: this.newPolicyInformation.copyOfInsurance }
      }
    }
  },

  methods: {
    maxFileSize(maxSize) {
      return value => (value?.size ? value?.size < maxSize : true)
    },
    isFileFormat(accessFileTypes) {
      return value => (value?.type ? accessFileTypes.includes(value?.type) : true)
    },
    disabledBedoreToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    closePopup() {
      this.$emit('close')
    },
    changeHavePolicy(value) {
      this.newPolicyInformation.havePolicy = value
    },
    uploadFile(file) {
      this.insuranceFile = file
      this.$v.insuranceFile.$touch()
    },
    deleteInsurance() {
      this.insuranceFile = ''
      this.newPolicyInformation.copyOfInsurance = ''
      this.$v.insuranceFile.$touch()
    },
    checkValidate() {
      if (!this.newPolicyInformation.havePolicy) {
        return true
      }

      this.$v.$touch()
      return !this.$v.$invalid
    },
    async saveInsurance() {
      if (this.checkValidate()) {
        const bodyFormData = new FormData()
        if (this.newPolicyInformation.havePolicy) {
          if (this.insuranceFile.name) {
            bodyFormData.append('public_liability_insurance', this.insuranceFile)
          }

          await this.$axios.$put('/api/v1/seller-setting/', bodyFormData, {
            params: {
              have_policy: 'True',
              policy_number: this.newPolicyInformation.policyNumber,
              currency: this.selectedCurrency.id,
              amount_covered: this.newPolicyInformation.amountCovered,
              amount_per_occurrence_covered: this.newPolicyInformation.amountPerOccurrence,
              insurance_policy_expire: this.newPolicyInformation.expirationDate.split('.').join('-')
            }
          })
        } else {
          bodyFormData.append('public_liability_insurance', '')

          await this.$axios.$put('/api/v1/seller-setting/', bodyFormData, {
            params: {
              have_policy: 'False',
              policy_number: '',
              currency: '1',
              amount_covered: '',
              amount_per_occurrence_covered: '',
              insurance_policy_expire: ''
            }
          })
        }

        this.$emit('close')
        this.$router.go(this.localePath())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-up-insurance-information {
  position: relative;
  background: $white;
  padding-top: 48px;
  width: 830px;

  &__subtitles {
    font-size: 18px;
    font-weight: bold;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__have-insurance,
    .have-insurance {
      &__radioButtons {
        margin-top: 20px;

        &__radio:not(:first-child) {
          margin-top: 12px;
        }
      }
    }

    &__policy-number {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__hint {
        font-size: 13px;
      }

      &__input {
        width: 100%;

        & input {
          width: 100%;
        }
      }
    }

    &__select-currency {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__selection {
        width: 400px;
      }
    }

    &__amount {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__input {
        width: 70%;

        & input {
          width: 70%;
        }
      }
    }

    &__amount-occurrence {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__input {
        width: 70%;

        & input {
          width: 70%;
        }
      }
    }

    &__expire-date {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &__upload {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__file {
        &--without-file {
          width: 165px;
          height: 114px;
          border: 1px dashed #6e6e6e;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          transition: all 0.3s;
          text-align: center;

          &:hover,
          &:hover &__text {
            color: $primary;
            cursor: pointer;
            border-color: $primary;
          }
        }

        &--with-file {
          display: flex;
          justify-content: flex-start;
          gap: 5px;
          align-items: center;
        }

        &__text {
          font-weight: bold;
        }

        &__hint {
          font-size: 12px;
        }

        &__name {
          color: $orange;
          word-break: break-word;
          overflow-wrap: anywhere;
        }

        &__delete-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 26px;
          cursor: pointer;
        }
      }
    }
  }

  .actions {
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
    gap: 25px;

    &__button {
      width: 70px;
      height: 40px;
      min-width: 100px;
    }
  }
}
</style>
