var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        title: _vm.translations.bookingAcknowledgeTitle.text,
        "dialog-classes": "booking-acknowledge",
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _c("VField", {
        staticClass: "booking-acknowledge__field pre-formatted",
        attrs: { label: _vm.translations.bookingAcknowledgedText.text.trim() },
      }),
      _c(
        "div",
        { staticClass: "booking-acknowledge__footer" },
        [
          _c(
            "VButton",
            {
              staticClass: "booking-acknowledge__button",
              attrs: { outline: "" },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.translations.buttonCancel.text))]
          ),
          _c(
            "VButton",
            {
              staticClass: "booking-acknowledge__button",
              attrs: { base: "" },
              on: { click: _vm.confirm },
            },
            [_vm._v(_vm._s(_vm.translations.buttonSave.text))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }