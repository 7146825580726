var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _c(
      "div",
      { staticClass: "help-change-lang", class: { opened: _vm.isOpen } },
      [
        _c(
          "div",
          {
            staticClass: "help-change-lang__select",
            on: {
              click: function ($event) {
                _vm.isOpen = !_vm.isOpen
              },
            },
          },
          [
            _c("img", { attrs: { src: _vm.activeLang.image, alt: "" } }),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.activeLang.abbreviation)),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "help-change-lang__items",
            class: { show: _vm.isOpen },
          },
          _vm._l(_vm.langs, function (lang) {
            return _c(
              "div",
              {
                key: lang.id,
                staticClass: "help-change-lang__item",
                on: {
                  click: function ($event) {
                    return _vm.onChange(lang)
                  },
                },
              },
              [
                _c("img", { attrs: { src: lang.image, alt: "" } }),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(lang.abbreviation)),
                ]),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }