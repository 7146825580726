var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview__section", attrs: { id: "full-description" } },
    [
      _c("div", { staticClass: "preview__title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.translations.descriptionFull.text) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("VExpansionText", {
        staticClass: "about-tour-list__text",
        attrs: { text: _vm.description, translations: _vm.translations },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }