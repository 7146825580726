var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reg-nav" }, [
    _c("div", { staticClass: "reg-nav__complete-percent" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(
          _vm._s(_vm.completedPercent) +
            "% " +
            _vm._s(_vm.translations.regComplete.text)
        ),
      ]),
      _c("div", { staticClass: "line" }, [
        _c("div", {
          staticClass: "line_active",
          style: `width: ${_vm.completedPercent}%;`,
        }),
      ]),
    ]),
    _c("div", { staticClass: "reg-nav__public-info reg-nav__info-type" }, [
      _c(
        "div",
        {
          staticClass: "title",
          on: {
            click: function ($event) {
              _vm.supplierInfo.is_hiden = !_vm.supplierInfo.is_hiden
            },
          },
        },
        [_vm._v(_vm._s(_vm.translations.regSupplierType.text))]
      ),
      !_vm.supplierInfo.is_hiden
        ? _c(
            "div",
            { staticClass: "steps" },
            _vm._l(_vm.$options.commonSteps, function (step) {
              return _c(
                "nuxt-link",
                {
                  key: step.name,
                  staticClass: "steps__item",
                  attrs: { to: _vm.localePath({ name: step.routeName }) },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations[step.nameTranslate].text) +
                      "\n      "
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "reg-nav__public-info reg-nav__info-type" }, [
      _c(
        "div",
        {
          staticClass: "title",
          class: { disabled: !_vm.enabledPublicRoute },
          on: {
            click: function ($event) {
              _vm.publicInfo.is_hiden = !_vm.publicInfo.is_hiden
            },
          },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.translations.regPublicInfo.text) + "\n    "
          ),
        ]
      ),
      !_vm.publicInfo.is_hiden
        ? _c(
            "div",
            { staticClass: "steps" },
            _vm._l(_vm.currentSteps.public, function (step) {
              return _c(
                "nuxt-link",
                {
                  key: step.name,
                  staticClass: "steps__item",
                  class: { disabled: !_vm.currentSteps.enabled[step.name] },
                  attrs: { to: _vm.localePath({ name: step.routeName }) },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations[step.nameTranslate].text) +
                      "\n      "
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "reg-nav__private-info reg-nav__info-type" }, [
      _c(
        "div",
        {
          staticClass: "title",
          class: { disabled: !_vm.enabledPrivateRoute },
          on: {
            click: function ($event) {
              _vm.privateInfo.is_hiden = !_vm.privateInfo.is_hiden
            },
          },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.translations.regPrivateInfo.text) + "\n    "
          ),
        ]
      ),
      !_vm.privateInfo.is_hiden
        ? _c(
            "div",
            { staticClass: "steps" },
            _vm._l(_vm.currentSteps.private, function (step) {
              return _c(
                "nuxt-link",
                {
                  key: step.name,
                  staticClass: "steps__item",
                  class: { disabled: !_vm.currentSteps.enabled[step.name] },
                  attrs: { to: _vm.localePath({ name: step.routeName }) },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations[step.nameTranslate].text) +
                      "\n      "
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }