var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "112",
        height: "20",
        viewBox: "0 0 112 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.0013 1.66602L12.5763 6.88268L18.3346 7.72435L14.168 11.7827L15.1513 17.516L10.0013 14.8077L4.8513 17.516L5.83464 11.7827L1.66797 7.72435L7.4263 6.88268L10.0013 1.66602Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.0013 1.66602L35.5763 6.88268L41.3346 7.72435L37.168 11.7827L38.1513 17.516L33.0013 14.8077L27.8513 17.516L28.8346 11.7827L24.668 7.72435L30.4263 6.88268L33.0013 1.66602Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.0013 1.66602L58.5763 6.88268L64.3346 7.72435L60.168 11.7827L61.1513 17.516L56.0013 14.8077L50.8513 17.516L51.8346 11.7827L47.668 7.72435L53.4263 6.88268L56.0013 1.66602Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M79.0013 1.66602L81.5763 6.88268L87.3346 7.72435L83.168 11.7827L84.1513 17.516L79.0013 14.8077L73.8513 17.516L74.8346 11.7827L70.668 7.72435L76.4263 6.88268L79.0013 1.66602Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M102.001 1.66602L104.576 6.88268L110.335 7.72435L106.168 11.7827L107.151 17.516L102.001 14.8077L96.8513 17.516L97.8346 11.7827L93.668 7.72435L99.4263 6.88268L102.001 1.66602Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }