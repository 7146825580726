var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.excludes.length
    ? _c(
        "div",
        { staticClass: "preview__section" },
        [
          _c("div", { staticClass: "preview__title" }, [
            _vm._v("\n    " + _vm._s(_vm.translations.excludes.text) + "\n  "),
          ]),
          _vm._v(" "),
          _vm.tourExcludes.more
            ? [
                _vm._l(_vm.tourExcludes.start, function (exclude) {
                  return _c(
                    "div",
                    {
                      key: `exclude-${exclude.id}`,
                      staticClass: "preview__include",
                    },
                    [
                      _c("div", {
                        staticClass: "preview__dot preview__dot--orange",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "preview__include-text" }, [
                        _vm._v(_vm._s(exclude.description)),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.showTourExcludes
                  ? _c(
                      "span",
                      {
                        staticClass: "preview__read-more",
                        on: {
                          click: function ($event) {
                            _vm.showTourExcludes = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.textReadMore.text))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTourExcludes
                  ? [
                      _vm._l(_vm.tourExcludes.more, function (exclude) {
                        return _c(
                          "div",
                          {
                            key: `exclude-${exclude.id}`,
                            staticClass: "preview__include",
                          },
                          [
                            _c("div", {
                              staticClass: "preview__dot preview__dot--orange",
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "preview__include-text" },
                              [_vm._v(_vm._s(exclude.description))]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "preview__read-more",
                          on: {
                            click: function ($event) {
                              _vm.showTourExcludes = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations.textHide.text))]
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._l(_vm.tourExcludes, function (exclude) {
                return _c(
                  "div",
                  {
                    key: `exclude-${exclude.id}`,
                    staticClass: "preview__include",
                  },
                  [
                    _c("div", {
                      staticClass: "preview__dot preview__dot--orange",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "preview__include-text" }, [
                      _vm._v(_vm._s(exclude.description)),
                    ]),
                  ]
                )
              }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }