var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c("div", { staticClass: "add-schedule" }, [
        _c(
          "div",
          { staticClass: "add-schedule__content" },
          [
            _c("div", { staticClass: "add-schedule__step" }, [
              _vm._v(
                _vm._s(
                  _vm.parseTranslate(
                    _vm.pricesTranslate.stepOf.text,
                    _vm.step,
                    "3"
                  )
                )
              ),
            ]),
            _vm.step == 1
              ? _c("AddAttributes", {
                  attrs: {
                    attributes: _vm.attributes,
                    "options-product-details": _vm.options,
                    translations: _vm.translations,
                    tour: _vm.tour,
                    step: 3,
                    "text-btn-save": _vm.translations.nextBtn.text,
                  },
                  on: {
                    back: _vm.close,
                    save: _vm.saveAttributes,
                    close: _vm.close,
                  },
                })
              : _vm.step == 2
              ? _c("pricing-schedule", {
                  attrs: {
                    translations: _vm.scheduleTranslate,
                    tour: _vm.tour,
                    "tour-lang": _vm.tourLang,
                    "improve-options-translate": _vm.improveOptionsTranslate,
                    step: 3,
                    "schedule-pricing": _vm.schedule,
                  },
                  on: {
                    back: _vm.backSchedule,
                    save: _vm.saveSchedule,
                    close: _vm.close,
                  },
                })
              : _vm.step == 3
              ? _c("price-option", {
                  attrs: {
                    "price-option": _vm.priceOption,
                    translations: _vm.pricesTranslate,
                    tour: _vm.tour,
                    step: 3,
                  },
                  on: { back: _vm.prevStep, save: _vm.save, close: _vm.close },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("no-members", {
        attrs: {
          translations: _vm.pricesTranslate,
          "no-members": _vm.noMembers,
          slug: _vm.tour.slug,
        },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }