var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        title: _vm.translations.historyTableTitle.text,
        "dialog-classes": "booking-history",
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _vm.loading ? _c("VLoader") : _vm._e(),
      _c("VTable", {
        staticClass: "booking-history__table",
        attrs: { items: _vm.formattedHistory, headers: _vm.headers },
      }),
      _c(
        "div",
        { staticClass: "booking-history__footer" },
        [
          _c("VButton", { attrs: { outline: "" }, on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.translations.buttonCancel.text)),
          ]),
          _vm.showRefundButton
            ? _c(
                "VButton",
                { attrs: { base: "" }, on: { click: _vm.refund } },
                [_vm._v(_vm._s(_vm.translations.buttonRefund.text))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }