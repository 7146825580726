var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pricing-schedule-price-pop-up" },
    [
      _c(
        "div",
        {
          staticClass: "pop-up__close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/img/svg/close.svg"), alt: "close" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pricing-schedule-price-pop-up__title" }, [
        _vm._v("\n    " + _vm._s(_vm.translations.title.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pricing-schedule-price-pop-up__content" },
        [
          _c(
            "div",
            { staticClass: "pricing-schedule-price-pop-up__content--title" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translations.contentStart.text) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("date-picker", {
            class: { "input-error": _vm.dateError },
            attrs: {
              placeholder: _vm.translations.startPlaceholder.text,
              "value-type": "YYYY-MM-DD",
              lang: _vm.calendarExtra.lang,
              format: _vm.calendarExtra.format,
              editable: false,
              "disabled-date": _vm.disabledDates,
            },
            on: {
              change: function ($event) {
                return _vm.checkDateEndError()
              },
            },
            model: {
              value: _vm.schedule.time1,
              callback: function ($$v) {
                _vm.$set(_vm.schedule, "time1", $$v)
              },
              expression: "schedule.time1",
            },
          }),
          _vm._v(" "),
          !_vm.schedule.endDate
            ? _c(
                "div",
                { staticClass: "pricing-schedule-price-pop-up__end-date-hide" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-schedule-price-pop-up__end-date-hide--title",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-schedule-price-pop-up__end-date-hide--text",
                          on: {
                            click: function ($event) {
                              _vm.schedule.endDate = !_vm.schedule.endDate
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.contentEnd.text) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-schedule-price-pop-up__end-date-hide--optional",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.optional.text) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-question-info", {
                        attrs: {
                          description: _vm.translations.endQuestion.text,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                { staticClass: "pricing-schedule-price-pop-up__end-date-show" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-schedule-price-pop-up__end-date-show--title",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translations.contentEnd.text) +
                          "\n        "
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-schedule-price-pop-up__end-date-show--optional",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.optional.text) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-question-info", {
                        attrs: {
                          description: _vm.translations.endQuestion.text,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-schedule-price-pop-up__end-date-show--date",
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          "value-type": "YYYY-MM-DD",
                          lang: _vm.calendarExtra.lang,
                          format: _vm.calendarExtra.format,
                          placeholder: _vm.translations.endPlaceholder.text,
                          "disabled-date": _vm.disabledDates,
                          editable: false,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.checkDateEndError()
                          },
                        },
                        model: {
                          value: _vm.schedule.time2,
                          callback: function ($$v) {
                            _vm.$set(_vm.schedule, "time2", $$v)
                          },
                          expression: "schedule.time2",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-schedule-price-pop-up__end-date-show--clear",
                          on: {
                            click: function ($event) {
                              _vm.schedule.time2 = null
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translations.endClearDate.text) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pricing-schedule-price-pop-up__select-option" },
            [
              _c("VRadioGroup", {
                staticClass:
                  "pricing-schedule-price-pop-up__select-option--item",
                attrs: { name: "scheduleType" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ on, attrs }) {
                      return [
                        _vm._l(_vm.scheduleTypes, function (scheduleType) {
                          return [
                            _c(
                              "VRadioButton",
                              _vm._g(
                                _vm._b(
                                  {
                                    key: scheduleType.id,
                                    attrs: {
                                      label: scheduleType.title,
                                      value: scheduleType.id,
                                    },
                                  },
                                  "VRadioButton",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                            _vm._v(" "),
                            scheduleType.description
                              ? _c(
                                  "div",
                                  {
                                    key: `description-${scheduleType.id}`,
                                    staticClass:
                                      "pricing-schedule-price-pop-up__select-option--description",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scheduleType.description) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.schedule.selectedScheduleTypeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.schedule, "selectedScheduleTypeId", $$v)
                  },
                  expression: "schedule.selectedScheduleTypeId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pricing-schedule-price-pop-up__nav" },
        [
          _c("v-btn", {
            attrs: {
              outline: "",
              name:
                _vm.step == 3
                  ? _vm.translations.backBtn.text
                  : _vm.translations.cancelBtn.text,
            },
            on: { click: _vm.previousPage },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pagination row" }, [
            _vm.step == 3
              ? _c("div", { staticClass: "dot dot_page" })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "dot dot_page dot_page_active" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot dot_page" }),
          ]),
          _vm._v(" "),
          _c("v-btn", {
            attrs: { base: "", name: _vm.translations.nextBtn.text },
            on: { click: _vm.changePage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("improve-options", {
        attrs: {
          "is-active": _vm.improveOptions,
          translations: _vm.improveOptionsTranslate,
        },
        on: {
          close: function ($event) {
            _vm.improveOptions = !_vm.improveOptions
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }