var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.meetingPoint !== null
    ? _c("div", { staticClass: "preview__section" }, [
        _c("div", { staticClass: "preview__title" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.translations.endPointTitle.text) + "\n  "
          ),
        ]),
        !_vm.meetingPoint.is_end_point
          ? _c("p", {
              staticClass: "preview__text",
              domProps: { textContent: _vm._s(_vm.endPoint.address) },
            })
          : _vm._e(),
        _vm.endPoint != null &&
        _vm.endPoint.description &&
        !_vm.meetingPoint.is_end_point
          ? _c("br")
          : _vm._e(),
        _vm.endPoint != null &&
        _vm.endPoint.description &&
        !_vm.meetingPoint.is_end_point
          ? _c("p", {
              staticClass: "preview__text",
              domProps: { textContent: _vm._s(_vm.endPoint.description) },
            })
          : _vm.meetingPoint.is_end_point
          ? _c("p", {
              staticClass: "preview__text",
              domProps: {
                textContent: _vm._s(_vm.translations.textReturns.text),
              },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }