var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-company-information" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "pop-up-company-information__title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.companyTranslate.title.text) + "\n    "
            ),
          ]),
          _c(
            "div",
            { staticClass: "pop-up-company-information__content" },
            [
              _c(
                "div",
                { staticClass: "business-name" },
                [
                  _c("v-input", {
                    attrs: {
                      label: _vm.companyTranslate.contentBusinessName.text,
                      placeholder:
                        _vm.companyTranslate.businessNameInputPlaceholder.text,
                      error: _vm.$v.form.businessName.$error,
                      "error-message":
                        _vm.companyTranslate.businessNameInputError.text,
                      "max-length": 255,
                    },
                    model: {
                      value: _vm.$v.form.businessName.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.businessName, "$model", $$v)
                      },
                      expression: "$v.form.businessName.$model",
                    },
                  }),
                ],
                1
              ),
              _vm.isSellerCompany
                ? _c(
                    "div",
                    { staticClass: "business-trading-name" },
                    [
                      _c(
                        "div",
                        { staticClass: "business-trading-name__title" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.companyTranslate.contentBusinessTradingName
                                  .text
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "business-trading-name__subtitle" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.companyTranslate
                                  .businessTradingNameDescription.text
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c("v-input", {
                        attrs: {
                          placeholder:
                            _vm.companyTranslate
                              .businessTradingNameInputPlaceholder.text,
                          error: _vm.$v.form.businessTradingName.$error,
                          "error-message":
                            _vm.companyTranslate.businessTradingNameInputError
                              .text,
                          "max-length": 24,
                        },
                        model: {
                          value: _vm.$v.form.businessTradingName.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.form.businessTradingName,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.form.businessTradingName.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "company-website" },
                [
                  _c("div", { staticClass: "company-website__title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.companyTranslate.contentWebsite.text) +
                        "\n        "
                    ),
                  ]),
                  _c("input-fields", {
                    class: { "input-error": _vm.$v.form.website.$error },
                    attrs: { placeholder: " http://www.example.com " },
                    model: {
                      value: _vm.$v.form.website.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.website, "$model", $$v)
                      },
                      expression: "$v.form.website.$model",
                    },
                  }),
                  _vm.$v.form.website.$error
                    ? _c(
                        "div",
                        { staticClass: "company-website_error error-text" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.companyTranslate.contentWebsiteInputError
                                  .text
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isSellerCompany
                ? _c(
                    "div",
                    { staticClass: "contact-email" },
                    [
                      _c("div", { staticClass: "contact-email__title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.companyTranslate.contactEmail.text) +
                            "\n        "
                        ),
                      ]),
                      _c("input-fields", {
                        class: {
                          "input-error": _vm.$v.form.contactEmail.$error,
                        },
                        attrs: { placeholder: "yourContactEmail@gmail.com" },
                        model: {
                          value: _vm.$v.form.contactEmail.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.form.contactEmail, "$model", $$v)
                          },
                          expression: "$v.form.contactEmail.$model",
                        },
                      }),
                      _vm.$v.form.contactEmail.$error
                        ? _c(
                            "div",
                            { staticClass: "company-website_error error-text" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.companyTranslate.contactEmailError.text
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "contact-phone" },
                [
                  _c("div", { staticClass: "contact-phone__title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.companyTranslate.contactPhone.text) +
                        "\n        "
                    ),
                  ]),
                  _vm.userInfo.contact_phone_code
                    ? _c("VuePhoneNumberInput", {
                        attrs: {
                          error: !_vm.countryError,
                          "default-country-code": _vm.contactPhoneCode,
                        },
                        on: { update: _vm.countryChanged },
                        model: {
                          value: _vm.contactPhone,
                          callback: function ($$v) {
                            _vm.contactPhone = $$v
                          },
                          expression: "contactPhone",
                        },
                      })
                    : _c("VuePhoneNumberInput", {
                        attrs: {
                          "default-country-code": _vm.contactPhoneCode,
                          error: !_vm.countryError,
                        },
                        on: { update: _vm.countryChanged },
                        model: {
                          value: _vm.contactPhone,
                          callback: function ($$v) {
                            _vm.contactPhone = $$v
                          },
                          expression: "contactPhone",
                        },
                      }),
                ],
                1
              ),
              _c(
                "VField",
                {
                  staticClass: "company-image__field",
                  attrs: {
                    label: _vm.companyTranslate.contentLogo.text,
                    error: _vm.$v.localPhotoFile.$error,
                    "error-message": _vm.photoError,
                  },
                },
                [
                  _c("VFileInput", {
                    staticClass: "company-image__file-input",
                    attrs: {
                      "preview-url": _vm.localPhotoUrl,
                      full: "",
                      error: _vm.$v.localPhotoFile.$error,
                      accept: "image/*",
                    },
                    on: { change: _vm.uploadFile },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "preview-append",
                          fn: function () {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "company-image__delete-icon",
                                  on: { click: _vm.deleteLogoImage },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/svg/add-logo-trash-icon.svg"),
                                      alt: "Delete Logo",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3797571622
                    ),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "company-information" }, [
                _c("div", { staticClass: "company-information__title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.companyTranslate.contentInformation.text) +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "company-information__description" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.companyTranslate.contentInformationDescription.text
                      ) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "company-information__textarea-wrapper" },
                  [
                    _c("VTextarea", {
                      attrs: {
                        error: _vm.$v.form.companyInformation.$error,
                        "error-message":
                          _vm.companyTranslate.errorComDescLength.text,
                        rows: "6",
                        "max-length": _vm.companyInformation.maxLength,
                        "min-length": "100",
                      },
                      model: {
                        value: _vm.$v.form.companyInformation.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.form.companyInformation,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.form.companyInformation.$model",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "pop-up-company-information__nav" }, [
            _c(
              "div",
              { staticClass: "cancel", on: { click: _vm.closeWindow } },
              [
                _c("Btn", {
                  attrs: {
                    outline: "",
                    name: _vm.companyTranslate.cancelBtn.text,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "save", on: { click: _vm.saveCompanySettings } },
              [
                _c("Btn", {
                  attrs: { base: "", name: _vm.companyTranslate.saveBtn.text },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }