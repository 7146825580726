<template>
  <div class="question-mark">
    <img src="@/assets/img/svg/question-info.svg" alt="question-info" @mouseover="isShow = true"
      @mouseleave="isShow = false" />
    <div v-if="isShow" class="question-mark__description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: { type: String, default: '' }
  },
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style lang="scss">
.question-mark {
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &__description {
    position: absolute;
    top: 10px;
    left: -8px;
    width: 250px;
    padding: 10px 10px;
    position: relative;
    background: #eee;
    text-align: start;
    border-radius: 3px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    z-index: 2500;

    &::before {
      content: '';
      border: solid transparent;
      position: absolute;
      left: 8px;
      bottom: 100%;
      border-bottom-color: #eee;
      border-width: 9px;
      margin-left: 0;
    }

    &--right {
      left: -100px;
      width: 200px;
    }
  }

  &.text-black {
    .question-mark__description {
      color: #1e2843;
    }
  }
}
</style>
