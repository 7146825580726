var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        title: _vm.translations.connectReservationSystem.text,
        "dialog-classes": "connect-product-to-reservation-system",
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _c(
        "div",
        { staticClass: "connect-product-to-reservation-system__name" },
        [_vm._v(_vm._s(_vm.tour.name))]
      ),
      _c("v-input", {
        attrs: {
          label: _vm.translations.supplierCode.text,
          error: _vm.$v.supplierProductCode.$error,
          "error-message": "This field can not be empty",
        },
        model: {
          value: _vm.$v.supplierProductCode.$model,
          callback: function ($$v) {
            _vm.$set(
              _vm.$v.supplierProductCode,
              "$model",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "$v.supplierProductCode.$model",
        },
      }),
      _c(
        "div",
        { staticClass: "connect-product-to-reservation-system__footer" },
        [
          _c("v-btn", { attrs: { outline: "" }, on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.translations.cancelButton.text)),
          ]),
          _c(
            "v-btn",
            {
              attrs: {
                base: "",
                loading: _vm.loading,
                "loading-name": "Connects...",
              },
              on: { click: _vm.connect },
            },
            [_vm._v(_vm._s(_vm.translations.connect.text))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }