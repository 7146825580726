var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-input v-input2",
      class: { "v-input2__active": _vm.isFocus },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "v-input__label" }, [
            _vm._v(_vm._s(_vm.label) + " "),
            _vm.optional
              ? _c("span", { staticClass: "v-input__label-optional" }, [
                  _vm._v("(" + _vm._s(_vm.optional) + ")"),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.subLabel
        ? _c("div", { staticClass: "v-input__sub-label" }, [
            _vm._v("\n    " + _vm._s(_vm.subLabel) + "\n  "),
          ])
        : _vm._e(),
      _c("div", { staticClass: "v-input__row" }, [
        _vm.prependText.length > 0
          ? _c("div", { staticClass: "v-input__prepend-text" }, [
              _vm._v(_vm._s(_vm.prependText)),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            class: [
              _vm.prependText.length > 0 ? "v-input__prepend-text-input" : "",
              "v-input__field-wrapper",
            ],
          },
          [
            _vm.type === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.localModel,
                      expression: "localModel",
                    },
                  ],
                  class: { "v-input2__input": true, "is-invalid": _vm.error },
                  attrs: {
                    id: _vm.id,
                    placeholder: _vm.placeholder,
                    maxLength: _vm.maxLength,
                    disabled: _vm.isDisabled,
                    onkeypress: _vm.onkeypress,
                    onpaste: _vm.onpaste,
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.localModel)
                      ? _vm._i(_vm.localModel, null) > -1
                      : _vm.localModel,
                  },
                  on: {
                    focus: _vm.gotFocus,
                    blur: _vm.lostFocus,
                    change: function ($event) {
                      var $$a = _vm.localModel,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.localModel = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.localModel = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.localModel = $$c
                      }
                    },
                  },
                })
              : _vm.type === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.localModel,
                      expression: "localModel",
                    },
                  ],
                  class: { "v-input2__input": true, "is-invalid": _vm.error },
                  attrs: {
                    id: _vm.id,
                    placeholder: _vm.placeholder,
                    maxLength: _vm.maxLength,
                    disabled: _vm.isDisabled,
                    onkeypress: _vm.onkeypress,
                    onpaste: _vm.onpaste,
                    type: "radio",
                  },
                  domProps: { checked: _vm._q(_vm.localModel, null) },
                  on: {
                    focus: _vm.gotFocus,
                    blur: _vm.lostFocus,
                    change: function ($event) {
                      _vm.localModel = null
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.localModel,
                      expression: "localModel",
                    },
                  ],
                  class: { "v-input2__input": true, "is-invalid": _vm.error },
                  attrs: {
                    id: _vm.id,
                    placeholder: _vm.placeholder,
                    maxLength: _vm.maxLength,
                    disabled: _vm.isDisabled,
                    onkeypress: _vm.onkeypress,
                    onpaste: _vm.onpaste,
                    type: _vm.type,
                  },
                  domProps: { value: _vm.localModel },
                  on: {
                    focus: _vm.gotFocus,
                    blur: _vm.lostFocus,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.localModel = $event.target.value
                    },
                  },
                }),
            true
              ? _c("div", { staticClass: "v-input2__passive" }, [
                  _vm._v(_vm._s(_vm.virtualValue)),
                ])
              : _vm._e(),
            _vm.maxLength > 0 && !_vm.hideCounter
              ? _c("div", { staticClass: "v-input__counter" }, [
                  _vm._v(
                    _vm._s(_vm.value.length) + "/" + _vm._s(_vm.maxLength)
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.error && _vm.errorMessage
        ? _c("div", { staticClass: "validation-error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }