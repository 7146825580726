var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-nav" }, [
    _c("div", { staticClass: "product-nav__complete-percent" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(
          _vm._s(_vm.completePercent) +
            "% " +
            _vm._s(_vm.commonTranslate.percentageComplete.text)
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("div", {
          staticClass: "line_active",
          style: `width: ${_vm.completePercent}%;`,
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "product-nav__basic product-nav__info-type" }, [
      _c(
        "div",
        {
          staticClass: "title",
          class: {
            title_active: _vm.basicInfo.is_open,
            disabled: !_vm.basicInfo.is_open,
          },
          on: {
            click: function ($event) {
              _vm.basicInfo.is_hiden = !_vm.basicInfo.is_hiden
            },
          },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.translations.productStepBasic.text) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.basicInfo.is_open && !_vm.basicInfo.is_hiden
        ? _c(
            "div",
            { staticClass: "steps" },
            [
              !_vm.slug
                ? _c(
                    "nuxt-link",
                    {
                      staticClass: "steps__item",
                      class: { disabled: !_vm.basicInfo.product_setup_open },
                      attrs: {
                        to: _vm.localePath(
                          `/product/build/basic/product-setup`
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.translations.productStepProductSetup.text
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _c(
                    "nuxt-link",
                    {
                      staticClass: "steps__item",
                      class: { disabled: !_vm.basicInfo.product_setup_open },
                      attrs: {
                        to: _vm.localePath(
                          `/product/${_vm.slug}/build/basic/product-setup`
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.translations.productStepProductSetup.text
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass: "steps__item",
                  class: { disabled: !_vm.basicInfo.categories_themes_open },
                  attrs: {
                    to: _vm.localePath(
                      `/product/${_vm.slug}/build/basic/categories-and-themes`
                    ),
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.translations.productStepCategoriesThemes.text
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass: "steps__item",
                  class: { disabled: !_vm.basicInfo.photos_open },
                  attrs: {
                    to: _vm.localePath(
                      `/product/${_vm.slug}/build/basic/photos`
                    ),
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations.productStepPhotos.text) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-nav__product-content product-nav__info-type" },
      [
        _c(
          "div",
          {
            staticClass: "title",
            class: {
              title_active: _vm.productContentInfo.is_open,
              disabled: !_vm.productContentInfo.is_open,
            },
            on: {
              click: function ($event) {
                _vm.productContentInfo.is_hiden =
                  !_vm.productContentInfo.is_hiden
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.productStepProductContent.text) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.productContentInfo.is_open && !_vm.productContentInfo.is_hiden
          ? _c(
              "div",
              { staticClass: "steps" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.productContentInfo.meeting_pickup_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/meeting-and-pickup`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translations.productStepMeetingPickup.text) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.isTravelServices
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "steps__item",
                        class: {
                          disabled:
                            !_vm.productContentInfo
                              .information_about_ticket_open,
                        },
                        attrs: {
                          to: _vm.localePath(
                            `/product/${_vm.slug}/build/product-content/information-about-ticket`
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.translations.productStepInformationAboutTicket
                                .text
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.productContentInfo.activity_unique_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/makes-unique`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isTravelServices
                            ? "About your service"
                            : _vm.translations.productStepFirstImpression.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.productContentInfo.activities_details_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/activities-details`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isTravelServices
                            ? "Transportation details"
                            : _vm.translations.productStepActivitiesDetails.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled:
                        !_vm.productContentInfo.inclusions_exclusions_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/inclusions-and-exclusions`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepInclusionsExclusions.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.productContentInfo.guides_languages_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/guides-and-languages`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepGuidesLanguages.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled:
                        !_vm.productContentInfo.information_travellers_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/information-edit`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepInformationTravellersNeed
                            .text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: { disabled: !_vm.productContentInfo.covid_open },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/product-content/covid`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translations.productStepCovid.text) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-nav__schedules-pricing product-nav__info-type" },
      [
        _c(
          "div",
          {
            staticClass: "title",
            class: {
              title_active: _vm.schedulesContentInfo.is_open,
              disabled: !_vm.schedulesContentInfo.is_open,
            },
            on: {
              click: function ($event) {
                _vm.schedulesContentInfo.is_hiden =
                  !_vm.schedulesContentInfo.is_hiden
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.productStepSchedulesPricing.text) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.schedulesContentInfo.is_open && !_vm.schedulesContentInfo.is_hiden
          ? _c(
              "div",
              { staticClass: "steps" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.schedulesContentInfo.product_type_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/schedules-and-pricing/pricing-type`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translations.productStepPricingType.text) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.schedulesContentInfo.schedules_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/schedules-and-pricing/schedules`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translations.productStepSchedules.text) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-nav__booking-tickets product-nav__info-type" },
      [
        _c(
          "div",
          {
            staticClass: "title",
            class: {
              title_active: _vm.bookingTicketInfo.is_open,
              disabled: !_vm.bookingTicketInfo.is_open,
            },
            on: {
              click: function ($event) {
                _vm.bookingTicketInfo.is_hiden = !_vm.bookingTicketInfo.is_hiden
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.translations.bookingAndTickets.text) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.bookingTicketInfo.is_open && !_vm.bookingTicketInfo.is_hiden
          ? _c(
              "div",
              { staticClass: "steps" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.booking_process_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/booking-process`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepBookingProcess.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.information_voucher,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/information-on-voucher`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translations.productStepVoucher.text) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.cancellation_policy_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/cancellation-policy`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepCancellationPolicy.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.traveler_required_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/information-edit`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepTravelerRequired.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.tickets_details_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/ticket-details`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepTicketsDetails.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.tickets_redemption_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/ticket-redemption`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepTicketsRedemption.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "steps__item",
                    class: {
                      disabled: !_vm.bookingTicketInfo.tickets_preview_open,
                    },
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/build/booking-and-tickets/ticket-preview`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.translations.productStepTicketsPreview.text
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "product-nav__finish product-nav__info-type" }, [
      _c(
        "div",
        {
          staticClass: "title",
          class: {
            title_active: _vm.finishInfo.is_open,
            disabled: !_vm.finishInfo.is_open,
          },
          on: {
            click: function ($event) {
              _vm.finishInfo.is_hiden = !_vm.finishInfo.is_hiden
            },
          },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.translations.productStepFinish.text) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.finishInfo.is_open && !_vm.finishInfo.is_hiden
        ? _c(
            "div",
            { staticClass: "steps" },
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "steps__item",
                  class: { disabled: !_vm.finishInfo.special_offers_open },
                  attrs: {
                    to: _vm.localePath(
                      `/product/${_vm.slug}/build/finish/special-offers`
                    ),
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations.productSpecialOffers.text) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass: "steps__item",
                  class: { disabled: !_vm.finishInfo.submit_review_open },
                  attrs: {
                    to: _vm.localePath(
                      `/product/${_vm.slug}/build/finish/submit-for-review`
                    ),
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations.productSubmitReview.text) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }