<template>
  <v-dialog
    v-model="modelActive"
    :title="translations.connectReservationSystem.text"
    dialog-classes="connect-product-to-reservation-system"
  >
    <div class="connect-product-to-reservation-system__name">{{ tour.name }}</div>

    <v-input
      v-model.trim="$v.supplierProductCode.$model"
      :label="translations.supplierCode.text"
      :error="$v.supplierProductCode.$error"
      error-message="This field can not be empty"
    />

    <div class="connect-product-to-reservation-system__footer">
      <v-btn outline @click="cancel">{{ translations.cancelButton.text }}</v-btn>
      <v-btn base :loading="loading" loading-name="Connects..." @click="connect">{{ translations.connect.text }}</v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import notificationService from '@/services/notification';
import VDialog from '@/components/common/VDialog.vue';
import VInput from '@/components/controls/VInput.vue';
import VBtn from '@/components/controls/VBtn.vue';

export default {
  name: 'ConnectProductToReservationSystem',
  components: { VDialog, VInput, VBtn },

  props: {
    isVisible: { type: Boolean, default: false },
    tour: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      loading: false,
      supplierProductCode: '',
    };
  },

  computed: {
    modelActive: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('update:isVisible', value);
      },
    },
  },

  validations() {
    return {
      supplierProductCode: { required },
    };
  },

  watch: {
    modelActive() {
      if (this.modelActive) {
        this.supplierProductCode = '';
        this.$v.$reset();
      }
    },
  },

  methods: {
    connect() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        this.$axios.$put(`/api/v1/edit-tour/?tour_id=${this.tour.id}&bokun_key=${this.supplierProductCode}`)
          .then((res) => {
            this.$axios.$post(`/api/v1/check-tour/?tour_id=${this.tour.id}`);
          })
          .catch(() => {
            notificationService.error({
              text: 'There was an error connecting to the reservation system',
            });
          })
          .finally(() => {
            this.loading = false;
            this.modelActive = false;
            location.reload();
          });
      }
    },

    cancel() {
      this.modelActive = false;
    },
  },
};
</script>

<style lang="scss">
.connect-product-to-reservation-system {
  width: 800px;

  .v-dialog {
    &__title {
      padding-top: 63px;
      padding-bottom: 22px;
      width: 100%;
    }

    &__header {
      padding: 0px;
      margin: 0 35px;
    }

    &__close {
      height: 24px;
    }

    &__content {
      padding-top: 22px;
    }
  }

  &__name {
    border-bottom: 1px solid $border;
    padding-bottom: 16px;
    margin-bottom: 31px;
    font-size: 18px;
    line-height: 24px;
  }

  &__footer {
    display: flex;
    width: 100%;
    padding-top: 38px;
    border-top: 1px solid $border;
    margin-top: 110px;

    .v-btn_outline {
      margin-right: 25px;
    }

    .v-btn_base {
      min-width: 70px;
    }
  }
}
</style>
