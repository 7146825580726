var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "refud-already-pop-up" }, [
          _c("div", { staticClass: "close", on: { click: _vm.closeWindow } }, [
            _c("img", {
              attrs: {
                id: `DelClosePopup`,
                width: "20",
                height: "20",
                src: require("@/assets/img/svg/close.svg"),
                alt: "close",
              },
            }),
          ]),
          _c("div", { staticClass: "refud-already-pop-up__title" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          ]),
          _c("div", { staticClass: "refud-already-pop-up__buttons" }, [
            _c(
              "div",
              { staticClass: "button", on: { click: _vm.closeWindow } },
              [_c("btn", { attrs: { base: "", name: _vm.buttonText } })],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }