var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        title: _vm.getTitle,
        "dialog-classes": "connect-reservation-system",
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _vm.step == 1
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "connect-reservation-system__description" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "connect-reservation-system__description--item",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translations.selectDescription1.text) +
                          "\n        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translations.selectDescription2.text) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.translations.selectDescription3.text)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "connect-reservation-system__label" }, [
                _vm._v(_vm._s(_vm.translations.yourReservationSystem.text)),
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  error: _vm.$v.reservationSystem.$error,
                  "error-message": "This field can not be empty",
                  items: _vm.normalizedReservationSystemTypes,
                  translates: _vm.reservationSystemTypes,
                  placeholder: "Select one",
                },
                model: {
                  value: _vm.$v.reservationSystem.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.reservationSystem, "$model", $$v)
                  },
                  expression: "$v.reservationSystem.$model",
                },
              }),
              _vm._v(" "),
              _vm.isBokun || _vm.isOther
                ? _c("v-input", {
                    attrs: {
                      label: _vm.isBokun ? "Account ID" : "Other",
                      error: _vm.$v.info.$error,
                      "error-message": "This field can not be empty",
                      placeholder: _vm.isBokun
                        ? "Introduce your Bokun Account ID"
                        : "Introduce the system that you work with",
                    },
                    model: {
                      value: _vm.$v.info.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.info,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.info.$model",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("div", [
            _vm.isBokun
              ? _c("div", { staticClass: "bokun_root" }, [
                  _c(
                    "div",
                    { staticClass: "connect-reservation-system__bokun--text" },
                    [
                      _vm._v(
                        "\n        As a supplier, you provide us with your contract terms when inviting us to become your reseller. Before you\n        can send a Marketplace contract, you need to make sure you have your Contract terms in place.\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "connect-reservation-system__bokun--title" },
                    [
                      _vm._v(
                        "\n        Follow the steps below to send a contract proposal to Vidodo Guide:\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "In your Bokun Dashboard, click on Sales tools → Marketplace"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Go to the Discover Partners page")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Click on the business card")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Click Offer my products")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Complete the marketplace contract sections"),
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Select Send proposal")]),
                  ]),
                ])
              : _c("div", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.translations.descLarge.text) +
                      "\n    "
                  ),
                ]),
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "connect-reservation-system__footer" },
        [
          _c("v-btn", { attrs: { outline: "" }, on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.translations.cancelButton.text)),
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                base: "",
                loading: _vm.loading,
                "loading-name": "Connects...",
              },
              on: { click: _vm.next },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.step === 1
                      ? _vm.translations.nextButton?.text
                      : _vm.translations.saveButton.text
                  ) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }