var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: { title: _vm.editTranslate.title.text },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "edit-schedule-pop-up" },
        [
          _c("div", [_vm._v(_vm._s(_vm.editTranslate.contentStart.text))]),
          _c("date-picker", {
            ref: "start",
            class: { "input-error": _vm.dateError },
            attrs: {
              placeholder: _vm.editTranslate.dateStartPlaceholder.text,
              "value-type": "YYYY-MM-DD",
              format: _vm.calendarExtra.format,
              lang: _vm.calendarExtra.lang,
              "disabled-date": _vm.disabledDates,
              editable: false,
            },
            on: {
              change: function ($event) {
                return _vm.checkDateEndError()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "mx-datepicker-popup",
                fn: function () {
                  return [
                    _c("button", { staticClass: "mx-btn mx-btn-text" }, [
                      _vm._v("sss"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.time1,
              callback: function ($$v) {
                _vm.time1 = $$v
              },
              expression: "time1",
            },
          }),
          !_vm.endDate
            ? _c(
                "div",
                { staticClass: "edit-schedule-pop-up__end-date-hide" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "edit-schedule-pop-up__end-date-hide--text",
                      on: {
                        click: function ($event) {
                          _vm.endDate = !_vm.endDate
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.editTranslate.contentEnd.text) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "edit-schedule-pop-up__end-date-hide--optional",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.editTranslate.optional.text) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c("v-question-info", {
                    attrs: { description: _vm.editTranslate.endQuestion.text },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "edit-schedule-pop-up__end-date-show" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "edit-schedule-pop-up__end-date-show--title",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.editTranslate.contentEnd.text) +
                          "\n        "
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-schedule-pop-up__end-date-show--title--optional",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.editTranslate.optional.text) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c("v-question-info", {
                        attrs: {
                          description: _vm.editTranslate.endQuestion.text,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "edit-schedule-pop-up__end-date-show--date",
                    },
                    [
                      _c("date-picker", {
                        ref: "dateEnd",
                        attrs: {
                          placeholder:
                            _vm.editTranslate.dateEndPlaceholder.text,
                          "value-type": "YYYY-MM-DD",
                          format: _vm.calendarExtra.format,
                          lang: _vm.calendarExtra.lang,
                          "disabled-date": _vm.disabledDates,
                          editable: false,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.checkDateEndError()
                          },
                        },
                        model: {
                          value: _vm.time2,
                          callback: function ($$v) {
                            _vm.time2 = $$v
                          },
                          expression: "time2",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "edit-schedule-pop-up__end-date-show--date--clear",
                          on: {
                            click: function ($event) {
                              _vm.time2 = ""
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.editTranslate.clearDate.text) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-schedule-pop-up__nav" },
        [
          _c("v-btn", {
            attrs: { outline: "", name: _vm.editTranslate.cancelBtn.text },
            on: {
              click: function ($event) {
                _vm.modelVisible = false
              },
            },
          }),
          _c("v-btn", {
            attrs: { base: "", name: _vm.editTranslate.saveBtn.text },
            on: { click: _vm.editSchedule },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }