import { SCHEDULE_TIME_TYPES } from '@/constants/scheduleTypes'
import { locales, twelveHourLangs, formats, format12Hour } from '@/constants/locales'

export const getLocaleByLanguage = (lang) => {
  if (!lang) throw new Error('lang is missing!')
  const locale = locales[lang]?.code
  if (locale) return locale
  console.warn('Locale not found for lang', lang)
  return locales.en
}

export const rawDateLocales = locales
export const defaultLocale = 'en-GB'

export function formatTime({ time, lang = 'en' }) {
  lang = lang.toLowerCase()

  const locale = locales[lang]?.code || 'en-GB'

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: twelveHourLangs.includes(lang),
  }

  // Проверяем, передано ли время и корректно ли оно
  if (time !== undefined) {
    const date = new Date(`1970-01-01T${time}`)
    return new Intl.DateTimeFormat(locale, options).format(date)
  } else {
    return formats[lang] || format12Hour
  }
}

export function formatTimeRange(times, lang = 'en', scheduleType = 'default') {
  lang = lang.toLowerCase()

  const locale = locales[lang]?.code || 'en-GB'

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: twelveHourLangs.includes(lang),
  }

  if (
    Array.isArray(times) &&
    (scheduleType === SCHEDULE_TIME_TYPES.coordinatedStartTimes || scheduleType === SCHEDULE_TIME_TYPES.openingHours)
  ) {
    if (times.length % 2 === 0) {
      const formattedRanges = []
      for (let i = 0; i < times.length; i += 2) {
        const formattedTime1 = new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${times[i]}`))
        const formattedTime2 = new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${times[i + 1]}`))
        formattedRanges.push(`${formattedTime1} - ${formattedTime2}`)
      }
      return formattedRanges.join(', ')
    }
    console.error('Invalid time range format')
    return ''
  }

  return times.map((t) => new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${t}`))).join(', ')
}

export function getTime(time, language) {
  if (time === undefined) return time

  const lang = language || this.$store.state.activeLang?.lang || 'en'

  // Проверяем, содержит ли строка времени диапазон
  if (time.includes(' - ')) {
    const [timeStart, timeEnd] = time.split(' - ')
    return [formatTime({ time: timeStart, lang }), formatTime({ time: timeEnd, lang })].join(' - ')
  } else if (typeof time === 'object') {
    time = time[0]
  }

  return formatTime({ time, lang })
}

export function getEarliestTime(times) {
  const timeArray = times.slice().sort((time1, time2) => {
    const [startHours1, startMinutes1] = time1.split(':')
    const [startHours2, startMinutes2] = time2.split(':')
    const time1Date = new Date()
    const time2Date = new Date()
    time1Date.setHours(startHours1, startMinutes1, 0)
    time2Date.setHours(startHours2, startMinutes2, 0)

    return time1Date.valueOf() - time2Date.valueOf()
  })

  return timeArray[0]
}

// '12:00 - 13:00,15:00 - 16:00,09:00 - 11:30'
// '12:00'
export function getStartTime(times) {
  // Проверка, является ли times массивом и имеет ли он элементы
  if (Array.isArray(times) && times.length > 0) {
    return getEarliestTime(times)
  }

  // Если times не массив, оборачиваем его в массив и ищем самое раннее время
  if (times) {
    return getEarliestTime([times])
  }

  // Если times не задан, возвращаем null или undefined
  return null
}
