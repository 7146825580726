var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shortform
    ? _c(
        "VDialog",
        {
          staticClass: "popup-cancel-booking",
          attrs: {
            title: _vm.translations.bookingConfirmOrCancelConfirmedTitle.text,
            size: "large",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "popup-cancel-booking__footer" },
                      [
                        _c(
                          "VButton",
                          {
                            attrs: { "orange-outline": "" },
                            on: { click: _vm.emptySave },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.translations.bookingConfirmationCancel.text
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1255641136
          ),
          model: {
            value: _vm.modelVisible,
            callback: function ($$v) {
              _vm.modelVisible = $$v
            },
            expression: "modelVisible",
          },
        },
        [
          _c("div", { staticClass: "popup-cancel-booking__content" }, [
            _c("div", { staticClass: "popup-cancel-booking__field" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.translations.bookingConfirmOrCancelConfirmedText.text
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ]
      )
    : _c(
        "VDialog",
        {
          staticClass: "popup-cancel-booking",
          attrs: {
            title: _vm.translations.titleCancelBooking.text,
            size: "extra-large",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "popup-cancel-booking__footer" },
                    [
                      _c(
                        "VButton",
                        { attrs: { outline: "" }, on: { click: _vm.cancel } },
                        [_vm._v(_vm._s(_vm.translations.buttonCancel.text))]
                      ),
                      _c(
                        "VButton",
                        { attrs: { base: "" }, on: { click: _vm.save } },
                        [
                          _vm._v(
                            _vm._s(_vm.translations.buttonCancelBooking.text)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.modelVisible,
            callback: function ($$v) {
              _vm.modelVisible = $$v
            },
            expression: "modelVisible",
          },
        },
        [
          _c("div", { staticClass: "popup-cancel-booking__content" }, [
            _c(
              "div",
              { staticClass: "popup-cancel-booking__field" },
              [
                _c("label", { staticClass: "popup-cancel-booking__label" }, [
                  _vm._v(
                    _vm._s(_vm.translations.labelReasonForCancelling.text)
                  ),
                ]),
                _c("VSelect", {
                  staticClass: "popup-cancel-booking__select",
                  attrs: {
                    error: _vm.$v.selectedReason.$error,
                    items: _vm.reasons,
                    translates: _vm.reasonsTranslates,
                    placeholder: _vm.translations.placeholderReason.text,
                  },
                  model: {
                    value: _vm.$v.selectedReason.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.selectedReason, "$model", $$v)
                    },
                    expression: "$v.selectedReason.$model",
                  },
                }),
                _vm.$v.selectedReason.$error && !_vm.$v.selectedReason.required
                  ? _c("span", { staticClass: "error-text" }, [
                      _vm._v(_vm._s(_vm.translations.errorRequired.text)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popup-cancel-booking__field" },
              [
                _c("label", { staticClass: "popup-cancel-booking__label" }, [
                  _vm._v(
                    _vm._s(_vm.translations.labelCancellationRequested.text)
                  ),
                ]),
                _c("VRadioGroup", {
                  attrs: { name: "refundRequested" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "VRadioButton",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label:
                                      _vm.translations.refoundPopUpYes.text,
                                    value: true,
                                  },
                                },
                                "VRadioButton",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                          _c(
                            "VRadioButton",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label: _vm.translations.refoundPopUpNo.text,
                                    value: false,
                                  },
                                },
                                "VRadioButton",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.isRequested,
                    callback: function ($$v) {
                      _vm.isRequested = $$v
                    },
                    expression: "isRequested",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popup-cancel-booking__field" },
              [
                _c("label", { staticClass: "popup-cancel-booking__label" }, [
                  _vm._v(_vm._s(_vm.translations.labelWhoCancelled.text)),
                ]),
                _c("VInput", {
                  staticClass: "popup-cancel-booking__input",
                  attrs: {
                    error: _vm.$v.name.$error,
                    placeholder: _vm.translations.placeholderName.text,
                  },
                  model: {
                    value: _vm.$v.name.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.name, "$model", $$v)
                    },
                    expression: "$v.name.$model",
                  },
                }),
                _vm.$v.name.$error && !_vm.$v.name.required
                  ? _c("span", { staticClass: "error-text" }, [
                      _vm._v(_vm._s(_vm.translations.errorRequired.text)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("p", { staticClass: "popup-cancel-booking__note" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translations.noteCancellation.text) +
                  "\n    "
              ),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }