var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-notification-item",
      class: { "v-notification-item--error": _vm.isError },
    },
    [
      _c("div", { staticClass: "v-notification-item__picture" }, [
        _vm.isError
          ? _c("img", {
              attrs: {
                width: "40",
                height: "40",
                src: require("@/assets/img/svg/cancel.svg"),
                alt: "alert error",
              },
            })
          : _c("img", {
              attrs: {
                width: "40",
                height: "40",
                src: require("@/assets/img/svg/sent_by.svg"),
                alt: "alert success",
              },
            }),
      ]),
      _c("div", { staticClass: "v-notification-item__description" }, [
        _c("div", { staticClass: "v-notification-item__title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
        _c("div", { staticClass: "v-notification-item__text" }, [
          _vm._v("\n      " + _vm._s(_vm.text) + "\n    "),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "v-notification-item__button-close",
          on: { click: _vm.close },
        },
        [_c("BaseIcon", { attrs: { "icon-name": "Close" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }