import { es, enGB } from 'date-fns/locale'

// Эти константы используются для определения языков и форматов времени.
export const locales = {
  en: { code: 'en-GB', localeModule: enGB },
  hi: { code: 'hi-IN', localeModule: null },
  ur: { code: 'ur-PK', localeModule: null },
  bn: { code: 'bn-BD', localeModule: null },
  es: { code: 'es-ES', localeModule: es },
  'es-MX': { code: 'es-MX', localeModule: es },
  fil: { code: 'fil-PH', localeModule: null },
  de: { code: 'de-DE', localeModule: null },
}
/* Above you can add your country as a language tag IETF BCP 47.
For example, the expressions de and de-DE are similar expressions and can be used.
So, the codes can be found here: https://en.wikipedia.org/wiki/IETF_language_tag. */

// Список стран с 12-часовым форматом времени
export const twelveHourCountries = [
  { country: 'India', code: 'hi' },
  { country: 'United States', code: 'en-US' },
  { country: 'Pakistan', code: 'ur' },
  { country: 'Bangladesh', code: 'bn' },
  { country: 'Mexico', code: 'es-MX' },
  { country: 'Philippines', code: 'fil' },
  { country: 'United Kingdom', code: 'en-GB' },
  { country: 'South Africa', code: 'en' },
  { country: 'Canada', code: 'en' },
  { country: 'Australia', code: 'en' },
  { country: 'New Zealand', code: 'en' },
  { country: 'Ireland', code: 'en' },
]

export const twelveHourLangs = twelveHourCountries.map((country) => country.code)

export const format12Hour = 'hh:mm A'
export const format24Hour = 'HH:mm'

// Строковые форматы для различных языков
export const formats = {
  en: format12Hour,
  'en-GB': format12Hour,
  'es-MX': format12Hour,
  hi: format12Hour,
  ur: format12Hour,
  bn: format12Hour,
  fil: format12Hour,
  es: format24Hour,
  de: format24Hour,
}
