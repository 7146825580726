<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-add-exclude">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div v-if="have_error && errorText" class="pop-up-add-exclude__errors">
          <div class="icon"><img src="@/assets/img/svg/error.svg" alt="info"></div>
          <div class="title"> {{ excludeTranslate.textError.text }} </div>
          <div class="error-type"><div class="description"> {{ errorText }} </div></div>
      </div>
      <div class="pop-up-add-exclude__title">
        {{ excludeTranslate.title.text }}
      </div>
      <div class="pop-up-add-exclude__subtitle">
        {{ excludeTranslate.subtitle.text }}
      </div>
      <div class="pop-up-add-exclude__content">
        <div class="category">
          <div class="category__title">
            {{ excludeTranslate.contentCategory.text }}
          </div>
          <div class="category__selection">
            <selection
              default
              :default-name="categoryName"
              :items="selectionCategory"
              @name="changeCategory"
            />
          </div>
        </div>
        <div
          v-if="
            categoryName == excludeTranslate.ownCategory.text ||
              categoryName == excludeTranslate.feesCategory.text
          "
          class="info-type"
        >
          <div
            v-if="categoryName == excludeTranslate.ownCategory.text"
            class="info-type__other"
          >
            <div class="icon">
              <img src="@/assets/img/svg/info.svg" alt="info">
            </div>
            <div class="description">
              {{ excludeTranslate.infotypeOwn.text }}
            </div>
          </div>
          <div
            v-if="categoryName == excludeTranslate.feesCategory.text"
            class="info-type__fees"
          >
            <div class="icon">
              <img src="@/assets/img/svg/info.svg" alt="info">
            </div>
            <div class="description">
              {{ excludeTranslate.infotypeFees.text }}
            </div>
          </div>
        </div>
        <div v-if="categoryName == excludeTranslate.ownCategory.text" class="own-exclusion">
          <div class="category__selection">
            <v-input
              v-model="ownExclusion"
              :placeholder="excludeTranslate.ownPlaceholder.text"
              :label="excludeTranslate.contentTitle.text"
              :max-length="100"
            />
          </div>
        </div>
        <div
          v-if="
            categoryName != excludeTranslate.categoryDefault.text &&
              categoryName != excludeTranslate.ownCategory.text
          "
          class="exclude"
        >
          <div class="category__title">
            {{ excludeTranslate.contentTitle.text }}
          </div>
          <div class="category__selection">
            <selection
              default
              :default-name="excludeName"
              :items="selectionTitles"
              @name="changeExcludes"
            />
          </div>
        </div>
      </div>
      <div class="pop-up-add-exclude__nav">
        <div class="cancel" @click="closeWindow">
          <Btn
            outline
            :name="excludeTranslate.cancelBtn.text"
            :disabled="BtnDesabled"
          />
        </div>
        <div class="Add" @click="saveNewExclude">
          <Btn base :name="excludeTranslate.addBtn.text" :disabled="BtnDesabled" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'
import Selection from '@/components/controls/Selection.vue'
import VInput from '@/components/controls/VInput.vue'

import { parseTranslate } from '@/utils/translations'

export default {
  components: {
    Btn,
    Selection,
    VInput
  },
  props: [
    'isActive',
    'tour_id',
    'lang',
    'arr',
    'includeArray',
    'editable',
    'id',
    'is_category',
    'editName',
    'index',
    'excludeTranslate'
  ],
  data: () => ({
    category: null,
    selectionCategory: [],
    selectionTitles: [],
    categoryName: '',
    excludeName: '',
    ownExclusion: '',
    changed: false,
    BtnDesabled: false,
    have_error: false,
    error_types: {
      additing_error: false,
      empty_category_error: false,
      empty_title_error: false,
      duplicateIncludes: false
    }
  }),
  async fetch () {
    this.category = await this.$axios.$get(`/api/v1/get-excludes?language=${this.lang}`)
    let haveCategoryEditable = false
    let categoryEditableId = null
    this.categoryName = this.excludeTranslate.categoryDefault.text
    this.excludeName = this.excludeTranslate.titleDefault.text
    this.selectionTitles = []
    this.selectionCategory = []
    for (const i in this.category) {
      if (this.editable && !haveCategoryEditable) {
        for (const j in this.category[i].excludes) {
          if (this.category[i].excludes[j].description == this.editName) {
            haveCategoryEditable = true
            categoryEditableId = i
            this.excludeName = this.category[i].excludes[j].description
            this.selectionTitles.push({
              name: this.category[i].excludes[j].description,
              is_active: true,
              titleId: this.category[i].excludes[j].id
            })
          } else {
            this.selectionTitles.push({
              name: this.category[i].excludes[j].description,
              is_active: false,
              titleId: this.category[i].excludes[j].id
            })
          }
        }
      }
      if (!haveCategoryEditable) {
        this.selectionTitles = []
      }
      if (categoryEditableId == i) {
        this.selectionCategory.push({
          name: this.category[i].description,
          is_active: true
        })
        this.categoryName = this.category[i].description
      } else {
        this.selectionCategory.push({
          name: this.category[i].description,
          is_active: false
        })
      }
      if (this.editable && !this.is_category) {
        this.categoryName = this.category[i].description
        this.ownExclusion = this.editName
      }
    }
  },

  computed: {
    errorText() {
      if (this.error_types.additing_error) {
        return this.excludeTranslate.descriptionsOptions.text
      }

      if (this.error_types.empty_category_error) {
        return this.excludeTranslate.descriptionsCategory.text
      }

      if (this.error_types.empty_title_error) {
        return this.excludeTranslate.needOption.text
      }

      if (this.error_types.duplicateIncludes) {
        return parseTranslate(this.excludeTranslate.errorDuplicateType.text, this.excludeName)
      }

      return ''
    }
  },

  watch: {
    excludeName() {
      this.resetErrors()
    }
  },
  methods: {
    resetErrors() {
      this.have_error = false
      this.error_types = {
        additing_error: false,
        empty_category_error: false,
        empty_title_error: false,
        duplicateIncludes: false
      }
    },
    changeCategory (defaultName) {
      this.categoryName = defaultName
      this.selectionTitles = []
      this.excludeName = this.excludeTranslate.titleDefault.text
      if (this.categoryName != this.excludeTranslate.ownCategory.text) {
        this.ownExclusion = ''
        for (const i in this.selectionCategory) {
          if (this.selectionCategory[i].name == this.categoryName) {
            for (const j in this.category[i].excludes) {
              this.selectionTitles.push({
                name: this.category[i].excludes[j].description,
                is_active: false,
                titleId: this.category[i].excludes[j].id
              })
            }
          }
        }
      }
    },
    changeExcludes (defaultName) {
      this.excludeName = defaultName
    },
    closeWindow () {
      const active = this.isActive
      this.$emit('clear')
      if (this.changed) {
        this.$emit('changeExcludeArray')
      }
      this.changed = false
      this.$emit('close', active)
    },
    async saveNewExclude () {
      // Errors
      let haveThisExclude = false
      this.resetErrors()

      const isInIncluded = this.includeArray.find(includeItem => includeItem.text === this.excludeName)
      if (isInIncluded) {
        haveThisExclude = true
        this.error_types.duplicateIncludes = true
        this.have_error = true
      }

      const isInExcluded = this.arr.find(excludeItem => excludeItem.text === this.excludeName)
      if (isInExcluded) {
        haveThisExclude = true
        this.error_types.additing_error = true
        this.have_error = true
      }

      if (this.categoryName == this.excludeTranslate.categoryDefault.text)
      {
        this.have_error = true
        this.error_types.empty_category_error = true
      } else if ((this.categoryName != this.excludeTranslate.ownCategory.text && this.includeName == this.excludeTranslate.titleDefault.text) || this.includeName == '')
      {
        this.have_error = true
        this.error_types.empty_title_error = true
      }

      if (
        ((this.excludeName != this.excludeTranslate.titleDefault.text &&
          this.excludeName != '') ||
          this.ownExclusion != '') &&
        !haveThisExclude
      ) {
        this.BtnDesabled = true
        if (this.editable) {
          if (this.is_category) {
            this.$axios.$delete(`/api/v1/delete-tour-exclude/?tour_exclude_id=${this.id}&tour_id=${this.tour_id}`)
          } else {
            this.$axios.$delete(`/api/v1/delete-tour-exclude/?exclude_id=${this.id}`)
          }
        }
        let titleId = null
        let titleDescription = ''
        for (const i in this.selectionTitles) {
          if (this.excludeName == this.selectionTitles[i].name) {
            titleId = this.selectionTitles[i].titleId
            titleDescription = this.selectionTitles[i].name
          }
        }
        const data = new FormData()
        data.append('tour_id', this.tour_id)
        data.append('edit_language', this.lang)
        if (this.ownExclusion != '') {
          data.append('description', this.ownExclusion)
        } else {
          data.append('tour_exclude_id', titleId)
        }
        
        const res = await this.$axios.$post('/api/v1/add-tour-exclude/', data, {headers: {'Content-Type': 'multipart/form-data'}});
        this.arr.push({
          text: this.ownExclusion != '' ? (res.description || this.ownExclusion) : titleDescription,
          id: res.id,
          is_category: this.ownExclusion == ''
        })        

        if (this.editable) {
          this.changed = true
        }
        this.BtnDesabled = false
        this.closeWindow()
      }
    }
  }
}
</script>

<style lang="scss">
.pop-up-add-exclude {
  // margin-top: -100px;
  position: relative;
  flex-wrap: wrap;
  background: $white;
  padding-top: 35px;
  padding-left: 40px;
  padding-bottom: 25px;
  width: 600px;
  &__errors {
    margin-top: 20px;
    margin-bottom: 17px;
    padding: 23px 18px 18px 67px;
    width: 520px;
    position: relative;
    border: 2px solid #d80007;
    border-radius: 14px;
    .icon {
      transform: rotate(180deg);
      position: absolute;
      left: 21px;
      top: 18px;
      width: 32px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      margin-bottom: 2px;
      font-weight: bold;
    }
    .description {
      width: 520px;
    }
    .error-type {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    .dot_red {
      margin-top: 6px;
      background: #d80007;
    }
  }
  &__title {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__subtitle {
    margin-bottom: 25px;
    width: 471px;
  }
  &__content {
    margin-bottom: 60px;
    .category {
      &__title {
        margin-bottom: 8px;
      }
      .selection {
        width: 520px;
        &__preview {
          width: 520px;
        }
        &__expand {
          width: 520px;
        }
      }
    }
    .info-type {
      margin-top: 16px;
      padding: 23px 32px 28px 21px;
      border: 2px solid #ff5134;
      border-radius: 8px;
      width: 520px;
      .icon {
        margin-right: 16px;
        width: 26px;
        height: 26px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .description {
        width: 421px;
      }
      &__other {
        display: flex;
        align-items: flex-start;
      }
      &__fees {
        display: flex;
        align-items: flex-start;
      }
    }
    .own-exclusion {
      margin-top: 44px;
      &__title {
        margin-bottom: 8px;
      }
      .v-input {
        width: 520px;
      }
    }
    .exclude {
      margin-top: 44px;
      &__title {
        margin-bottom: 8px;
      }
      .selection {
        width: 520px;
        &__preview {
          width: 520px;
        }
        &__expand {
          width: 520px;
        }
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ececec;
    padding-top: 21px;
    width: 520px;
    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
</style>
