<template>
	<VDialog v-model="modelVisible">
		<div class="edit-price-pop-up">
			<price-option :is-edit="true" :price-option="priceOption" :translations="translations" :tour="tour" :step="0"
				@back="close" @save="save" @close="close" />
		</div>
		<no-members :translations="translations" :no-members="noMembers" :slug="tour.slug" @close="close" />
	</VDialog>
</template>

<script>
import { mapState } from 'vuex'
import {
	getPriceFee,
	getAgeTieredGroupsDefault,
	getAvailableDays,
	getAvailableOpeningTimes,
} from '@/utils/price'
import VDialog from '@/components/common/VDialog.vue'
import PriceOption from '@/components/price/PriceOption.vue'
import NoMembers from '@/components/price/NoMembers.vue'

import { SCHEDULE_TIME_TYPES } from '@/constants/scheduleTypes'

import { formatCurrency } from '@/utils/utils'
import { getPriceDayNames } from '~/utils/date'

export default {
	components: {
		VDialog,
		PriceOption,
		NoMembers,
	},
	props: {
		isActive: { type: Boolean, default: false },
		tour: { type: Object, default: () => ({}) },
		tourLang: { type: String, default: '' },
		tourMembers: { type: Array, default: () => [] },
		translations: { type: Object, default: () => ({}) },
		ticketIndex: { type: Number, default: 0 },
		scheduleType: { type: Object, default: () => ({}) },
		priceScheduleIndex: { type: Number, default: 0 },
		priceSchedule: { type: Object, default: () => ({}) },
	},

	data: () => ({
		priceOption: {
			members: [],
			addTimes: false,
			openingHours: false,
			anotherTimes: [],
			openingScheduleTimes: [],
			days: [
				{ name: 'Mon', day: '1', is_active: false },
				{ name: 'Tue', day: '2', is_active: false },
				{ name: 'Wed', day: '3', is_active: false },
				{ name: 'Thu', day: '4', is_active: false },
				{ name: 'Fri', day: '5', is_active: false },
				{ name: 'Sat', day: '6', is_active: false },
				{ name: 'Sun', day: '7', is_active: false },
			],
			activeDaysCount: 0,
			allDaysChecked: false,
			tieredPricing: false,
		},
		noMembers: false,
		lang: '',
		oldGroupPrice: 0,
		oldGroupCount: 0,
	}),

	async fetch() {
		this.lang = this.$cookies.get('i18n_redirected')
		this.priceOption.days = getPriceDayNames(this.lang)
		// Tiered pricing
		if (!this.tour.is_group_pricing) {
			this.priceOption.tieredPricing = this.tourMembers[0].count_to !== 0
		}
		// Members
		if (this.tour.is_group_pricing) {
			this.priceOption.members = [
				...this.priceOption.members,
				{
					id: this.tourMembers[0].id,
					is_active: true,
					price: this.tourMembers[0].price_without_discount,
					feePrice: getPriceFee(
						this.tourMembers[0].price_without_discount,
						this.userInfo.commission_amount
					),
					groupCount: this.priceSchedule.max_travelers,
				},
			]
			this.oldGroupPrice = this.priceOption.members[0].price
			this.oldGroupCount = this.priceOption.members[0].groupCount
		} else {
			this.priceOption.members = await this.$axios.$get(
				`/api/v1/get-tour-members/?tour_id=${this.tour.id}&language=${this.tourLang}`
			)

			if (this.priceOption.members.length !== 0) {
				for (const i in this.priceOption.members) {
					for (const ti in this.tourMembers) {
						if (
							this.tourMembers[ti].member.age_category.id ===
							this.priceOption.members[i].age_category.id
						) {
							const newMember = {
								...this.priceOption.members[i],
								is_active: true,
								use: true,
							}
							const feePrice = getPriceFee(
								this.tourMembers[ti].price_without_discount,
								this.userInfo.commission_amount
							)

							if (this.priceOption.tieredPricing) {
								if (!newMember.ageTieredGroups) {
									newMember.ageTieredGroups = []
								}
								newMember.ageTieredGroups.push({
									minAge: this.tourMembers[ti].count_from.toString(),
									minAgeError: false,
									maxAge: this.tourMembers[ti].count_to.toString(),
									maxAgeError: false,
									price: this.tourMembers[ti].price_without_discount,
									feePrice: formatCurrency(feePrice, this.lang, 'none'),
									ageCategoryId: newMember.age_category.id,
								})
							} else {
								newMember.ageTieredGroups = [
									getAgeTieredGroupsDefault(newMember.age_category.id),
								]
								newMember.price = this.tourMembers[
									ti
								].price_without_discount
								newMember.feePrice = feePrice
							}
							this.priceOption.members[i] = newMember
							if (!this.priceOption.tieredPricing) {
								break
							}
						} else if (!this.priceOption.members[i].use) {
							this.priceOption.members[i] = {
								...this.priceOption.members[i],
								ageTieredGroups: [],
								is_active: false,
								price: null,
								feePrice: null,
							}
						}
					}
				}
			} else {
				this.noMembers = true
			}
			this.priceOption.members.sort(function (a, b) {
				return b.max_age - a.max_age
			})
		}
		// Days
		for (const i in this.priceSchedule.weekdays) {
			for (const d in this.priceOption.days) {
				if (
					this.priceSchedule.weekdays[i].day_number.toString() ===
					this.priceOption.days[d].day
				) {
					this.priceOption.days[d].is_active = true
					this.priceOption.activeDaysCount += 1
				}
			}
		}
		for (const p in this.tour.get_tour_schedule[this.ticketIndex]?.price_schedules ||
			[]) {
			if (p !== this.priceScheduleIndex) {
				for (const pi in this.tour.get_tour_schedule[this.ticketIndex]
					.price_schedules[p].weekdays) {
					for (const di in this.days) {
						if (
							this.tour.get_tour_schedule[this.ticketIndex].price_schedules[
								p
							].weekdays[pi].day_number === this.days[di].day
						) {
							this.days[di].no_work = true
						}
					}
				}
			}
		}

		if (this.priceOption.activeDaysCount === 7) {
			this.priceOption.allDaysChecked = true
		}
		// Schedule type
		if (this.scheduleType.option === SCHEDULE_TIME_TYPES.startTimes) {
			this.priceOption.addTimes = true
			this.priceOption.openingHours = false

			for (const i in this.priceSchedule.available_time) {
				let timeStr = this.priceSchedule.available_time[i]

				// Проверка типа данных и преобразование в строку
				if (Array.isArray(timeStr)) {
					timeStr = timeStr[0]
				}

				const time = timeStr.split(':')
				const newTime = new Date()
				newTime.setHours(time[0], time[1])
				this.priceOption.anotherTimes.push({ time: newTime, is_active: true })
			}
		} else if (
			this.scheduleType.option === SCHEDULE_TIME_TYPES.coordinatedStartTimes ||
			this.scheduleType.option === SCHEDULE_TIME_TYPES.openingHours
		) {
			this.priceOption.addTimes = false
			this.priceOption.openingHours = true

			this.priceOption.openingScheduleTimes = this.priceSchedule.available_time.reduce(
				(newTimes, timeArray) => {
					const startTimeStr = timeArray[0]
					const endTimeStr = timeArray[1]
					const times = [
						{
							startTime: new Date(`1970-01-01T${startTimeStr}`),
							endTime: new Date(`1970-01-01T${endTimeStr}`),
						},
					]
					return [...newTimes, ...times]
				},
				[]
			)
		}
	},
	computed: {
		...mapState(['userInfo']),

		modelVisible: {
			get() {
				return this.isActive
			},
			set(visible) {
				this.$emit('update:isActive', visible)
			},
		},
	},
	methods: {
		getAvailableDays,
		getAvailableOpeningTimes,

		close() {
			this.modelVisible = false
		},

		async save(data) {
			this.priceOption = data
			this.$emit('loading')

			// Weeks
			const weekSchedule = await this.$axios.$put(
				`/api/v1/set-week-schedule/`,
				null,
				{
					params: {
						week_schedule_id: this.priceSchedule.id,
						available_time: this.getAvailableOpeningTimes(
							this.priceOption.openingHours,
							this.priceOption.addTimes,
							this.priceOption.openingScheduleTimes,
							this.priceOption.anotherTimes
						),
						weekdays: this.getAvailableDays(this.priceOption.days),
					},
				}
			)
			// Group price
			if (this.tour.is_group_pricing) {
				if (
					this.oldGroupPrice !== this.priceOption.members[0].price ||
					this.oldGroupCount !== this.priceOption.members[0].groupCount
				) {
					await this.$axios.$put(
						`/api/v1/set-week-schedule/?week_schedule_id=${this.priceSchedule.id}&max_travelers=${this.priceOption.members[0].groupCount}`
					)
					if (this.tour.travelers.length > 0) {
						await this.$axios.$delete(
							`/api/v1/delete-tour-schedule-member/?schedule_member_id=${this.tourMembers[0].id}`
						)
						await this.$axios.$post(
							`/api/v1/add-tour-schedule-member/?week_schedule_id=${weekSchedule.id}&member_id=${this.tour.travelers[0].id}&price=${this.priceOption.members[0].price}`
						)
					} else {
						const getGroupMembers = await this.$axios.$get(
							`/api/v1/get-members/?tour_id=${this.tour.id}&language=${this.lang}`
						)
						await this.$axios.$post(
							`/api/v1/add-tour-member/?tour_id=${this.tour.id}&age_category_id=${getGroupMembers[0].id}&min_age=1&max_age=100`
						)
						await this.$axios.$post(
							`/api/v1/add-tour-schedule-member/?week_schedule_id=${weekSchedule.id}&member_id=${this.tourMembers[0].id}&price=${this.priceOption.members[0].price}`
						)
					}
				}
				// week_schedule_id: 2000
				// member_id: 2311
				// price: 640.0
			}
			// Person price
			else if (!this.priceOption.tieredPricing) {
				if (this.tourMembers[0].count_to !== 0) {
					for (const i in this.tourMembers) {
						this.$axios.$delete(
							`/api/v1/delete-tour-schedule-member/?schedule_member_id=${this.tourMembers[i].id}`
						)
					}
				}
				for (const i in this.priceOption.members) {
					let addNewMember = true
					for (const om in this.tourMembers) {
						if (
							this.tourMembers[om].member.age_category.name ===
							this.priceOption.members[i].age_category.name
						) {
							addNewMember = false
							if (
								this.tourMembers[om].price_without_discount !==
								this.priceOption.members[i].price ||
								!this.priceOption.members[i].is_active
							) {
								addNewMember = true
								this.$axios.$delete(
									`/api/v1/delete-tour-schedule-member/?schedule_member_id=${this.tourMembers[om].id}`
								)
							}
						}
					}
					if (this.priceOption.members[i].is_active && addNewMember) {
						this.$axios.$post(
							`/api/v1/add-tour-schedule-member/?week_schedule_id=${this.priceSchedule.id}&member_id=${this.priceOption.members[i].id}&price=${this.priceOption.members[i].price}`
						)
					}
				}
			}
			// Person tiered price
			else {
				for (const i in this.tourMembers) {
					await this.$axios.$delete(
						`/api/v1/delete-tour-schedule-member/?schedule_member_id=${this.tourMembers[i].id}`
					)
				}
				for (const i in this.priceOption.members) {
					if (this.priceOption.members[i].is_active) {
						for (const ti in this.priceOption.members[i].ageTieredGroups) {
							this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
								params: {
									week_schedule_id: weekSchedule.id,
									member_id: this.priceOption.members[i].id,
									price: this.priceOption.members[i].ageTieredGroups[ti]
										.price,
									count_to: this.priceOption.members[i].ageTieredGroups[
										ti
									].maxAge,
									count_from: this.priceOption.members[i]
										.ageTieredGroups[ti].minAge,
								},
							})
						}
					}
				}
			}

			this.$router.go(this.localePath('/'))
		},
	},
}
</script>

<style lang="scss">
.edit-price-pop-up {
	position: relative;
	background: $white;
	width: 800px;
}
</style>
