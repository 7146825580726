<template>
  <div v-if="tourTo != null && tourFrom != null">
    <!-- Product title -->
    <div class="translation-block">
      <div class="translation-block__title">{{ translations.productTitle.text }}</div>
      <div class="translation-row translation-block__container">
        <div class="translation-row__english">
          <VTextarea v-model="tourFrom.name" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <VTextarea v-model="$v.tourTo.name.$model" :error="$v.tourTo.name.$error" :max-length="250" rows="2" />
        </div>
      </div>
    </div>

    <!-- Block meeting & pickup -->
    <div class="translation-block">
      <div class="translation-block__title">{{ translations.meetingPickup.text }}</div>
      <!-- Meeting point details -->
      <div v-if="['MEETING', 'MIXED'].includes(tour.meeting_type)" class="translation-row translation-block__container">
        <div class="translation-row__english">
          <div>{{ translations.meetingInstructions.text }}</div>
          <VTextarea v-model="tourFrom.meeting_point.description" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ '\xa0' }}</div>
          <VTextarea
            v-model="$v.tourTo.meeting_point.description.$model"
            :error="$v.tourTo.meeting_point.description.$error"
            :max-length="400"
            rows="4"
          />
        </div>
      </div>
      <!-- Pickup details -->
      <div
        v-if="tour.pick_up_description != null && tour.pick_up_description != '' && tour.pick_up_description != 'null'"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.pickupInstructions.text }}</div>
          <VTextarea v-model="tourFrom.pick_up_description" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ '\xa0' }}</div>
          <VTextarea
            v-model="$v.tourTo.pick_up_description.$model"
            :error="$v.tourTo.pick_up_description.$error"
            :max-length="400"
            rows="4"
          />
        </div>
      </div>

      <!-- End point instructions -->
      <div
        v-if="tour.end_point != null && tour.end_point.description != null && tour.end_point.description != ''"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.endPointInstructions.text }}</div>
          <VTextarea v-model="tourFrom.end_point.description" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ '\xa0' }}</div>
          <VTextarea
            v-model="$v.tourTo.end_point.description.$model"
            :error="$v.tourTo.end_point.description.$error"
            :max-length="400"
            rows="4"
          />
        </div>
      </div>
    </div>

    <!-- Information displayed on voucher -->
    <div v-if="tour.tour_important_info != null && tour.tour_important_info != ''" class="translation-block">
      <div class="translation-block__title">{{ translations.informationDisplayed.text }}</div>
      <!-- Important information -->
      <div class="translation-row translation-block__container">
        <div class="translation-row__english">
          <div>{{ translations.contentImportantinfo.text }}</div>
          <VTextarea v-model="tourFrom.tour_important_info" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ '\xa0' }}</div>
          <VTextarea
            v-model="$v.tourTo.tour_important_info.$model"
            :error="$v.tourTo.tour_important_info.$error"
            :max-length="400"
            rows="4"
          />
        </div>
      </div>
      <!-- Additional information before leaving -->
      <div
        v-if="tour.tour_leaving != null && tour.tour_leaving != ''"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.informationBeforeLeaving.text }}</div>
          <VTextarea v-model="tourFrom.tour_leaving" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ '\xa0' }}</div>
          <VTextarea
            v-model="$v.tourTo.tour_leaving.$model"
            :error="$v.tourTo.tour_leaving.$error"
            :max-length="400"
            rows="4"
          />
        </div>
      </div>
    </div>

    <!-- Tickets redemption -->
    <div
      v-if="tour.ticket_redemption_instruction != null && tour.ticket_redemption_instruction != ''"
      class="translation-block"
    >
      <div class="translation-block__title">{{ translations.ticketsRedemption.text }}</div>
      <!-- Special instructions -->
      <div class="translation-row translation-block__container">
        <div class="translation-row__english">
          <div>{{ translations.specialInstructions.text }}</div>
          <VTextarea v-model="tourFrom.ticket_redemption_instruction" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ '\xa0' }}</div>
          <VTextarea
            v-model="$v.tourTo.ticket_redemption_instruction.$model"
            :error="$v.tourTo.ticket_redemption_instruction.$error"
            :max-length="400"
            rows="4"
          />
        </div>
      </div>
    </div>

    <v-btn base :name="translations.saveContinue.text" :loading="loading" loading-name="..." @click="saveTranslate" />
    <loader v-if="loading" />
  </div>
</template>

<script>
import VTextarea from '@/components/controls/VTextarea.vue';
import VBtn from '@/components/controls/VBtn.vue';
import Loader from '@/components/Loader';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'StepBase',
  components: {
    VTextarea,
    VBtn,
    Loader,
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    tourTranslate: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    newQuery: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
    tourFrom: null,
    tourTo: null,
    importantInfoTranslate: null,
  }),

  validations: {
    tourTo: {
      name: { required },
      meeting_point: {
        description: {
          required: requiredIf(function (model) {
            return ['MEETING', 'MIXED'].includes(this.tour.meeting_type);
          }),
        },
      },

      pick_up_description: {
        required: requiredIf(function (model) {
          return (
            this.tour.pick_up_description != null &&
            this.tour.pick_up_description != '' &&
            this.tour.pick_up_description != 'null'
          );
        }),
      },
      end_point: {
        description: {
          required: requiredIf(function (model) {
            return (
              this.tour.end_point != null &&
              this.tour.end_point.description != null &&
              this.tour.end_point.description != ''
            );
          }),
        },
      },
      tour_important_info: {
        required: requiredIf(function (model) {
          return this.tour.tour_important_info != null && this.tour.tour_important_info != '';
        }),
      },
      tour_leaving: {
        required: requiredIf(function (model) {
          return this.tour.tour_leaving != null && this.tour.tour_leaving != '';
        }),
      },
      ticket_redemption_instruction: {
        required: requiredIf(function (model) {
          return this.tour.ticket_redemption_instruction != null && this.tour.ticket_redemption_instruction != '';
        }),
      },
    },
  },

  mounted() {
    this.tourFrom = JSON.parse(JSON.stringify(this.tour));
    this.tourTo = JSON.parse(JSON.stringify(this.tourTranslate));
  },
  methods: {
    async saveTranslate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      const bodyFormData = new FormData();
      bodyFormData.append('name', this.tourTo.name);
      bodyFormData.append('tour_leaving', this.tourTo.tour_leaving);
      bodyFormData.append('ticket_redemption_instruction', this.tourTo.ticket_redemption_instruction);
      bodyFormData.append('pick_up_description', this.tourTo.pick_up_description);
      bodyFormData.append('tour_important_info', this.tourTo.tour_important_info);
      bodyFormData.append('meeting_point_description', this.tourTo.meeting_point?.description);
      bodyFormData.append('end_point_description', this.tourTo.end_point?.description);

      await this.$axios
        .$put(`/api/v1/edit-tour/`, bodyFormData, {
          params: {
            tour_id: this.tour.id,
            edit_language: this.newQuery.lang,
          },
        })
        .then(() => {
          this.loading = false;
          this.$emit('next-step');
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
