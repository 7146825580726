var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "translations-list" },
    [
      _c("div", { staticClass: "translations-list__title" }, [
        _vm._v(_vm._s(_vm.translations.headerTranslations?.text)),
      ]),
      _c("div", { staticClass: "translations-list__desc" }, [
        _vm._v(_vm._s(_vm.translations.warnReview?.text) + "."),
      ]),
      _vm._l(_vm.getTranslateLanguages, function (item, index) {
        return _c("div", { key: index, staticClass: "translate-card" }, [
          _c("div", { staticClass: "translate-card__title" }, [
            _vm._v(_vm._s(item.name)),
          ]),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.goToPage(item.code)
                },
              },
            },
            [_c("base-icon", { attrs: { "icon-name": "Edit" } })],
            1
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }