var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tour-price",
      class: { "tour-price--sale": _vm.isSale, "tour-price--small": _vm.small },
    },
    [
      _c("div", { staticClass: "tour-price__from" }, [
        _vm._v("\n    " + _vm._s(_vm.fromText) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tour-price__price" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.formatPrice(_vm.price, _vm.lang, "EUR")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tour-price__group" }, [
        _vm._v("\n    " + _vm._s(_vm.groupText) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }