var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.highlights.length
    ? _c(
        "div",
        { staticClass: "preview__section", attrs: { id: "basic-info" } },
        [
          _c("h3", { staticClass: "preview__title" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.translations.highlights.text) + "\n  "
            ),
          ]),
          _vm._v(" "),
          _vm.tourHighlights.more
            ? [
                _vm._l(_vm.tourHighlights.start, function (highlight) {
                  return _c(
                    "div",
                    {
                      key: `highlight-${highlight.id}`,
                      staticClass: "preview__include",
                    },
                    [
                      _c("div", {
                        staticClass: "preview__dot preview__dot--blue",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "preview__include-text" }, [
                        _vm._v(_vm._s(highlight.description)),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.showTourHighlights
                  ? _c(
                      "span",
                      {
                        staticClass: "preview__read-more",
                        on: {
                          click: function ($event) {
                            _vm.showTourHighlights = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.textReadMore.text))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTourHighlights
                  ? [
                      _vm._l(_vm.tourHighlights.more, function (highlight) {
                        return _c(
                          "div",
                          {
                            key: `highlight-${highlight.id}`,
                            staticClass: "preview__include",
                          },
                          [
                            _c("div", {
                              staticClass: "preview__dot preview__dot--blue",
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "preview__include-text" },
                              [_vm._v(_vm._s(highlight.description))]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "preview__read-more",
                          on: {
                            click: function ($event) {
                              _vm.showTourHighlights = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations.textHide.text))]
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._l(_vm.tourHighlights, function (highlight) {
                return _c(
                  "div",
                  {
                    key: `highlight-${highlight.id}`,
                    staticClass: "preview__include",
                  },
                  [
                    _c("div", {
                      staticClass: "preview__dot preview__dot--blue",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "preview__include-text" }, [
                      _vm._v(_vm._s(highlight.description)),
                    ]),
                  ]
                )
              }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }