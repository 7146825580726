var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "translations__content" }, [
        _c(
          "div",
          { staticClass: "translate-your-product" },
          [
            _c("div", { staticClass: "translate-your-product__title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.translations.title.text) + "\n      "
              ),
            ]),
            _c("div", { staticClass: "translate-your-product__subtitle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translations.subtitle.text) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "translate-your-product__description" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translations.description.text) +
                  "\n      "
              ),
            ]),
            _c("v-btn", {
              attrs: { base: "", name: _vm.translations.startBtn.text },
              on: {
                click: function ($event) {
                  _vm.translateSelect = !_vm.translateSelect
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _vm._m(0),
          _c("div", { staticClass: "info__title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.translations.infotypeTitle.text) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "info__description" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.translations.infotypeDescription.text) +
                "\n      "
            ),
          ]),
        ]),
      ]),
      _c("translateSelect", {
        attrs: {
          "is-active": _vm.translateSelect,
          "select-language-translate": _vm.selectTranslations,
          tour: _vm.tour,
        },
        on: {
          close: function ($event) {
            _vm.translateSelect = !_vm.translateSelect
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info__icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/svg/translation-planet.svg"),
          alt: "translation-planet",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }