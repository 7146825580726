<template>
  <div v-if="notSuitable.length" id="notSuitable" class="preview__section">
    <h3 class="preview__title">
      {{ translations.tourDescriptionNotSuitable.text }}
    </h3>
    <template v-if="tourNotSuitable.more">
      <div v-for="suitable in tourNotSuitable.start" :key="`suitable-${suitable.id}`" class="preview__include">
        <div class="preview__dot preview__dot--orange" />
        <span class="preview__include-text">{{ suitable.description }}</span>
      </div>
      <span v-if="!showTourNotSuitable" class="preview__read-more" @click="showTourNotSuitable = true">{{ translations.textReadMore.text }}</span>
      <template v-if="showTourNotSuitable">
        <div v-for="suitable in tourNotSuitable.more" :key="`suitable-${suitable.id}`" class="preview__include">
          <div class="preview__dot preview__dot--orange" />
          <span class="preview__include-text">{{ suitable.description }}</span>
        </div>
        <span class="preview__read-more" @click="showTourNotSuitable = false">{{ translations.textHide.text }}</span>
      </template>
    </template>
    <template v-else>
      <div v-for="suitable in tourNotSuitable" :key="`suitable-${suitable.id}`" class="preview__include">
        <div class="preview__dot preview__dot--orange" />
        <span class="preview__include-text">{{ suitable.description }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NotSuitable',
  props: {
    notSuitable: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showTourNotSuitable: false
    }
  },
  computed: {
    tourNotSuitable () {
      return this.notSuitable?.length > 4
        ? { start: this.notSuitable.slice(0, 4), more: this.notSuitable.slice(4) }
        : this.notSuitable
    }
  }
}
</script>

<style lang="scss">
</style>
