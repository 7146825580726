var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        fill: "none",
        viewBox: "0 0 24 24",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          "fill-rule": "evenodd",
          d: "M19.6 13.08c.19.39.54.66.97.69.64.04 1.2-.48 1.23-1.16.04-.68-.45-1.27-1.09-1.31a1.14 1.14 0 0 0-1.04.55l-1.17-.08a6.72 6.72 0 0 0-1.16-3.59l.91-.86c.39.13.82.05 1.14-.25a1.3 1.3 0 0 0 .1-1.74 1.12 1.12 0 0 0-1.64-.1c-.32.3-.45.74-.37 1.16l-.91.86a5.94 5.94 0 0 0-3.21-1.66l.07-1.25c.36-.2.61-.57.64-1.03.04-.68-.45-1.27-1.1-1.3-.63-.05-1.18.47-1.22 1.15-.03.45.18.87.51 1.1l-.07 1.25c-1.25.05-2.41.5-3.37 1.24L8 5.78v-.01c.13-.4.06-.86-.23-1.2a1.12 1.12 0 0 0-1.64-.11 1.3 1.3 0 0 0-.1 1.74 1.12 1.12 0 0 0 1.1.4l.81.97a6.62 6.62 0 0 0-1.57 3.41l-1.17-.07a1.16 1.16 0 0 0-.97-.68c-.64-.05-1.19.47-1.23 1.15-.04.68.45 1.27 1.1 1.31a1.14 1.14 0 0 0 1.03-.55l1.17.08a6.72 6.72 0 0 0 1.17 3.59l-.92.86a1.11 1.11 0 0 0-1.14.25 1.3 1.3 0 0 0-.1 1.74c.43.51 1.16.56 1.64.11a1.28 1.28 0 0 0 .37-1.17l.92-.86a5.94 5.94 0 0 0 3.2 1.66h.01l-.07 1.25c-.36.2-.62.58-.64 1.03-.04.68.45 1.27 1.09 1.3.64.05 1.19-.47 1.23-1.15.02-.45-.18-.87-.52-1.1l.07-1.25A5.85 5.85 0 0 0 16 17.24l.81.98c-.12.4-.05.87.23 1.2.43.52 1.16.57 1.64.11a1.3 1.3 0 0 0 .1-1.74c-.28-.34-.7-.48-1.1-.4l-.8-.97c.8-.92 1.37-2.1 1.56-3.4V13l1.17.07Zm-2.25-.76-.01-.5a5.43 5.43 0 0 0-.95-2.9l-.28-.41-.34-.35a4.8 4.8 0 0 0-2.6-1.35l-.46-.08-.48.02c-1 .04-1.95.4-2.73 1l-.38.3-.32.37a5.35 5.35 0 0 0-1.27 2.76l-.08.5.02.5c.03 1.07.37 2.07.94 2.9l.28.4.35.35c.7.7 1.6 1.18 2.6 1.35l.46.08.47-.02a4.72 4.72 0 0 0 2.73-1l.39-.3.32-.37a5.35 5.35 0 0 0 1.27-2.76l.07-.5ZM6.9 5.38Z",
          "clip-rule": "evenodd",
        },
      }),
      _c("path", {
        attrs: {
          stroke: "currentColor",
          "stroke-width": ".1",
          d: "M19.6 13.09c.19.38.54.65.97.68.64.04 1.2-.48 1.23-1.16.04-.68-.45-1.27-1.09-1.31a1.14 1.14 0 0 0-1.04.55l-1.17-.08a6.72 6.72 0 0 0-1.16-3.59l.91-.86c.39.13.82.05 1.14-.25a1.3 1.3 0 0 0 .1-1.74 1.12 1.12 0 0 0-1.64-.1c-.32.3-.45.74-.37 1.16l-.91.86a5.94 5.94 0 0 0-3.21-1.66l.07-1.25c.36-.2.61-.57.64-1.03.04-.68-.45-1.27-1.1-1.3-.63-.05-1.18.47-1.22 1.15-.03.45.18.87.51 1.1l-.07 1.25c-1.25.05-2.41.5-3.37 1.24L8 5.78v-.01c.13-.4.06-.86-.23-1.2a1.12 1.12 0 0 0-1.64-.11 1.3 1.3 0 0 0-.1 1.74 1.12 1.12 0 0 0 1.1.4l.81.97a6.62 6.62 0 0 0-1.57 3.41l-1.17-.07a1.16 1.16 0 0 0-.97-.68c-.64-.05-1.19.47-1.23 1.15-.04.68.45 1.27 1.1 1.31a1.14 1.14 0 0 0 1.03-.55l1.17.08a6.72 6.72 0 0 0 1.17 3.59l-.92.86a1.11 1.11 0 0 0-1.14.25 1.3 1.3 0 0 0-.1 1.74c.43.51 1.16.56 1.64.11a1.28 1.28 0 0 0 .37-1.17l.92-.86a5.94 5.94 0 0 0 3.2 1.66h.01l-.07 1.25c-.36.2-.62.58-.64 1.03-.04.68.45 1.27 1.09 1.3.64.05 1.19-.47 1.23-1.15.02-.45-.18-.87-.52-1.1l.07-1.25A5.85 5.85 0 0 0 16 17.24l.81.98c-.12.4-.05.87.23 1.2.43.52 1.16.57 1.64.11a1.3 1.3 0 0 0 .1-1.74c-.28-.34-.7-.48-1.1-.4l-.8-.97c.8-.92 1.37-2.1 1.56-3.4V13l1.17.07Zm-2.26-1.27.01.5-.07.5A5.35 5.35 0 0 1 16 15.56l-.32.37-.39.3c-.77.6-1.72.96-2.73 1l-.47.02-.47-.08a4.8 4.8 0 0 1-2.6-1.35l-.34-.34-.28-.41a5.43 5.43 0 0 1-.94-2.9l-.02-.5.08-.5A5.35 5.35 0 0 1 8.8 8.42l.32-.37.38-.3c.78-.6 1.72-.96 2.73-1l.48-.02.46.08c1 .17 1.9.65 2.6 1.35l.34.35.28.4c.57.83.91 1.83.95 2.9ZM6.9 5.38Z",
        },
      }),
      _c("path", {
        attrs: {
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-width": "1.4",
          d: "m9.85 18.15 6-11.98",
        },
      }),
      _c("path", {
        attrs: {
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-width": "1.4",
          d: "m9.06 17.54 5.99-11.98",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }