<template>
  <div class="pricing-schedule-price-pop-up">
    <div class="pop-up__close" @click="$emit('close')">
      <img src="@/assets/img/svg/close.svg" alt="close" />
    </div>
    <div class="pricing-schedule-price-pop-up__title">
      {{ translations.title.text }}
    </div>
    <div class="pricing-schedule-price-pop-up__content">
      <div class="pricing-schedule-price-pop-up__content--title">
        {{ translations.contentStart.text }}
      </div>
      <date-picker
        v-model="schedule.time1"
        :placeholder="translations.startPlaceholder.text"
        :class="{ 'input-error': dateError }"
        value-type="YYYY-MM-DD"
        :lang="calendarExtra.lang"
        :format="calendarExtra.format"
        :editable="false"
        :disabled-date="disabledDates"
        @change="checkDateEndError()"
      />
      <div v-if="!schedule.endDate" class="pricing-schedule-price-pop-up__end-date-hide">
        <div class="pricing-schedule-price-pop-up__end-date-hide--title">
          <div class="pricing-schedule-price-pop-up__end-date-hide--text" @click="schedule.endDate = !schedule.endDate">
            {{ translations.contentEnd.text }}
          </div>
          <div class="pricing-schedule-price-pop-up__end-date-hide--optional">
            {{ translations.optional.text }}
          </div>
          <v-question-info :description="translations.endQuestion.text" />
        </div>
      </div>
      <div v-else class="pricing-schedule-price-pop-up__end-date-show">
        <div class="pricing-schedule-price-pop-up__end-date-show--title">
          {{ translations.contentEnd.text }}
          <div class="pricing-schedule-price-pop-up__end-date-show--optional">
            {{ translations.optional.text }}
          </div>
          <v-question-info :description="translations.endQuestion.text" />
        </div>
        <div class="pricing-schedule-price-pop-up__end-date-show--date">
          <date-picker
            v-model="schedule.time2"
            value-type="YYYY-MM-DD"
            :lang="calendarExtra.lang"
            :format="calendarExtra.format"
            :placeholder="translations.endPlaceholder.text"
            :disabled-date="disabledDates"
            :editable="false"
            @change="checkDateEndError()"
          />
          <div class="pricing-schedule-price-pop-up__end-date-show--clear" @click="schedule.time2 = null">
            {{ translations.endClearDate.text }}
          </div>
        </div>
      </div>
      <div class="pricing-schedule-price-pop-up__select-option">
        <VRadioGroup
          v-model="schedule.selectedScheduleTypeId"
          name="scheduleType"
          class="pricing-schedule-price-pop-up__select-option--item"
        >
          <template #default="{ on, attrs }">
            <template v-for="scheduleType in scheduleTypes">
              <VRadioButton
                :key="scheduleType.id"
                :label="scheduleType.title"
                :value="scheduleType.id"
                v-bind="attrs"
                v-on="on"
              />
              <div
                v-if="scheduleType.description"
                :key="`description-${scheduleType.id}`"
                class="pricing-schedule-price-pop-up__select-option--description"
              >
                {{ scheduleType.description }}
              </div>
            </template>
          </template>
        </VRadioGroup>
      </div>
    </div>
    <div class="pricing-schedule-price-pop-up__nav">
      <v-btn outline :name="step == 3 ? translations.backBtn.text : translations.cancelBtn.text" @click="previousPage" />
      <div class="pagination row">
        <div v-if="step == 3" class="dot dot_page" />
        <div class="dot dot_page dot_page_active" />
        <div class="dot dot_page" />
      </div>
      <v-btn base :name="translations.nextBtn.text" @click="changePage" />
    </div>

    <improve-options
      :is-active="improveOptions"
      :translations="improveOptionsTranslate"
      @close="improveOptions = !improveOptions"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import { checkDateEnd } from '@/utils/validations'
import VBtn from '@/components/controls/VBtn.vue'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VQuestionInfo from '@/components/common/VQuestionInfo.vue'
import notificationService from '@/services/notification'
import ImproveOptions from '@/components/pop-up/price/ImproveOptions.vue'

import { SCHEDULE_TIME_TYPES } from '@/constants/scheduleTypes'
import { calendarExtra } from '~/utils/componentHelpers'

const scheduleTimeTypeNone = 'other'

export default {
  components: {
    VBtn,
    DatePicker,
    VRadioGroup,
    VRadioButton,
    VQuestionInfo,
    ImproveOptions
  },
  props: {
    translations: { type: Object, default: () => ({}) },
    tour: { type: Object, default: () => ({}) },
    tourLang: { type: String, default: '' },
    improveOptionsTranslate: { type: Object, default: () => ({}) },
    schedulePricing: { type: Object, default: () => ({}) },
    step: { type: Number, default: 0 }
  },
  data() {
    return {
      schedule: {
        selectedScheduleTypeId: null,
        time1: new Date().toISOString(),
        time2: null,
        endDate: false
      },
      scheduleTypes: [],
      dateError: false,
      improveOptions: false
    }
  },
  async fetch() {
    this.schedule = JSON.parse(JSON.stringify({...this.schedule, ...this.schedulePricing}));
    this.scheduleTypes = await this.$axios.$get(`/api/v1/show-schedule-types/?language=${this.tourLang}`)
        .then(data => data.filter(x => x.option !== scheduleTimeTypeNone))
    this.schedule.selectedScheduleTypeId = this.scheduleTypes[0].id
  },
  computed: {
    calendarExtra,     
    selectedScheduleType() {
      return this.scheduleTypes.find(({ id }) => id === this.schedule.selectedScheduleTypeId)
    },

    isOpeningHoursSelected() {
      return (
        this.selectedScheduleType.option === SCHEDULE_TIME_TYPES.openingHours ||
        this.selectedScheduleType.option === SCHEDULE_TIME_TYPES.coordinatedStartTimes
      )
    },
    isStartsTimeSelected() {
      return this.selectedScheduleType.option === SCHEDULE_TIME_TYPES.startTimes
    }
  },
  watch: {
    selectedScheduleType() {
      if (this.selectedScheduleType.option === scheduleTimeTypeNone) {
        this.improveOptions = true
      }
    }
  },
  methods: {
    checkDateEnd,
    checkDateEndError() {
      if (!this.checkDateEnd(this.schedule.time1, this.schedule.time2)) {
        this.schedule.time2 = null
        notificationService.error({ title: this.translations.endDateCannotBeEarlierStart.text })
      }

      if (this.time1 != null && this.time1 === this.time2) {
        this.time2 = null
        notificationService.error({ title: this.translations.startDateCannotBeEndDate.text })
      }
    },
    disabledDates(date) {
      const newDate = new Date()
      newDate.setDate(newDate.getDate() - 1)
      return newDate > date
    },
    previousPage() {
      this.$emit('back', this.priceOptionLocal)
    },
    changePage() {
      if (
        this.schedule.time1 != null &&
        this.schedule.time1 !== '' &&
        this.selectedScheduleType.option !== scheduleTimeTypeNone
      ) {
        if (this.schedule.time2 == null || this.schedule.time2 === '') this.schedule.endDate = false
        this.$emit('save', {
          schedule: this.schedule,
          addTimes: this.isStartsTimeSelected,
          openingHours: this.isOpeningHoursSelected
        })
      } else {
        this.dateError = this.schedule.time1 == null
      }
      this.improveOptions = this.selectedScheduleType.option === scheduleTimeTypeNone
    }
  }
}
</script>
<style lang="scss">
.pricing-schedule-price-pop-up {
  &__title {
    margin-bottom: 23px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
  }
  &__content {
    margin-bottom: 70px;
    border-top: 1px solid #ececec;
    padding-top: 43px;
    &--title {
      margin-bottom: 10px;
    }
  }
  &__end-date-hide {
    margin-top: 26px;
    margin-bottom: 47px;
    &--title {
      display: flex;
    }
    &--text {
      cursor: pointer;
      margin-right: 8px;
      color: $primary;
    }
    &--optional {
      margin-right: 8px;
      color: #999999;
    }
  }
  &__end-date-show {
    margin-top: 26px;
    margin-bottom: 47px;
    &--title {
      margin-bottom: 10px;
      display: flex;
    }
    &--optional {
      margin-left: 8px;
      margin-right: 8px;
      color: #999999;
    }
    &--date {
      display: flex;
      align-items: center;
    }
    &--clear {
      cursor: pointer;
      margin-left: 20px;
      font-weight: bold;
      color: $primary;
    }
  }
  &__select-option {
    margin-bottom: 44px;
    &--item {
      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
    &--description {
      margin-top: 5px;
      color: #888a93;
      font-size: 13px;
      width: 760px;
    }
  }
  &__nav {
    border-top: 1px solid #ececec;
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
    .v-btn {
      width: 150px;
      min-width: 150px;
    }
  }
}
</style>
