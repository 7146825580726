<template>
  <div class="pricing-change-modal-person-page">
    <div class="pricing-change-modal-person-page__title">
      {{ getDateSpan(oldSchedule, translations) }}
    </div>
    <div class="pricing-change-modal-person-page__tiered-pricing">
      <div class="switcher" @click="tieredClick()">
        <Checkbox switcher :checked="tiered" />
      </div>
      {{ translations.popupPersonThirdPageTieredPriceSwitcher.text }}
    </div>
    <div v-if="!tiered" class="pricing-change-modal-person-page__block">
      <ErrorBanner v-if="isHaveMinPriceError || isHaveMaxPriceError" :errors="errorList"
        class="change-pricing-type__error-banner change-pricing-type__error-banner--tiered" />
      <div v-for="(member, index) in prices" :key="`age-${index}`" class="pricing-change-modal-person-page__age-item">
        <div :class="index == 0
          ? 'pricing-change-modal-person-page__age-item--checkbox-first'
          : 'pricing-change-modal-person-page__age-item--checkbox'
          " @click="changeMember(index)">
          <Checkbox :id="`member-${index}`" default :name="member.member.name" :checked="member.is_active" />
        </div>
        <div class="pricing-change-modal-person-page__price pricing-change-modal-person-page__price-no-tried">
          <div v-if="index == 0" class="pricing-change-modal-person-page__price--title">
            {{ translations.popupPersonThirdPageSuggested.text }}
            <v-question-info :description="translations.popupPersonThirdPageSuggestedDescription.text" />
          </div>
          <v-input2 v-model="$v.prices.$each.$iter[index].price.$model"
            :error="$v.prices.$each.$iter[index].price.$error" type="number" prepend-text="EUR" onpaste="return false;"
            @blur="changePriceBlur(index)" @input="changePrice(index)" />
        </div>
        <div
          class="pricing-change-modal-person-page__price pricing-change-modal-person-page__price-no-tried pricing-change-modal-person-page__get-paid">
          <div v-if="index == 0" class="pricing-change-modal-person-page__price--title">
            {{ translations.popupPersonThirdPageGetPaid.text }}
            <v-question-info :description="translations.popupPersonThirdPageGetPaidDescription.text" />
          </div>
          <v-input :value="showPrice(prices[index].feePrice)" prepend-text="EUR" is-disabled />
        </div>
      </div>
    </div>
    <div v-else class="pricing-change-modal-person-page__block">
      <ErrorBanner v-if="
        isHaveMinPriceError ||
        isHaveMaxPriceError ||
        isHaveAgeError ||
        isHaveAgeIntervalError
      " :errors="errorList" class="change-pricing-type__error-banner change-pricing-type__error-banner--tiered" />
      <div v-for="(member, index) in prices" :key="`age-${index}`" class="pricing-change-modal-person-page__age-item">
        <div :class="index == 0
          ? 'pricing-change-modal-person-page__age-item--checkbox-first-tiered'
          : 'pricing-change-modal-person-page__age-item--checkbox'
          " @click="changeMember(index)">
          <Checkbox :id="`member-${index}`" default :name="member.member.name" :checked="member.is_active" />
        </div>
        <div class="age__tiered-pricing">
          <div v-for="(ageTieredGroup, ageTieredIndex) in member.ageTieredGroups"
            :key="`ageTieredGroup-${ageTieredIndex}`" class="pricing-change-modal-person-page__age-item">
            <div class="pricing-change-modal-person-page__price-min-max">
              <div v-if="ageTieredIndex == 0 && index == 0">
                {{ translations.popupPersonThirdPageTieredPriceMinMax.text }}
              </div>
              <div class="pricing-change-modal-person-page__price-min-max--input">
                <v-input v-model="$v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[
                  ageTieredIndex
                ].minAge.$model
                  " onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpaste="return false;"
                  type="number" :error="ageTieredGroup.minAgeError ||
                    $v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[
                      ageTieredIndex
                    ].minAge.$error
                    " @input="checkMinAgeCount(index, ageTieredIndex)" />
                <p>-</p>
                <v-input v-model="$v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[
                  ageTieredIndex
                ].maxAge.$model
                  " onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpaste="return false;"
                  type="number" :error="ageTieredGroup.maxAgeError ||
                    $v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[
                      ageTieredIndex
                    ].maxAge.$error
                    " @input="checkMinAgeCount(index, ageTieredIndex)" />
              </div>
            </div>
            <div class="pricing-change-modal-person-page__price">
              <div v-if="ageTieredIndex == 0 && index == 0" class="pricing-change-modal-person-page__price--title">
                {{ translations.popupPersonThirdPageSuggested.text }}
                <v-question-info :description="translations.popupPersonThirdPageSuggestedDescription.text
                  " />
              </div>
              <v-input2 v-model="$v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[ageTieredIndex]
                .price.$model
                " :error="$v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[ageTieredIndex]
                  .price.$error
                  " type="number" prepend-text="EUR" onpaste="return false;" @input="changePriceTiered(ageTieredGroup)"
                @blur="changePriceTieredBlur(ageTieredGroup)" />
            </div>
            <div class="pricing-change-modal-person-page__price pricing-change-modal-person-page__get-paid">
              <div v-if="ageTieredIndex == 0 && index == 0" class="pricing-change-modal-person-page__price--title">
                {{ translations.popupPersonThirdPageGetPaid.text }}
                <v-question-info :description="translations.popupPersonThirdPageGetPaidDescription.text" />
              </div>
              <v-input :value="showPrice(ageTieredGroup.feePrice)" prepend-text="EUR" is-disabled />
            </div>
            <img v-if="ageTieredIndex != 0" class="pricing-change-modal-person-page__del-cross"
              src="@/assets/img/svg/del_cross.svg" alt="del_cross"
              @click="removeField(ageTieredIndex, member.ageTieredGroups)" />
          </div>

          <div class="pricing-change-modal-person-page__add-input"
            @click="addAgeGroupField(member.ageTieredGroups, { member })">
            {{ translations.popupPersonThirdPageTieredPriceAdd.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="change-pricing-type__nav">
      <v-btn outline :name="translations.popupNavBack.text" @click="back" />
      <div class="nav__pagination row">
        <div v-for="index in pageCount" :key="`pageNum${index}`">
          <div class="dot dot_page" :class="{ dot_page_active: index - 1 == currentPageIndex }" />
        </div>
      </div>
      <v-btn base :name="isLastPage ? translations.popupNavSave.text : translations.popupNavNext.text
        " @click="next" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { requiredIf, minValue, maxValue } from "vuelidate/lib/validators"
import { checkAge, getDateSpan, isAdultOrSenior } from "@/utils/price"
import { parseTranslate } from "@/utils/translations"
import Checkbox from "@/components/controls/Checkbox.vue"
import VBtn from "@/components/controls/VBtn.vue"
import VInput from "@/components/controls/VInput.vue"
import VInput2 from "@/components/controls/VInput2.vue"
import ErrorBanner from "@/components/ErrorBanner.vue"
import VQuestionInfo from "@/components/common/VQuestionInfo.vue"
import { formatCurrency } from '@/utils/utils'

const MIN_PRICE = 2.99
const MAX_PRICE = 99999999999999
const MIN_AGE = 1
const MAX_AGE = 99

export default {
  name: "PersonPrice",
  components: {
    Checkbox,
    VBtn,
    VInput,
    VInput2,
    ErrorBanner,
    VQuestionInfo,
  },
  props: {
    translations: { type: Object, default: () => ({}) },
    pageCount: { type: Number, default: 0 },
    currentPageIndex: { type: Number, default: 0 },
    oldSchedule: { type: Object, default: () => ({}) },
    isLastPage: { type: Boolean, default: false },
    isTiered: { type: Boolean, default: false },
    pageIndex: { type: Number, default: 0 },
    members: { type: Array, default: () => [] },
  },
  data() {
    return {
      isHaveMinPriceError: false,
      isHaveMaxPriceError: false,
      isHaveAgeError: false,
      isHaveAgeIntervalError: false,
      priceError: [],
      ageError: [],
      prices: [],
      tiered: false,
    }
  },
  fetch() {
    this.prices = JSON.parse(JSON.stringify(this.members))
    this.tiered = this.isTiered
  },
  computed: {
    ...mapState(["userInfo"]),
    errorList() {
      return this.priceError.concat(this.ageError)
    },
  },
  validations() {
    return {
      prices: {
        $each: {
          ...(!this.tiered
            ? {
              price: {
                required: requiredIf(
                  (field) => field.is_active && this.isAdultOrSenior(field)
                ),
                minValue: (field, member) =>
                  member.is_active && this.isAdultOrSenior(member.member)
                    ? minValue(MIN_PRICE)(Number(field))
                    : true,
                maxValue: (field, member) =>
                  member.is_active && this.isAdultOrSenior(member.member)
                    ? maxValue(MAX_PRICE)(Number(field))
                    : true,
              },
            }
            : {
              ageTieredGroups: {
                $each: {
                  price: {
                    required: requiredIf(
                      (field) =>
                        this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                          .is_active &&
                        this.checkAdultsOrSeniorById(
                          field.ageCategoryId,
                          field.pageIndex
                        )
                    ),
                    minValue: (value, field) =>
                      this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                        .is_active &&
                        this.checkAdultsOrSeniorById(field.ageCategoryId, field.pageIndex)
                        ? minValue(MIN_PRICE)(Number(value))
                        : true,
                    maxValue: (value, field) =>
                      this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                        .is_active &&
                        this.checkAdultsOrSeniorById(field.ageCategoryId, field.pageIndex)
                        ? maxValue(MAX_PRICE)(Number(value))
                        : true,
                  },
                  minAge: {
                    required: requiredIf(
                      (field) =>
                        this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                          .is_active &&
                        this.checkAdultsOrSeniorById(
                          field.ageCategoryId,
                          field.pageIndex
                        )
                    ),
                    minValue: (value, field) =>
                      this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                        .is_active &&
                        this.checkAdultsOrSeniorById(field.ageCategoryId, field.pageIndex)
                        ? minValue(MIN_AGE)(Number(value))
                        : true,
                    maxValue: (value, field) =>
                      this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                        .is_active &&
                        this.checkAdultsOrSeniorById(field.ageCategoryId, field.pageIndex)
                        ? maxValue(MAX_AGE)(Number(value))
                        : true,
                  },
                  maxAge: {
                    required: requiredIf(
                      (field) =>
                        this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                          .is_active &&
                        this.checkAdultsOrSeniorById(
                          field.ageCategoryId,
                          field.pageIndex
                        )
                    ),
                    minValue: (value, field) =>
                      this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                        .is_active &&
                        this.checkAdultsOrSeniorById(field.ageCategoryId, field.pageIndex)
                        ? minValue(MIN_AGE)(Number(value))
                        : true,
                    maxValue: (value, field) =>
                      this.getAgeCategoryById(field.ageCategoryId, field.pageIndex)
                        .is_active &&
                        this.checkAdultsOrSeniorById(field.ageCategoryId, field.pageIndex)
                        ? maxValue(MAX_AGE)(Number(value))
                        : true,
                  },
                },
              },
            }),
        },
      },
    }
  },
  methods: {
    getDateSpan,
    isAdultOrSenior,
    formatCurrency,
    checkAdultsOrSeniorById(id, pageIndex) {
      const member = this.getAgeCategoryById(id, pageIndex)

      return this.isAdultOrSenior(member.member)
    },
    getAgeCategoryById(id) {
      return this.prices.find((member) => member.member.id === id)
    },
    addAgeGroupField(fieldType, { member }) {
      fieldType.push({
        minAge: null,
        minAgeError: false,
        maxAge: null,
        maxAgeError: false,
        price: "0",
        feePrice: "0",
        ageCategoryId: member.member.id,
      })
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1)
    },
    tieredClick() {
      this.tiered = !this.tiered
      this.priceError = []
      this.ageError = []
      this.isHaveMinPriceError = false
      this.isHaveMaxPriceError = false
      this.isHaveAgeError = false
      this.isHaveAgeIntervalError = false
    },
    changeMember(index) {
      if (this.prices[index]) {
        if (document.getElementById(`member-${index}`).checked) {
          this.prices[index].is_active = true
        } else {
          this.prices[index].is_active = false
        }
      }
    },
    checkMinAgeCount(index, tieredIndex) {
      this.ageError = []
      this.isHaveAgeError = false
      this.isHaveAgeIntervalError = false
      const res = checkAge(this.prices[index].ageTieredGroups, tieredIndex)
      this.prices[index].ageTieredGroups[tieredIndex].minAgeError = res.minAgeError
      this.prices[index].ageTieredGroups[tieredIndex].maxAgeError = res.maxAgeError

      if (res.minAgeError || res.maxAgeError) {
        this.isHaveAgeIntervalError = true
        this.ageError.push(this.translations.bookingInfo.text)
      }

      for (let i = 0; i < this.prices.length; i++) {
        for (let j = 0; j < this.prices[i].ageTieredGroups.length; j++) {
          this.checkAgeError(
            this.$v.prices.$each.$iter[i].ageTieredGroups.$each.$iter[j]
          )
        }
      }
    },
    showPrice(number) {
      if (number == null) return number
      const lang = this.$cookies.get("i18n_redirected")
      return formatCurrency(number, lang, 'none')
    },
    changePrice(index) {
      const feePrice =
        this.prices[index].price -
        this.prices[index].price * (this.userInfo.commission_amount / 100)
      this.prices[index].feePrice = feePrice
      this.priceError = []
      this.isHaveMinPriceError = false
      this.isHaveMaxPriceError = false
      for (let i = 0; i < this.prices.length; i++) {
        this.checkPriceError(this.$v.prices.$each.$iter[i])
      }
    },
    changePriceBlur(index) {
      const oldPrice = this.prices[index].price
      const value = Number(oldPrice) || 0
      this.prices[index].price = value.toFixed(2)
    },
    changePriceTiered(ageTiredPerson) {
      const feePrice =
        ageTiredPerson.price -
        ageTiredPerson.price * (this.userInfo.commission_amount / 100)
      ageTiredPerson.feePrice = feePrice
      this.priceError = []
      this.isHaveMinPriceError = false
      this.isHaveMaxPriceError = false
      for (let i = 0; i < this.prices.length; i++) {
        for (let j = 0; j < this.prices[i].ageTieredGroups.length; j++) {
          this.checkPriceError(
            this.$v.prices.$each.$iter[i].ageTieredGroups.$each.$iter[j]
          )
        }
      }
    },
    changePriceTieredBlur(ageTiredPerson) {
      const oldPrice = ageTiredPerson.price
      const value = Number(oldPrice) || 0
      ageTiredPerson.price = value.toFixed(2)
    },
    checkPriceError(data) {
      if (data.$anyError) {
        if ((!data.price.required || !data.price.minValue) && !this.isHaveMinPriceError) {
          this.isHaveMinPriceError = true
          this.priceError.push(
            parseTranslate(
              this.translations.suggestedRetailPriceGreaterError.text,
              MIN_PRICE
            )
          )
        } else if (!data.price.maxValue && !this.isHaveMaxPriceError) {
          this.isHaveMaxPriceError = true
          this.priceError.push(
            parseTranslate(
              this.translations.suggestedRetailPriceLessError.text,
              MAX_PRICE
            )
          )
        }
      }
    },
    checkAgeError(data) {
      if (data.$anyError) {
        if (
          !data.minAge.required ||
          !data.minAge.minValue ||
          !data.minAge.maxValue ||
          !data.maxAge.required ||
          !data.maxAge.minValue ||
          !data.maxAge.maxValue
        ) {
          if (!this.isHaveAgeError) {
            this.ageError.push(
              parseTranslate(this.translations.ageError.text, MIN_AGE, MAX_AGE)
            )
          }
        }
      }
    },
    back() {
      this.$emit("back", {
        prices: this.prices,
        tiered: this.tiered,
      })
    },
    next() {
      this.$v.$touch()
      this.priceError = []
      this.ageError = []
      this.isHaveMinPriceError = false
      this.isHaveMaxPriceError = false
      this.isHaveAgeError = false
      this.isHaveAgeIntervalError = false

      if (this.tiered) {
        for (let i = 0; i < this.prices.length; i++) {
          for (let j = 0; j < this.prices[i].ageTieredGroups.length; j++) {
            this.checkPriceError(
              this.$v.prices.$each.$iter[i].ageTieredGroups.$each.$iter[j]
            )
            this.checkAgeError(
              this.$v.prices.$each.$iter[i].ageTieredGroups.$each.$iter[j]
            )
          }
        }
      } else {
        for (let i = 0; i < this.prices.length; i++) {
          this.checkPriceError(this.$v.prices.$each.$iter[i])
        }
      }
      if (
        !this.isHaveMinPriceError &&
        !this.isHaveMaxPriceError &&
        !this.isHaveAgeError &&
        !this.isHaveAgeIntervalError
      ) {
        this.$emit("next", {
          prices: this.prices,
          tiered: this.tiered,
        })
      }
    },
  },
}
</script>
<style lang="scss">
.pricing-change-modal-person-page {
  &__title {
    margin-bottom: 17px;
    font-weight: bold;
  }

  &__tiered-pricing {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    color: $gray-three;

    .switcher {
      margin-right: 10px;
    }
  }

  &__block {
    padding: 25px 30px 32px 20px;
    background: #f9f9f9;
    border: 1px solid #ececec;
    border-radius: 4px;
    width: 100%;
  }

  &__age-item {
    display: flex;
    margin-bottom: 15px;

    &--checkbox {
      width: 120px;
      margin-top: 23px;
    }

    &--checkbox-first {
      width: 120px;
      margin-top: 44px;
    }

    &--checkbox-first-tiered {
      width: 120px;
      margin-top: 67px;
    }
  }

  &__price {
    position: relative;
    margin-left: 10px;
    margin-right: 20px;

    &--title {
      display: flex;
    }

    .v-input2 {
      &__passive {
        justify-content: end;
        right: 16px;
        top: 10px;
      }
    }

    .v-input {
      margin-top: 0;

      input {
        text-align: right;
        width: 160px;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  &__price-no-tried {
    .v-input {
      input {
        width: 240px;
      }
    }
  }

  &__price-min-max {
    &--input {
      display: flex;
      margin-top: 25px;
      width: 163px;
    }

    p {
      margin: 0px 7px;
    }

    .v-input {
      margin-top: -25px;

      input {
        width: 60px;
      }
    }
  }

  &__add-input {
    cursor: pointer;
    color: $primary;
    font-weight: bold;
  }

  &__del-cross {
    cursor: pointer;
    margin-right: -15px;
    margin-top: 13px;
  }

  &__get-paid {
    .question-mark__description {
      margin-left: -200px !important;
    }
  }
}
</style>
