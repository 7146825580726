var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.importantInfo && _vm.importantInfo !== ""
    ? _c(
        "div",
        { staticClass: "preview__section", attrs: { id: "important-info" } },
        [
          _c("div", { staticClass: "preview__title" }, [
            _vm._v("\n    " + _vm._s(_vm.translations.important.text) + "\n  "),
          ]),
          _vm._l(_vm.lines, function (line, key) {
            return _c("div", { key: key, staticClass: "preview__include" }, [
              _c("div", { staticClass: "preview__dot preview__dot--orange" }),
              _c("span", { staticClass: "preview__include-text" }, [
                _vm._v(_vm._s(line)),
              ]),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }