var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.noMembers
    ? _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "no-members" },
          [
            _c("div", { staticClass: "no-members__title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translations.contentNoMembers.text) +
                  "\n    "
              ),
            ]),
            _c(
              "div",
              { staticClass: "no-members__description" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.translations.noMembersDescription1.text) +
                    "\n      "
                ),
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/booking-details/pricing-type`
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translations.noMembersDescription2.text) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.translations.noMembersDescription3.text) +
                    " "
                ),
                _c("br"),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "no-members__description nav-description" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.translations.noMembersDescription4.text) +
                    "\n    "
                ),
              ]
            ),
            _c("v-btn", {
              staticClass: "no-members__button",
              attrs: { base: "", name: _vm.translations.closeBtn.text },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }