<template>
  <input v-model="proxyValue" class="input input2" :class="[setClass, { 'input--error': error }]" :type="type"
    :placeholder="placeholder" :readonly="readOnly" :max="max" :min="min" v-bind="$attrs" @focus="onFocus"
    @blur="onBlur" @input="$emit('input', $event.target.value)">
</template>

<script>
import { formatCurrency } from '~/utils/utils'


export default {
  name: 'InputFields',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number],
    placeholder: String,
    // Input type
    default: Boolean,
    disabledInput: Boolean,
    readOnly: Boolean,
    searchInput: Boolean,
    fullWidth: Boolean,
    max: String,
    min: String,
    error: { type: Boolean, default: false }
  },
  data() {
    return {
      isFocus: false
    }
  },
  computed: {
    setClass() {
      const typeInput = this.default
        ? 'default'
        : this.disabledInput
          ? 'disabledInput'
          : this.searchInput
            ? 'searchInput'
            : ''
      return 'input_' + typeInput
    },
    proxyValue: {
      get() {
        return this.isFocus ? this.value : this.displayNumber(this.value)
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
      setBlur(newValue) {
        newValue = false
        this.$emit('blur.native', newValue)
      }
    }
  },
  methods: {
    onFocus(ev) {
      this.isFocus = true
      this.$emit('focus', ev)
    },
    onBlur(ev) {
      this.addSumEnding()
      this.isFocus = false
      this.$emit('blur', ev)
    },
    addSumEnding() {
      const raw = this.value
      const number = Number(raw) || 0
      if (!number) return
      const mustBe = number.toFixed(2)
      if (raw !== mustBe) this.$emit('input', mustBe)
    },
    displayNumber(value) {
      if (!value) return value
      const number = Number(value)
      if (!number) return value
      const lang = this.$cookies.get("i18n_redirected")
      return formatCurrency(number, lang, 'none')
    },
  }
}
</script>

<style lang="scss"></style>
