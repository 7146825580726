<template>
	<div class="add-prices-pop-up">
		<div class="pop-up__close" @click="$emit('close')">
			<img src="@/assets/img/svg/close.svg" alt="close" />
		</div>
		<div class="add-prices-pop-up__title">
			{{ isEdit ? translations.titleEdit.text : translations.titleAdd.text }}
		</div>
		<ErrorBanner v-if="isSpacialOfferNow" :errors="[getInfoBannerText]" class="add-prices-pop-up__info-banner" />
		<div v-if="errors || isHaveMinMaxError" class="add-prices-pop-up__errors">
			<div class="add-prices-pop-up__errors--icon">
				<img src="@/assets/img/svg/error.svg" alt="info" />
			</div>
			<div class="add-prices-pop-up__errors--title">
				{{ translations.errorsTitle.text }}
			</div>
			<div v-for="(error, index) in errorsArray" :key="`error-${index}`" class="add-prices-pop-up__errors--error-type">
				<div class="dot dot_bold add-prices-pop-up__errors--dot_red" />
				<div class="add-prices-pop-up__errors--description">
					{{ error.text }}
				</div>
			</div>

			<div v-if="isHaveMinMaxError" class="error-type">
				<div class="dot dot_bold dot_red" />
				<div class="description">{{ translations.bookingInfo.text }}</div>
			</div>
		</div>
		<div class="add-prices-pop-up__pricing-title">
			{{ translations.contentPricing.text }}
		</div>
		<div v-if="!tour.is_group_pricing" class="add-prices-pop-up__tiered-pricing">
			<div class="switcher" @click="priceOptionLocal.tieredPricing = !priceOptionLocal.tieredPricing">
				<Checkbox switcher :checked="priceOptionLocal.tieredPricing" />
			</div>
			{{ translations.switcher.text }}
		</div>
		<!-- Price -->
		<div class="add-prices-pop-up__block">
			<ErrorBanner v-if="
				isHavePriceError ||
				isHaveMaxTravelersError ||
				isHaveMinPriceError ||
				isHaveMaxPriceError ||
				isHaveAgeError ||
				isHaveAgeIntervalError
			" :errors="errorList" class="pop-up-add-prices__error-banner pop-up-add-prices__error-banner--tiered" />
			<!-- Person price -->
			<div v-if="!tour.is_group_pricing && !priceOptionLocal.tieredPricing" class="price-option__members-table">
				<div class="price-option__members-row">
					<div class="price-option__members-cell"></div>
					<div class="price-option__members-cell">
						<div v-if="someMmeberIsActive" class="price-option__members-subrow">
							<div class="price-option__members-cell">
								{{ translations.pricingRetail.text }}
								<v-question-info :description="translations.pricingRetailQuestion.text" />
							</div>
							<div class="price-option__members-cell">
								{{ translations.pricingGetPaid.text }}
								<v-question-info class="add-prices-pop-up__get-paid" :description="translations.pricingGetPaidQuestion.text
									" />
							</div>
						</div>
					</div>
				</div>
				<div v-for="(age, index) in priceOptionLocal.members" :key="`age-${index}`" class="price-option__members-row">
					<div class="price-option__members-cell">
						<div @click="changeMember(index)">
							<Checkbox :id="`member-${index}`" default :name="age.age_category.name" :checked="age.is_active" />
						</div>
					</div>
					<div v-if="age.is_active" class="price-option__members-cell">
						<div class="price-option__members-subrow">
							<div class="price-option__members-cell">
								<v-input v-model="$v.priceOptionLocal.members.$each.$iter[index]
									.price.$model
									" :error="$v.priceOptionLocal.members.$each.$iter[index]
										.price.$error
										" type="number" onpaste="return false;" prepend-text="EUR" @input="changePrice(index)"
									@blur="blurPrice(index)" />
							</div>
							<div class="price-option__members-cell">
								<v-input :value="showPrice(priceOptionLocal.members[index].feePrice)" prepend-text="EUR" is-disabled />
							</div>
						</div>
					</div>
					<div v-else class="price-option__members-cell">
						<div class="price-option__member-description">
							{{ age.description }}
						</div>
					</div>
				</div>
			</div>
			<!-- Tiered price -->
			<div v-else-if="!tour.is_group_pricing" class="price-option__members-table">
				<div class="price-option__members-row price-option__members-row--tiered">
					<div class="price-option__members-cell"></div>
					<div class="price-option__members-cell price-option__members-cell--tiered">
						<div v-if="someMmeberIsActive" class="price-option__members-tiered-subrow">
							<div class="price-option__members-cell">
								{{ translations.pricingMinMax.text }}
							</div>
							<div class="price-option__members-cell">
								{{ translations.pricingRetail.text }}
								<v-question-info :description="translations.pricingRetailQuestion.text" />
							</div>
							<div class="price-option__members-cell">
								{{ translations.pricingGetPaid.text }}
								<v-question-info class="add-prices-pop-up__get-paid"
									:description="translations.pricingRetailQuestion.text" />
							</div>
						</div>
					</div>
				</div>
				<div v-for="(age, index) in priceOptionLocal.members" :key="`age-${index}`"
					class="price-option__members-row price-option__members-row--tiered">
					<div class="price-option__members-cell">
						<div @click="changeMember(index)">
							<Checkbox :id="`member-${index}`" default :name="age.age_category.name" :checked="age.is_active" />
						</div>
					</div>
					<div v-if="age.is_active" class="price-option__members-cell price-option__members-cell--tiered">
						<div v-for="(
								ageTieredGroup, ageTieredIndex
							) in age.ageTieredGroups" :key="`ageTieredGroup-${ageTieredIndex}`" class="price-option__members-tiered-subrow">
							<div class="price-option__members-cell">
								<v-input v-model="getTieredModel(index, ageTieredIndex).minAge
									.$model
									" :error="getTieredModel(index, ageTieredIndex).minAge
										.$error || ageTieredGroup.minAgeError
										" type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpaste="return false;"
									@input="checkMinAgeCount(index, ageTieredIndex)" />
								<span>-</span>
								<v-input v-model="getTieredModel(index, ageTieredIndex).maxAge
									.$model
									" :error="getTieredModel(index, ageTieredIndex).maxAge
										.$error || ageTieredGroup.maxAgeError
										" type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpaste="return false;"
									@input="checkMinAgeCount(index, ageTieredIndex)" />
							</div>
							<div class="price-option__members-cell">
								<v-input v-model="getTieredModel(index, ageTieredIndex).price.$model
									" :error="getTieredModel(index, ageTieredIndex).price.$error
										" type="number" prepend-text="EUR" onpaste="return false;" @input="changePriceTiered(index, ageTieredIndex)"
									@blur="blurPrice(index, ageTieredIndex)" />
							</div>
							<div class="price-option__members-cell">
								<v-input :value="showPrice(ageTieredGroup.feePrice)" prepend-text="EUR" is-disabled />
								<img v-if="ageTieredIndex != 0" src="@/assets/img/svg/del_cross.svg"
									class="add-prices-pop-up__del-price" @click="
										removeField(ageTieredIndex, age.ageTieredGroups)
										" />
							</div>
						</div>
						<div class="add-prices-pop-up__add-input" @click="addAgeGroupField(age, age.ageTieredGroups)">
							{{ translations.pricingAddTier.text }}
						</div>
					</div>
					<div v-else class="price-option__members-cell price-option__members-cell--tiered">
						<div class="price-option__member-description">
							{{ age.description }}
						</div>
					</div>
				</div>
			</div>
			<!-- Group price -->
			<div v-else class="price-option__members-table">
				<div class="price-option__members-row price-option__members-row--group">
					<div class="price-option__members-cell">
						{{ translations.maxTravelersPerBooking.text }}
					</div>
					<div class="price-option__members-cell">
						{{ translations.pricingRetail.text }}
						<v-question-info :description="translations.retailPriceQuestionMark.text" />
					</div>
					<div class="price-option__members-cell">
						{{ translations.pricingGetPaid.text }}
						<v-question-info class="add-prices-pop-up__get-paid" :description="translations.getPaidQuestionMark.text" />
					</div>
				</div>
				<div class="price-option__members-row price-option__members-row--group">
					<div class="price-option__members-cell">
						<v-input v-model="priceOptionLocal.members[0].groupCount" :error="groupPriceCountError"
							class="input-group input-group_count" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
							type="number" @blur="blurMaxTravelers" />
					</div>
					<div class="price-option__members-cell">
						<v-input v-model="$v.priceOptionLocal.members.$each.$iter[0].price.$model
							" :error="$v.priceOptionLocal.members.$each.$iter[0].price.$error
								" type="number" prepend-text="EUR" @input="changePrice(0)" @blur="blurPrice(0)" />
					</div>
					<div class="price-option__members-cell">
						<v-input :value="showPrice(priceOptionLocal.members[0].feePrice)" prepend-text="EUR" is-disabled />
					</div>
				</div>
			</div>
		</div>
		<!-- Days -->
		<div class="add-prices-pop-up__apply-days">
			<div class="add-prices-pop-up__apply-days--title">
				{{ translations.contentDaysApply.text }}
			</div>
			<div @click="selectAllDays">
				<Checkbox id="select-all" default :name="translations.daysApplySelectAll.text"
					:checked="priceOptionLocal.allDaysChecked" />
			</div>
			<div class="add-prices-pop-up__apply-days-content">
				<div v-for="(day, index) in priceOptionLocal.days" :key="`day-${index}`"
					class="add-prices-pop-up__apply-days-content--item" @click="changeDate(index)">
					<Checkbox :id="`day-${index}`" button :name="day.name" :checked="day.is_active" />
				</div>
			</div>
		</div>
		<!-- Apply time -->
		<div v-if="priceOptionLocal.addTimes" class="add-prices-pop-up__apply-time">
			<div class="add-prices-pop-up__apply-time--title">
				{{ translations.contentTimeApply.text }}
			</div>
			<div class="add-prices-pop-up__apply-time--note">
				{{ translations.timeApplyNote.text }}
			</div>
			<div class="row">
				<div v-for="(time, index) in priceOptionLocal.anotherTimes" :key="`time-interval-${index}`"
					class="add-prices-pop-up__apply-time-item row">
					<date-picker v-model="time.time" type="time" :lang="lang" :time-picker-options="timePickerOptions"
						:format="timeFormat" :placeholder="translations.timeApplyPlaceholder.text" class="custom-detepicker"
						:editable="false" @change="newValueApplyTime(index)" />
					<div v-if="index != 0" class="add-prices-pop-up__apply-time-item--delete"
						@click="removeField(index, priceOptionLocal.anotherTimes)">
						<img src="@/assets/img/svg/del_cross.svg" alt="del_cross" />
					</div>
					<div v-if="time.error" class="add-prices-pop-up__apply-time-item--error">
						{{ translations.timeApplyError.text }}
					</div>
				</div>
			</div>
			<span v-if="priceOptionLocal.anotherTimes.length < 48" class="add-prices-pop-up__add-time"
				@click="addAnotherTimeField(priceOptionLocal.anotherTimes)">
				{{ translations.timeApplyAdd.text }}
			</span>
		</div>
		<!-- Opening time -->
		<div v-if="priceOptionLocal.openingHours" class="add-prices-pop-up__opening-time">
			<div class="add-prices-pop-up__opening-time--title">
				{{ translations.contentTimeApply.text }}
			</div>
			<div v-for="(
					openingScheduleTime, index
				) in priceOptionLocal.openingScheduleTimes" :key="`openingScheduleTime-${index}`"
				class="add-prices-pop-up__opening-time--edit-expand">
				<div class="add-prices-pop-up__edit-expand--item">
					<date-picker v-model="openingScheduleTime.startTime" type="time" :lang="lang"
						:time-picker-options="timePickerOptions" :format="timeFormat" :show-second="false" class="custom-detepicker"
						:placeholder="translations.openingTimePlaceholderFrom.text" :editable="false" />
				</div>
				<div class="edit-expand__item">
					<div class="dash">-</div>
				</div>
				<div class="edit-expand__item">
					<date-picker v-model="openingScheduleTime.endTime" type="time" :lang="lang"
						:time-picker-options="timePickerOptions" :format="timeFormat" :show-second="false" class="custom-detepicker"
						:placeholder="translations.openingTimePlaceholderTo.text" :editable="false" />
				</div>
				<img v-if="index != 0" src="@/assets/img/svg/del_cross.svg"
					class="add-prices-pop-up__del-opening-schedule-times"
					@click="removeField(index, priceOptionLocal.openingScheduleTimes)" />
			</div>
			<span v-if="false" class="add-prices-pop-up__add-time" @click="addField(priceOptionLocal.openingScheduleTimes)">
				{{ translations.timeApplyAdd.text }}
			</span>
		</div>
		<div v-if="spacialOfferError" class="add-prices-pop-up__spacial-offer-error">
			{{ translations.pricesCannotBeSaved.text }}
		</div>
		<div class="add-prices-pop-up__nav">
			<v-btn outline :name="translations.backBtn.text" @click="$emit('back')" />
			<div v-if="step > 0" class="pagination row">
				<div v-if="step == 3" class="dot dot_page" />
				<div class="dot dot_page" />
				<div class="dot dot_page dot_page_active" />
			</div>
			<v-btn base :name="translations.saveBtn.text" :disabled="isSpacialOfferNow" @click="save" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import VBtn from '@/components/controls/VBtn.vue'
import VInput from '@/components/controls/VInput.vue'
import Checkbox from '@/components/controls/Checkbox.vue'
import ErrorBanner from '@/components/ErrorBanner.vue'
import VQuestionInfo from '@/components/common/VQuestionInfo.vue'

import { format, getDayNames } from '@/utils/date'
import { checkAge, isAdultOrSenior, getAgeTieredGroupsDefault, getPriceFee, FREE_CHILDREN_CATEGORIES } from '@/utils/price'
import { parseTranslate } from '@/utils/translations'

import { formatCurrency, formatTime } from '@/utils/utils'

const MIN_PRICE = 2.99
const MAX_PRICE = 99999999999999
const MIN_TRAVELERS = 1
const MAX_TRAVELERS = 9999
const DAYS_30 = 30 * 86400 * 1000
const MIN_AGE = 1
const MAX_AGE = 99

export default {
	components: {
		VBtn,
		Checkbox,
		DatePicker,
		VInput,
		ErrorBanner,
		VQuestionInfo,
	},
	props: {
		isEdit: { type: Boolean, default: false },
		tour: { type: Object, default: () => ({}) },
		translations: { type: Object, default: () => ({}) },
		priceOption: { type: Object, default: () => ({}) },
		step: { type: Number, default: 0 },
	},
	data() {
		return {
			priceOptionLocal: {
				members: [],
				openingHours: false,
				addTimes: false,
				anotherTimes: [{ time: null, is_active: false }],
				openingScheduleTimes: [{ startTime: null, endTime: null }],
				days: [
					{ name: 'Mon', day: '1', is_active: false },
					{ name: 'Tue', day: '2', is_active: false },
					{ name: 'Wed', day: '3', is_active: false },
					{ name: 'Thu', day: '4', is_active: false },
					{ name: 'Fri', day: '5', is_active: false },
					{ name: 'Sat', day: '6', is_active: false },
					{ name: 'Sun', day: '7', is_active: false },
				],
				activeDaysCount: 0,
				allDaysChecked: false,
				tieredPricing: false,
			},
			errors: false,
			errorsArray: [],
			isHaveMinMaxError: false,
			timePickerOptions: {
				start: '00:00',
				step: '00:30',
				end: '23:30',
			},
			groupPriceCountError: false,
			isHavePriceError: false,
			priceError: [],
			isHaveMaxTravelersError: false,
			maxTravelersError: [],
			isHaveMinPriceError: false,
			isHaveMaxPriceError: false,
			isHaveAgeError: false,
			isHaveAgeIntervalError: false,
			ageError: [],
			spacialOfferError: false,
			specialOfferMinPriceError: false,
			lang: '',
		}
	},
	fetch() {
		this.lang = this.$cookies.get('i18n_redirected')
		// WARN! Не трогать, попытка исправить это приведёт к непредсказуемым багам
		this.priceOptionLocal = this.priceOption
		const dayNames = getDayNames(this.lang)
		this.priceOptionLocal.days.forEach((day, idx) => { day.name = dayNames[idx] })
	},
	computed: {
		...mapState(['userInfo']),
		minPriceError() {
			if (this.$v.priceOptionLocal.members.$anyError) {
				return parseTranslate(
					this.translations.suggestedRetailPriceGreaterError.text,
					MIN_PRICE
				)
			}
			return ''
		},
		isSpacialOfferNow() {
			const dateNow = new Date()
			dateNow.setHours(0, 0, 0, 0)
			return dateNow <= new Date(this.tour.discount_info[0]?.discount_end_date)
		},
		getInfoBannerText() {
			const strartDateFormatted = format(
				this.tour.discount_info[0]?.discount_start_date,
				'do MMMM yyy',
				this.lang
			)
			const endDateFormatted = format(
				this.tour.discount_info[0]?.discount_end_date,
				'do MMMM yyy',
				this.lang
			)

			return parseTranslate(
				this.translations.errorIntroOffer.text,
				strartDateFormatted,
				endDateFormatted
			)
		},
		errorList() {
			return [...this.priceError, ...this.maxTravelersError, ...this.ageError]
		},

		someMmeberIsActive() {
			return this.priceOptionLocal.members.some((member) => member.is_active)
		},
		timeFormat() {
			return formatTime({ lang: this.lang })
		},
	},
	validations() {
		if (this.priceOptionLocal.tieredPricing) {
			return {
				priceOptionLocal: {
					members: {
						$each: {
							ageTieredGroups: {
								$each: {
									price: {
										required: requiredIf(
											(field) =>
												this.getAgeCategoryById(
													field.ageCategoryId
												).is_active &&
												this.checkAdultsOrSeniorById(
													field.ageCategoryId
												)
										),
										// Данные поля отвечают за возрастные группы с включенным Tiered pricing
										minValue: (value, field) => {
											const group = this.getAgeCategoryById(field.ageCategoryId)
											if (!group.is_active) return true
											if (FREE_CHILDREN_CATEGORIES.has(field.ageCategoryId)
												&& Number(value) === 0) return true
											return minValue(MIN_PRICE)(Number(value))
										},
										maxValue: (value, field) =>
											this.getAgeCategoryById(field.ageCategoryId)
												.is_active
												? maxValue(MAX_PRICE)(Number(value))
												: true,
									},
									minAge: {
										required: requiredIf(
											(field) =>
												this.getAgeCategoryById(
													field.ageCategoryId
												).is_active &&
												this.checkAdultsOrSeniorById(
													field.ageCategoryId
												)
										),
										minValue: (value, field) =>
											this.getAgeCategoryById(field.ageCategoryId)
												.is_active
												? minValue(MIN_AGE)(Number(value))
												: true,
										maxValue: (value, field) =>
											this.getAgeCategoryById(field.ageCategoryId)
												.is_active
												? maxValue(MAX_AGE)(Number(value))
												: true,
									},
									maxAge: {
										required: requiredIf(
											(field) =>
												this.getAgeCategoryById(
													field.ageCategoryId
												).is_active &&
												this.checkAdultsOrSeniorById(
													field.ageCategoryId
												)
										),
										minValue: (value, field) =>
											this.getAgeCategoryById(field.ageCategoryId)
												.is_active
												? minValue(MIN_AGE)(Number(value))
												: true,
										maxValue: (value, field) =>
											this.getAgeCategoryById(field.ageCategoryId)
												.is_active
												? maxValue(MAX_AGE)(Number(value))
												: true,
									},
								},
							},
						},
					},
				},
			}
		}
		if (this.tour.is_group_pricing) {
			return {
				priceOptionLocal: {
					members: {
						$each: {
							groupCount: {
								required,
								minValue: (value) =>
									minValue(MIN_TRAVELERS)(Number(value)),
								maxValue: (value) =>
									maxValue(MAX_TRAVELERS)(Number(value)),
							},
							price: {
								required,
								minValue: (value) => minValue(MIN_PRICE)(Number(value)),
								maxValue: (value) => maxValue(MAX_PRICE)(Number(value)),
							},
						},
					},
				},
			}
		}
		return {
			// Данные поля отвечают за возрастные группы без Tired pricing
			priceOptionLocal: {
				members: {
					$each: {
						price: {
							required: requiredIf(
								(field) => field.is_active && this.isAdultOrSenior(field)
							),
							minValue: (field, member) => {
								if (!member.is_active) return true
								if (FREE_CHILDREN_CATEGORIES.has(member.age_category.id)
									&& Number(field) === 0) return true
								return minValue(MIN_PRICE)(Number(field))
							},
							maxValue: (field, member) =>
								member.is_active
									? maxValue(MAX_PRICE)(Number(field))
									: true,
						},
					},
				},
			},
		}
	},

	methods: {
		isAdultOrSenior,

		getTieredModel(ageIndex, countIndex) {
			return this.$v.priceOptionLocal.members.$each.$iter[ageIndex].ageTieredGroups
				.$each.$iter[countIndex]
		},

		showPrice(number) {
			if (number == null || number === undefined) return number
			return Number(number).toLocaleString(undefined, { maximumFractionDigits: 2 })
		},

		checkAdultsOrSeniorById(id) {
			const member = this.getAgeCategoryById(id)

			return this.isAdultOrSenior(member)
		},

		getAgeCategoryById(id) {
			return this.priceOptionLocal.members.find(
				(member) => member.age_category.id === id
			)
		},
		selectAllDays() {
			const selectAll = document.getElementById('select-all')
			if (selectAll.checked) {
				for (const index in this.priceOptionLocal.days) {
					document.getElementById(`day-${index}`).checked = true
					this.priceOptionLocal.days[index].is_active = true
				}
			} else {
				for (const index in this.priceOptionLocal.days) {
					document.getElementById(`day-${index}`).checked = false
					this.priceOptionLocal.days[index].is_active = false
				}
			}
		},
		changeDate(index) {
			this.priceOptionLocal.days[index].is_active = document.getElementById(
				`day-${index}`
			).checked

			this.priceOptionLocal.activeDaysCount = 0
			for (const i in this.priceOptionLocal.days) {
				if (this.priceOptionLocal.days[i].is_active) {
					this.priceOptionLocal.activeDaysCount += 1
				}
			}
			this.priceOptionLocal.allDaysChecked =
				this.priceOptionLocal.activeDaysCount === 7
		},
		changePriceTiered(index, tieredIndex) {
			const newFee = getPriceFee(
				this.priceOptionLocal.members[index].ageTieredGroups[tieredIndex].price,
				this.userInfo.commission_amount
			)
			this.priceOptionLocal.members[index].ageTieredGroups[
				tieredIndex
			].feePrice = newFee
			this.priceError = []
			this.isHavePriceError = false
			this.isHaveMinPriceError = false
			this.isHaveMaxPriceError = false
			for (let i = 0; i < this.priceOptionLocal.members.length; i++) {
				if (this.priceOptionLocal.members[i].is_active) {
					for (
						let j = 0;
						j < this.priceOptionLocal.members[i].ageTieredGroups.length;
						j++
					) {
						this.checkPriceError(
							this.$v.priceOptionLocal.members.$each.$iter[i]
								.ageTieredGroups.$each.$iter[j]
						)
					}
				}
			}
		},
		changeMember(index) {
			this.priceOptionLocal.members = this.priceOptionLocal.members.map(
				(member, memberIndex) => {
					if (index === memberIndex) {
						return {
							...member,
							is_active: document.getElementById(`member-${index}`).checked,
						}
					}

					return member
				}
			)
		},
		blurMaxTravelers() {
			const oldValue = this.priceOptionLocal.members[0].groupCount
			if (!this.tour.is_private && oldValue > 0) {
				this.priceOptionLocal.members[0].groupCount = Math.min(oldValue, this.tour.max_travelers)
			}
		},
		blurPrice(index, groupIndex = undefined) {
			const field = typeof groupIndex === 'number'
				? this.priceOptionLocal.members[index].ageTieredGroups[groupIndex]
				: this.priceOptionLocal.members[index]
			const rawValue = field.price
			const value = Number(rawValue) || 0
			field.price = value.toFixed(2)
		},
		changePrice(index) {
			const newFee = getPriceFee(
				this.priceOptionLocal.members[index].price,
				this.userInfo.commission_amount
			)
			this.priceOptionLocal.members[index].feePrice = newFee
			this.priceError = []
			this.isHavePriceError = false
			this.isHaveMinPriceError = false
			this.isHaveMaxPriceError = false
			if (this.tour.is_group_pricing) this.checkRetailPrice()
			else {
				for (let i = 0; i < this.priceOptionLocal.members.length; i++) {
					if (this.priceOptionLocal.members[i].is_active) {
						this.checkPriceError(
							this.$v.priceOptionLocal.members.$each.$iter[i]
						)
					}
				}
			}
		},
		removeField(index, fieldType) {
			fieldType.splice(index, 1)
		},
		addAnotherTimeField(fieldType) {
			fieldType.push({ time: null, is_active: false })
		},
		addAgeGroupField(age, fieldType) {
			fieldType.push(getAgeTieredGroupsDefault(age.age_category.id))
		},
		newValueApplyTime(index) {
			const checkedValue = new Date(
				this.priceOptionLocal.anotherTimes[index].time
			).toLocaleTimeString()
			for (const i in this.priceOptionLocal.anotherTimes) {
				const checkedAnotherTime = new Date(
					this.priceOptionLocal.anotherTimes[i].time
				).toLocaleTimeString()
				if (
					checkedAnotherTime != null &&
					checkedAnotherTime !== '' &&
					checkedValue != null &&
					checkedValue !== '' &&
					index !== +i
				) {
					if (checkedAnotherTime === checkedValue) {
						this.priceOptionLocal.anotherTimes[index].time = null
						this.priceOptionLocal.anotherTimes[index].error = true
						break
					} else {
						this.priceOptionLocal.anotherTimes[index].error = false
					}
				}
			}
		},
		addField(fieldType) {
			fieldType.push({ startTime: null, endTime: null })
			console.log('Field added:', fieldType)
		},
		checkMinAgeCount(index, tieredIndex) {
			this.ageError = []
			this.isHaveAgeError = false
			this.isHaveAgeIntervalError = false
			const res = checkAge(
				this.priceOptionLocal.members[index].ageTieredGroups,
				tieredIndex
			)
			this.priceOptionLocal.members[index].ageTieredGroups[
				tieredIndex
			].minAgeError = res.minAgeError
			this.priceOptionLocal.members[index].ageTieredGroups[
				tieredIndex
			].maxAgeError = res.maxAgeError

			if (res.minAgeError || res.maxAgeError) {
				this.isHaveAgeIntervalError = true
				this.ageError.push(this.translations.bookingInfo.text)
			}

			for (let i = 0; i < this.priceOptionLocal.members.length; i++) {
				if (this.priceOptionLocal.members[i].is_active) {
					for (
						let j = 0;
						j < this.priceOptionLocal.members[i].ageTieredGroups.length;
						j++
					) {
						this.checkAgeError(
							this.$v.priceOptionLocal.members.$each.$iter[i]
								.ageTieredGroups.$each.$iter[j]
						)
					}
				}
			}
		},
		checkSpacialOffer(member) {
			const timeHasPassedSinceEndDiscount =
				new Date().getTime() -
				new Date(this.tour.discount_info[0]?.discount_end_date).getTime()
			this.specialOfferMinPriceError =
				timeHasPassedSinceEndDiscount < DAYS_30 &&
				Number(member.price) < Number(this.tour.price_without_discount)

			return this.specialOfferMinPriceError
		},
		checkRetailPrice() {
			this.priceError = []
			this.isHavePriceError = false

			if (this.$v.priceOptionLocal.members.$each.$iter[0].price.$anyError) {
				this.isHavePriceError = true
				if (
					!this.$v.priceOptionLocal.members.$each.$iter[0].price.required ||
					!this.$v.priceOptionLocal.members.$each.$iter[0].price.minValue
				)
					this.priceError.push(
						parseTranslate(
							this.translations.suggestedRetailPriceGreaterError.text,
							MIN_PRICE
						)
					)
				else if (!this.$v.priceOptionLocal.members.$each.$iter[0].price.maxValue)
					this.priceError.push(
						parseTranslate(
							this.translations.suggestedRetailPriceLessError.text,
							MAX_PRICE
						)
					)
			}
		},
		checkMaxTravelersError() {
			this.maxTravelersError = []
			this.isHaveMaxTravelersError = false

			if (this.$v.priceOptionLocal.members.$each.$iter[0].groupCount.$anyError) {
				this.isHaveMaxTravelersError = true
				if (
					!this.$v.priceOptionLocal.members.$each.$iter[0].groupCount
						.required ||
					!this.$v.priceOptionLocal.members.$each.$iter[0].groupCount.minValue
				)
					this.maxTravelersError.push(
						parseTranslate(
							this.translations.minimumNumberTravelersMinError.text,
							MIN_TRAVELERS
						)
					)
				else if (
					!this.$v.priceOptionLocal.members.$each.$iter[0].groupCount.maxValue
				)
					this.maxTravelersError.push(
						parseTranslate(
							this.translations.numberTravelersMaxError.text,
							MAX_TRAVELERS
						)
					)
			}
		},
		checkPriceError(data) {
			if (data.$anyError) {
				if (
					(!data.price.required || !data.price.minValue) &&
					!this.isHaveMinPriceError
				) {
					this.isHaveMinPriceError = true
					this.priceError.push(
						parseTranslate(
							this.translations.suggestedRetailPriceGreaterError.text,
							MIN_PRICE
						)
					)
				} else if (!data.price.maxValue && !this.isHaveMaxPriceError) {
					this.isHaveMaxPriceError = true
					this.priceError.push(
						parseTranslate(
							this.translations.suggestedRetailPriceLessError.text,
							MAX_PRICE
						)
					)
				}
			}
		},
		checkAgeError(data) {
			if (data.$anyError) {
				if (
					!data.minAge.required ||
					!data.minAge.minValue ||
					!data.minAge.maxValue ||
					!data.maxAge.required ||
					!data.maxAge.minValue ||
					!data.maxAge.maxValue
				) {
					if (!this.isHaveAgeError) {
						this.isHaveAgeError = true
						this.ageError.push(
							parseTranslate(
								this.translations.ageError.text,
								MIN_AGE,
								MAX_AGE
							)
						)
					}
				}
			}
		},
		save() {
			this.$v.$touch()
			let haveActiveMember = false
			let haveActiveDays = false
			let haveError = false
			this.errorsArray = []

			this.priceError = []
			this.ageError = []
			this.isHaveMinPriceError = false
			this.isHaveMaxPriceError = false
			this.isHaveAgeError = false
			this.isHaveAgeIntervalError = false
			this.specialOfferMinPriceError = false

			if (this.tour.is_group_pricing) {
				haveActiveMember = this.priceOptionLocal.members[0].is_active = true
				this.checkRetailPrice()
				this.checkMaxTravelersError()
				if (this.isEdit) {
					this.checkSpacialOffer(this.priceOptionLocal.members[0])
				}
				haveError = this.isHaveMaxTravelersError || this.isHavePriceError
			} else {
				for (let i = 0; i < this.priceOptionLocal.members.length; i++) {
					if (this.priceOptionLocal.members[i].is_active) {
						haveActiveMember = true
						if (this.priceOptionLocal.tieredPricing) {
							for (
								let j = 0;
								j <
								this.priceOptionLocal.members[i].ageTieredGroups.length;
								j++
							) {
								if (this.isEdit) {
									this.checkSpacialOffer(
										this.priceOptionLocal.members[i].ageTieredGroups[
										j
										]
									)
								}
								this.checkPriceError(
									this.$v.priceOptionLocal.members.$each.$iter[i]
										.ageTieredGroups.$each.$iter[j]
								)
								this.checkAgeError(
									this.$v.priceOptionLocal.members.$each.$iter[i]
										.ageTieredGroups.$each.$iter[j]
								)
							}
						} else {
							if (this.isEdit) {
								this.checkSpacialOffer(this.priceOptionLocal.members[i])
							}
							this.checkPriceError(
								this.$v.priceOptionLocal.members.$each.$iter[i]
							)
						}
					}
				}
			}
			for (const i in this.priceOptionLocal.days) {
				if (this.priceOptionLocal.days[i].is_active) {
					haveActiveDays = true
					break
				}
			}
			if (this.priceOptionLocal.openingHours) {
				for (const i in this.priceOptionLocal.openingScheduleTimes) {
					if (
						this.priceOptionLocal.openingScheduleTimes[i].startTime == null ||
						this.priceOptionLocal.openingScheduleTimes[i].startTime === '' ||
						this.priceOptionLocal.openingScheduleTimes[i].endTime == null ||
						this.priceOptionLocal.openingScheduleTimes[i].endTime === ''
					) {
						haveError = true
						break
					}
				}
			}
			if (this.priceOptionLocal.addTimes) {
				for (const i in this.priceOptionLocal.anotherTimes) {
					if (
						this.priceOptionLocal.anotherTimes[i].time == null ||
						this.priceOptionLocal.anotherTimes[i].time === ''
					) {
						haveError = true
						break
					}
				}
			}

			if (
				!this.$v.$anyError &&
				haveActiveMember &&
				haveActiveDays &&
				!haveError &&
				(this.step > 0 || !this.isSpacialOfferNow) &&
				!this.specialOfferMinPriceError
			) {
				this.$emit('save', this.priceOptionLocal)
			} else {
				this.errors = true
				if (!haveActiveMember) {
					this.errorsArray.push({ text: this.translations.error1.text })
				}
				if (!haveActiveDays) {
					this.errorsArray.push({ text: this.translations.error4.text })
				}
				if (haveError) {
					this.errorsArray.push({ text: this.translations.error5.text })
				}
				if (this.isSpacialOfferNow) {
					this.spacialOfferError = true
					this.errors = false
				}
				if (this.specialOfferMinPriceError) {
					this.errorsArray.push({
						text:
							this.translations.pricesCannotBeLowerThanPrediscount.text +
							this.tour.price_without_discount,
					})
				}
			}
		},
	},
}
</script>
<style lang="scss">
.add-prices-pop-up {
	&__title {
		margin-bottom: 23px;
		font-weight: bold;
		font-size: 32px;
		line-height: 48px;
	}

	&__pricing-title {
		margin-bottom: 17px;
		font-weight: bold;
	}

	&__block {
		padding: 34px 30px 32px 21px;
		background: #f9f9f9;
		border: 1px solid #ececec;
		border-radius: 4px;
		width: 100%;
		margin-bottom: 47px;
	}

	&__info-banner {
		background-color: white;
		border-color: $orange;
		margin-bottom: 40px;
		color: $orange;

		svg {
			min-width: 27px;
		}

		.error-banner {
			&__item {
				&:before {
					content: none !important;
				}
			}
		}
	}

	&__tiered-pricing {
		margin-bottom: 17px;
		display: flex;
		align-items: center;
		color: $gray-three;

		.switcher {
			margin-right: 10px;
		}
	}

	&__spacial-offer-error {
		margin-top: -50px;
		color: $red;
		margin-bottom: 20px;
	}

	&__get-paid {
		.question-mark__description {
			margin-left: -200px;
		}
	}

	&__apply-days {
		margin-bottom: 43px;

		&--title {
			margin-bottom: 8px;
			font-weight: bold;
		}
	}

	&__apply-days-content {
		display: flex;
		margin-top: 20px;

		&--item {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}

		.inactive-date {
			background: $gray !important;
		}
	}

	&__apply-time {
		&--title {
			margin-bottom: 2px;
			font-weight: bold;
		}

		&--note {
			margin-bottom: 7px;
		}
	}

	&__apply-time-item {
		position: relative;
		margin-bottom: 10px;
		margin-right: 10px;

		&--delete {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			margin-left: -27px;
			width: 22px;
			height: 22px;
			background: $white;
		}

		&--error {
			z-index: 4;
			position: absolute;
			top: -65px;
			left: 0;
			background: $white;
			padding: 8px;
			min-width: 170px;
			border-radius: 10px;
			font-size: 15px;
			border: 1px solid $red;
			box-shadow: 0px 2px 8px rgba(30, 40, 67, 0.1);
		}
	}

	&__opening-time {
		&--title {
			margin-bottom: 20px;
			font-weight: bold;
		}

		&--edit-expand {
			display: flex;
			align-items: center;

			&--item {
				&:not(:last-child) {
					margin-right: 8px;
				}
			}

			margin-bottom: 15px;
		}
	}

	&__del_cross {
		cursor: pointer;
		margin-left: 5px;
	}

	&__del-price {
		cursor: pointer;
		margin-right: -15px;
		margin-left: 5px;
	}

	&__del-opening-schedule-times {
		margin-left: 10px;
		cursor: pointer;
	}

	&__add-input {
		cursor: pointer;
		color: $primary;
		font-weight: bold;
	}

	&__add-time {
		display: flex;
		align-items: center;
		margin-top: 10px;
		cursor: pointer;
		font-weight: bold;
		color: $primary;
		margin-bottom: 84px;
	}

	&__nav {
		border-top: 1px solid #ececec;
		padding-top: 23px;
		display: flex;
		justify-content: space-between;

		.v-btn {
			width: 150px;
			min-width: 150px;
		}
	}

	.custom-detepicker {
		width: 110px;
		z-index: 1;
	}

	&__errors {
		margin-bottom: 17px;
		padding: 23px 18px 18px 67px;
		width: 750px;
		position: relative;
		border: 2px solid #d80007;
		border-radius: 14px;

		&--icon {
			transform: rotate(180deg);
			position: absolute;
			left: 21px;
			top: 18px;
			width: 32px;
			height: 32px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&--title {
			margin-bottom: 10px;
			font-weight: bold;
		}

		&--description {
			width: 675px;
		}

		&--error-type {
			display: flex;
			align-items: flex-start;

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}

		&--dot_red {
			margin-top: 6px;
			background: #d80007;
		}
	}

	.error-banner {
		margin-bottom: 15px;
		margin-top: 0px;
	}
}

.price-option {
	&__members-row {
		display: grid;
		grid-template-columns: 100px auto;
		gap: 20px;

		.v-input {
			margin-top: 0 !important;
			width: 100%;

			input {
				margin-top: 0 !important;
			}
		}

		.v-input__prepend-text {
			margin-top: 0 !important;
		}

		&:not(:first-child) {
			margin-bottom: 20px;
		}

		&--group {
			grid-template-columns: 235px 235px 235px;
		}
	}

	&__members-subrow {
		display: grid;
		grid-template-columns: 300px 300px;
		gap: 20px;
	}

	&__members-tiered-subrow {
		display: grid;
		grid-template-columns: 140px 225px 225px;
		gap: 20px;
	}

	&__member-description {
		width: 100%;
		font-size: 12px;
		line-height: 14px;
		color: #888a93;
	}

	&__members-cell {
		display: flex;
		align-items: center;
		height: 48px;

		&--tiered {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			height: auto;
			gap: 20px;
		}
	}
}
</style>
