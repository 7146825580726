import localeES from 'vue2-datepicker/locale/es';
import localeEN from 'vue2-datepicker/locale/en';

export function translatePhoneInput() {
  const raw = this.$store.state.commonTranslate?.vuePhoneNumberInput?.text ?? '';
  if (raw.length < 1) return undefined;
  try {
    return JSON.parse(raw);
  } catch (ex) {
    console.warn('Invalid JSON for VuePhoneInputNumber', ex);
    return undefined;
  }
}

export function calendarExtra() {
  const lang = this.$cookies.get("i18n_redirected");
  const locales = {en: localeEN, es: localeES};
  const locale = {...(locales[lang] ?? localeEN)};
  if (!locale.formatLocale) locale.formatLocale = {};
  locale.formatLocale.firstDayOfWeek = 1;
  return {lang: locale, format: 'DD.MM.YYYY'};
}