
// Настройки обрезания ваучера
// В бумажной версии название тура не может быть длиннее 25 символов, чтобы не поломалась вёрстка

/*
const settings = {
  tourName: 25,
  userFirstName: 25,
  userLastName: 25
}
*/

export const cutVaucher = (_, value) => {
  return value
  // const len = settings[type];
  // if (!len) throw new Error('Unknown cut setting!');    
  // return value.substring(0, len);
}