var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "attractions-card" }, [
    _c("div", { staticClass: "attractions-card__number" }, [
      _vm._v("\n    " + _vm._s(_vm.index + 1) + ".\n  "),
    ]),
    _c("div", { staticClass: "attractions-card__content" }, [
      _c("div", { staticClass: "attractions-card__header" }, [
        _c("div", { staticClass: "attractions-card__address" }, [
          _vm._v("\n        " + _vm._s(_vm.address) + "\n      "),
        ]),
        _c("div", { staticClass: "attractions-card__actions" }, [
          _c(
            "button",
            {
              staticClass: "attractions-card__actions-button",
              class: {
                "attractions-card__actions-button--disabled": _vm.disabledDown,
              },
              attrs: { disabled: _vm.disabledDown },
              on: { click: _vm.moveDown },
            },
            [
              _c("IconChevronDoubleDown", {
                staticClass: "attractions-card__actions-down",
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "attractions-card__actions-button",
              class: {
                "attractions-card__actions-button--disabled": _vm.disabledUp,
              },
              attrs: { disbled: _vm.disabledUp },
              on: { click: _vm.moveUp },
            },
            [
              _c("IconChevronDoubleDown", {
                staticClass: "attractions-card__actions-up",
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "attractions-card__actions-button",
              on: { click: _vm.edit },
            },
            [_c("IconEdit", { staticClass: "attractions-card__actions-edit" })],
            1
          ),
          _c(
            "button",
            {
              staticClass: "attractions-card__actions-button",
              on: { click: _vm.remove },
            },
            [
              _c("IconTrash", {
                staticClass: "attractions-card__actions-trash",
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "attractions-card__description" }, [
        _vm._v("\n      " + _vm._s(_vm.description) + "\n    "),
      ]),
      _c("span", { staticClass: "attraction-card__fiiter" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.durationTime) +
            " " +
            _vm._s(_vm.durationTranslates[_vm.durationTimeUnit]) +
            ", " +
            _vm._s(_vm.ticketRedemption) +
            _vm._s(
              _vm.isSkipLine ? `, ${_vm.translates.skipTheLineInfo.text}` : ""
            ) +
            "\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }