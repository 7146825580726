import { format as _format } from 'date-fns'
import { locales, twelveHourLangs } from '@/constants/locales'

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}
export function formatDate(date, locale) {
  return new Date(date).toLocaleDateString(locale, options)
}

export function formatDateMonthDayYear(date, locale) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return new Date(date).toLocaleDateString(locale, options)
}

export function formatTimeLocale(date, locale = 'en', includeWeekday = true, onlyNumericDate = false) {
  const formattedLocale = locale === 'en' ? 'en-GB' : locale
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: twelveHourLangs.includes(locale), // проверка для 12-часового формата
  }

  if (includeWeekday) {
    options.weekday = 'long' // добавляем день недели, если аргумент true
  }

  // Если нужно отобразить только числовую дату
  if (onlyNumericDate) {
    return new Date(date).toLocaleDateString(formattedLocale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  return new Date(date).toLocaleString(formattedLocale, options)
}

export function format(date, formatString = 'MMM d, YYYY', options, lang = 'en') {
  const locale = locales[lang]?.localeModule
  return _format(new Date(date), formatString, { ...options, locale })
}

export function daysToSeconds(days) {
  return days * 24 * 60 * 60
}

export function getDayNames(lang) {
  const en = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const es = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']
  const all = { es, en }
  return all[lang] ?? en
}

export function getPriceDayNames(lang) {
  const target = getDayNames(lang)
  return target.map((name, index) => ({ name, day: (index + 1).toString(), is_active: false }))
}
