var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _c("div", { staticClass: "change-lang", class: { opened: _vm.isOpen } }, [
      _c(
        "div",
        {
          staticClass: "change-lang__select change-lang__item",
          on: {
            click: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _c("img", { attrs: { src: _vm.activeLang.image, alt: "" } }),
          _c(
            "svg",
            {
              attrs: {
                width: "19",
                height: "10",
                viewBox: "0 0 19 10",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M18 1L9.5 9L1 0.999999",
                  stroke: "#B2B7C6",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "change-lang__items", class: { show: _vm.isOpen } },
        [
          _vm._l(_vm.langs, function (lang) {
            return [
              lang.id !== _vm.activeLang.id
                ? _c(
                    "div",
                    {
                      key: lang.id,
                      staticClass: "change-lang__item",
                      on: {
                        click: function ($event) {
                          return _vm.onChange(lang)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: lang.image, alt: "" } })]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }