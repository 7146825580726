var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "v-list" },
    [
      _vm._l(_vm.itemsShow, function (item, index) {
        return _c(
          "li",
          { key: index, staticClass: "v-list__item", class: _vm.itemClasses },
          [
            _c(
              "span",
              { staticClass: "v-list__marker-wrapper" },
              [
                _vm._t("marker", function () {
                  return [
                    _c("span", {
                      staticClass: "v-list__marker",
                      class: { "v-list__marker--orange": _vm.orange },
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._t(
              "default",
              function () {
                return [
                  _vm._v("\n      " + _vm._s(item.description) + "\n    "),
                ]
              },
              null,
              item
            ),
          ],
          2
        )
      }),
      _vm.items.length > _vm.showCount
        ? _c(
            "button",
            {
              staticClass: "v-list__button-show",
              on: { click: _vm.toggleShow },
            },
            [
              _vm._v("\n    " + _vm._s(_vm.textShow) + "\n    "),
              _c(
                "div",
                {
                  staticClass: "v-list__button-arrow",
                  class: { "v-list__button-arrow--expanded": _vm.isShowHidden },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/svg/small-arrow.svg"),
                      alt: "small-arrow",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }