<template>
  <div class="ticket-mobile-preview">
    <div class="ticket-mobile-preview__header" >
      <img src="@/assets/img/mobile-header.jpg" alt="" />
      <div>{{ translations.bookingInformation.text }}</div>
    </div>
    <div class="ticket-mobile-preview__content">
      <div class="ticket-mobile-preview__main">
        <div class="ticket-mobile-preview__qr-code">
          <img src="@/assets/img/svg/tour-qrcode.svg" alt="tour-qrcode" />
          <div class="ticket-mobile-preview__qr-code__expand">
            <img src="@/assets/img/svg/expand.svg" alt="" />
          </div>
        </div>
        <div class="ticket-mobile-preview__booking-code">VIG68028109</div>
        <div class="ticket-mobile-preview__tour-name">{{ cutVaucher('tourName', tour.name) }}</div>
        <div class="ticket-mobile-preview__info">
          <img src="@/assets/img/svg/tour-date-gray.svg" alt="tour-date" />
          <p class="ticket-mobile-preview__info-text">{{ formattedDate }}</p>
        </div>
        <div class="ticket-mobile-preview__info">
          <img src="@/assets/img/svg/tour-time-gray.svg" alt="tour-start-time" />
            <div class="ticket-mobile-preview__info-text ">{{ tourStarting }}</div>
        </div>
        <div class="ticket-mobile-preview__info">
          <img src="@/assets/img/svg/tour-duration-gray.svg" alt="tour-duration" />
          <p class="ticket-mobile-preview__info-text">{{ durationTitle }}</p>
        </div>
        <div v-if="language" class="ticket-mobile-preview__info">
          <img src="@/assets/img/svg/tour-language-gray.svg" alt="tour-language" />
          <p class="ticket-mobile-preview__info-text">{{ language }}</p>
        </div>
        <div class="ticket-mobile-preview__info">
          <img src="@/assets/img/svg/tour-amount-gray.svg" alt="tour-members-amount" />
          <p class="ticket-mobile-preview__info-text">1 {{ memberAgeCategory }}</p>
        </div>
        <div class="ticket-mobile-preview__info">
          <img src="@/assets/img/svg/tour-buyer-gray.svg" alt="tour-buyer" />
          <p class="ticket-mobile-preview__info-text">
            {{ translations.mobileBuyer.text }}
          </p>
        </div>

        <div class="ticket-mobile-preview__calendar-button">
          {{ translations.addToCalendarButton.text }}
        </div>
        <p v-if="reservationSystem" class="ticket-mobile-preview__reference">
          {{ translations.activityProviderReference.text }}
          1bsadcdihjsdoa-ajxjskdj-sdadjcjsi-7236rnskdna
        </p>
      </div>
      <div class="ticket-mobile-preview__warning">
        <img src="@/assets/img/svg/info.svg" alt="info" />
        <p class="ticket-mobile-preview__warning-text">
          {{ translations.mobileInfoType.text }}
        </p>
      </div>
      <div class="ticket-mobile-preview__warning">
        <img src="@/assets/img/svg/info.svg" alt="info" />
        <p class="ticket-mobile-preview__warning-text">
          {{ translations.textRememberPassport.text }}
        </p>
      </div>
      <div class="ticket-mobile-preview__options">
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage1.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage4.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage5.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage6.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage7.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage8.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
        <div class="ticket-mobile-preview__options-item">
          {{ translations.mobilePage2.text }}
          <img class="arrow" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
        </div>
      </div>
      <div class="ticket-mobile-preview__booking">
        <div class="ticket-mobile-preview__title">
          {{ translations.mobileManagement.text }}
        </div>
        <p class="ticket-mobile-preview__text">
          {{ translations.cancellationText.text }}
        </p>
        <div>
          <div class="ticket-mobile-preview__booking-item">
            <img class="image" src="@/assets/img/svg/transfer.svg" alt="transfer" />
            {{ translations.mobileOptions1.text }}
          </div>
          <div class="ticket-mobile-preview__booking-item">
            <img class="image" src="@/assets/img/svg/cancel-trashbox.svg" alt="cancel-trashbox" />
            {{ translations.mobileOptions2.text }}
          </div>
        </div>
      </div>
      <div class="ticket-mobile-preview__operator">
        <div class="ticket-mobile-preview__title">
          {{ translations.activityPperator.text }}
        </div>
        <div class="ticket-mobile-preview__text">
          {{ translations.activityProvidedBy.text }}
        </div>
        <div class="ticket-mobile-preview__title">{{ companyName }}</div>
        <div class="ticket-mobile-preview__text">
          {{ translations.contactActivityOperator.text }}
        </div>
        <div class="ticket-mobile-preview__contacts">
          <div class="ticket-mobile-preview__contacts-item">
            <img class="image" src="@/assets/img/svg/voucher-phone.svg" />
            <span class="ticket-mobile-preview__contacts-text">{{ translations.contactCall.text }}</span>
          </div>
          <div class="ticket-mobile-preview__contacts-item">
            <img class="image" src="@/assets/img/svg/message.svg" />
            <span class="ticket-mobile-preview__contacts-text">{{ translations.contactMessage.text }}</span>
          </div>
        </div>
        <div class="ticket-mobile-preview__text">
          {{ translations.visitHelpCenter.text }}
        </div>
      </div>
      <div>
        <div class="ticket-mobile-preview__booking-item">
          <img class="image" src="@/assets/img/svg/transfer.svg" alt="transfer" />
          {{ translations.helpCenter.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cutVaucher } from './utils';
import { format } from '@/utils/date';
import { formatTime } from '@/utils/utils';
import { getTourStarting, normalizeRange } from '~/utils/vaucherUtils';

// eslint-disable semi

export default {
  name: 'TicketMobilePreview',
  props: {
    lang: { type: String, required: true },
    translations: { type: Object, default: () => ({}) },
    tour: { type: Object, default: () => ({}) },
  },
  methods: {
    cutVaucher,
    formatTime,
  },
  computed: {
    companyName() {
      return this.$store.state.userInfo.company_trading_name;
    },
    tourStarting() {
      return getTourStarting.call(this, this.tour, this.translations, this.lang);
    },
    reservationSystem() {
      const userInfo = this.$store.state.userInfo;
      const type = userInfo.reservation_system_type;
      const status = userInfo.reservation_system_status;
      if (!type) return false;
      if (status !== 'connected') return false;
      return true;
    },
    schedule() {
      return this.tour.get_tour_schedule[0];
    },

    formattedDate() {
      return format(this.schedule.start_date, 'd MMMM yyyy', undefined, this.lang);
    },

    time() {
      return this.schedule?.price_schedules?.[0]?.available_time[0] || '';
    },

    language() {
      const count = this.tour.languages?.length ?? 0;
      if (count < 1) return undefined;
      if (this.lang === 'es') return 'Inglés';
      return 'English';
    },

    isFlexDuration() {
      return this.tour.duration_type === 'flexible_time';
    },

    memberAgeCategory() {
      const members = this.schedule?.price_schedules?.[0]?.members;

      if (members.length) {
        return members[0]?.member?.age_category.name;
      }

      return this.translations.mobileAmount.text;
    },

    durationTitle() {
      return normalizeRange(this.tour.duration_time, this.tour.duration_time_unit, this.lang)
    },

    durationUnitTranslate() {
      const durations = {
        h: this.translations.DHours.text,
        min: this.translations.DMinutes.text,
        d: this.translations.DDays.text,
      };
      return durations[this.tour.duration_time_unit] || this.tour.duration_time_unit;
    },
  },
};
</script>

<style lang="scss">
.shifted {
  margin-left: 28px;
}

.ticket-mobile-preview {
  overflow: hidden;
  background-image: url('~/assets/img/svg/phone.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 356px;
  height: 643px;
  padding: 17px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
    & > img {
      width: 100%;
    }
    & > div {
      color: white;
      font-size: 16px;
      position: absolute;
      width: 100%;
      margin-top: 28px;
      top: 0;
      text-align: center;
    }
  }

  &__main {
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 24px 21px 11px 24px;
  }

  &__booking-code {
    margin-bottom: 4px;
    font-weight: bold;
    color: $orange;
    font-size: 14px;
    line-height: 19px;
  }

  &__tour-name {
    word-wrap: break-word;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  &__qr-code {
    position: relative;
    float: right;
    margin-left: 12px;
    width: 76px;
    height: 76px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    img {
      width: 100%;
    }

    &__expand {
      position: absolute;
      bottom: -25px;
      right: 0;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 8px;
  }

  &__info-text {
    font-size: 12px;
    font-weight: 600;
  }

  &__calendar-button {
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    width: fit-content;
    margin-top: 16px;
  }

  &__reference {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
  }

  &__warning {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    margin-top: 20px;

    img {
      width: 20px;
    }
  }

  &__warning-text {
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
  }

  &__options {
    margin-bottom: 20px;
  }

  &__options-item {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    border-bottom: 1px solid #e2e6ef;
    padding-top: 20px;
    padding-bottom: 20px;

    &:first-child {
      margin-top: 20px;
      border-top: 1px solid #e2e6ef;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__booking-item {
    display: flex;
    padding: 14px 0;
    border-bottom: 1px solid #e2e6ef;

    &:first-child {
      border-top: 1px solid #e2e6ef;
      margin-bottom: 20px;
    }

    &:first-child {
      margin-top: 20px;
      border-top: 1px solid #e2e6ef;
    }

    .image {
      margin-right: 14px;
    }
  }

  &__operator {
    margin-top: 20px;
  }

  &__content {
    background: #fdfdfd;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding: 16px;
    margin-top: -18px;
    z-index: 5;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__contacts {
    display: flex;
    padding: 16px;
  }

  &__contacts-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;

    img {
      width: 32px;
      margin-bottom: 8px;
    }

    &:first-child {
      border-right: 1px solid #e2e6ef;
    }
  }

  &__contacts-text {
    color: $primary;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
}
</style>
