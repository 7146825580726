<template>
  <div class="activity-features-grid">
    <activity-icon
      v-for="(icon, index) in activeIcons"
      :key="index"
      :icon-name="icon.iconName"
      :title="icon.title"
      :description="icon.description"
    />
  </div>
</template>
<script>
import ActivityIcon from '@/components/preview/ActivityIcon.vue';
import { pluralizeNumeral } from '@/utils/pluralization';
import { getTourStarting } from '@/utils/vaucherUtils';

export default {
  name: 'ActivityTour',
  components: { ActivityIcon },
  props: {
    tour: { type: Object, default: () => {} },
    translates: { type: Object, default: () => {} },
    availabilityTimes: { type: Array, default: () => [] },
    flexibleDuration: { type: Boolean, default: false },
    tourFlexibleDuration1: { type: String, default: '' },
    tourFlexibleDuration2: { type: String, default: '' },
    durationTime: { type: [Number, String], default: 0 },
    durationUnit: { type: String, default: 'h' },
    durationType: { type: String, default: '' },
    scheduleType: { type: Object, default: null },
  },
  computed: {
    getTourDurationTitle() {
      const durationText =
        this.durationType === 'valid' ? this.translates.tourValid.text : this.translates.tourDescriptionDuration.text;

      if (!this.flexibleDuration) {
        const days = Math.floor(this.durationTime / (60 * 24));
        const hours = Math.floor((this.durationTime % (60 * 24)) / 60);
        const minutes = this.durationTime % 60;
        const daysString = days
          ? `${days} ${pluralizeNumeral(days, this.translates.DDaysOne.text, this.translates.DDays.text)}`
          : '';
        const hoursString = hours
          ? `${hours} ${pluralizeNumeral(hours, this.translates.DHoursOne.text, this.translates.DHours.text)}`
          : '';
        const minutesString = minutes
          ? `${minutes} ${pluralizeNumeral(minutes, this.translates.DMinutesOne.text, this.translates.DMinutes.text)}`
          : '';
        return `${durationText} ${daysString || ''} ${hoursString || ''} ${minutesString || ''}`;
      }

      return `${durationText} ${this.tourFlexibleDuration1} - ${this.tourFlexibleDuration2} ${this.getDurationUnitTranslate}`;
    },

    getDurationUnitTranslate() {
      const durations = {
        h: this.translates.DHours.text,
        min: this.translates.DMinutes.text,
        d: this.translates.DDays.text,
      };
      return durations[this.durationUnit] || this.durationUnit;
    },

    getTourDurationDescription() {
      const lang = this.$cookies.get("i18n_redirected") ?? 'en';
      return getTourStarting.call(this, this.tour, this.translates, lang);
    },
    getTourVouchersDescription() {
      return this.tour.ticket_type === 'paper_and_electronic'
        ? this.translates.tourDescriptionVouchersType1.text
        : this.translates.tourDescriptionVouchersType2.text;
    },
    durationIcon() {
      return {
        iconName: 'TourDuration',
        title: this.getTourDurationTitle,
        description: this.getTourDurationDescription,
        isActive: true,
      };
    },
    voucherIcon() {
      return {
        iconName: 'TourVouchers',
        title: this.translates.tourDescriptionVouchers.text,
        description: this.getTourVouchersDescription,
        isActive: true,
      };
    },
    covidIcon() {
      return {
        iconName: 'Covid',
        title: this.translates.tourDescriptionCovid.text,
        description: this.translates.tourDescriptionCovidType.text,
        isActive: this.tour.covid_requirements.length || this.tour.covid_security.length,
      };
    },
    smallIcon() {
      return {
        iconName: 'TourSmall',
        title: this.translates.smallGroup.text,
        description: this.translates.smallGroupDescription.text.replace('8', this.tour.max_travelers),
        isActive: this.tour.max_travelers < 9 && !this.tour.is_private,
      };
    },
    privateIcon() {
      return {
        iconName: 'TourPrivate',
        title: this.translates.privateGroup.text,
        description: this.translates.privateGroupDescription.text,
        isActive: this.tour.is_private,
      };
    },
    guideIcon() {
      return {
        iconName: 'TourGuide',
        title: this.translates.tourDescriptionGuide.text,
        description: this.tour.languages.map(({ language }) => language).join(', '),
        isActive: this.tour.languages.length !== 0,
      };
    },
    cancellationIcon() {
      return {
        iconName: 'TourCancellation',
        title: this.translates.tourDescriptionCancellation.text,
        description: this.translates.tourDescriptionCancellationType.text,
        isActive: this.tour.cancellation_type !== 'no_refund',
      };
    },
    transferIcon() {
      let text;
      if (this.tour.meeting_type == 'MIXED') {
        text = this.translates.tourDescriptionTransferType.text;

        // если в пикапе есть хотя бы одна область
        if (this.tour.pick_ups?.some((pickup) => pickup.radius != 0))
          text = this.translates.tourDescriptionTransferType2.text;

        // если указано "Allow travelers to enter their own pickup location"
        if (this.tour.travelers_own_pick_up.length) text = this.translates.tourDescriptionTransferType3.text;
      } else if (this.tour.meeting_type == 'PICKUP') {
        text = this.translates.tourDescriptionTransferPickup.text;

        // если в пикапе есть хотя бы одна область
        if (this.tour.pick_ups?.some((pickup) => pickup.radius != 0))
          text = this.translates.tourDescriptionTransferPickup2.text;

        // если указано "Allow travelers to enter their own pickup location"
        if (this.tour.travelers_own_pick_up.length) text = this.translates.tourDescriptionTransferPickup3.text;
      }
      return {
        iconName: 'TourTransfer',
        title: this.translates.tourDescriptionTransfer.text,
        description: text,
        isActive: ['MIXED', 'PICKUP'].includes(this.tour.meeting_type),
      };
    },
    skipLineIcon() {
      return {
        iconName: 'TourSkipLine',
        title: this.translates.iconSkipLine.text,
        description: this.translates.tourDescriptionSkipLineType.text,
        isActive: this.tour.is_skip_line,
      };
    },
    customizeIcon() {
      return {
        iconName: 'TourCustomize',
        title: this.translates.personalisedActivity.text,
        description: this.translates.personalisedActivityDescription.text,
        isActive: this.tour.is_customize,
      };
    },
    activityIcons() {
      return [
        this.durationIcon,
        this.voucherIcon,
        this.smallIcon,
        this.privateIcon,
        this.guideIcon,
        this.cancellationIcon,
        this.transferIcon,
        this.skipLineIcon,
        this.customizeIcon,
      ];
    },
    activeIcons() {
      return this.activityIcons.filter(({ isActive }) => isActive);
    },
  },
};
</script>

<style lang="scss">
.activity-features-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: min-content min-content;
  row-gap: 25px;
  column-gap: 5px;
  justify-items: center;
  align-items: start;
}
</style>
