<template>
  <client-only>
    <div class="help-change-lang" :class="{ 'opened': isOpen }">
      <div class="help-change-lang__select" @click="isOpen = !isOpen">
        <img :src="activeLang.image" alt="" />
        <div class="text">{{ activeLang.abbreviation }}</div>
      </div>
      <div class="help-change-lang__items" :class="{ 'show': isOpen }">
        <div v-for="lang in langs" :key="lang.id" class="help-change-lang__item" @click="onChange(lang)">
          <img :src="lang.image" alt="" />
          <div class="text">{{ lang.abbreviation }}</div>
        </div>
      </div>
    </div>
  </client-only>
</template>

<script>
import { mapActions } from 'vuex'
const langs = [
  { "id": 4, "image": "https://test.backend.vidodoguide.com/media/uploads/icons/lang_eu_CO4WemK.svg", "lang": "English", "abbreviation": "EN" },
  { "id": 5, "image": "https://test.backend.vidodoguide.com/media/uploads/icons/lang_es_gz73Udl.svg", "lang": "Español", "abbreviation": "ES" }
]

export default {
  components: {
  },
  data() {
    return {
      isOpen: false,
      langs,
      activeLang: langs[0]
    }
  },
  beforeMount() {
    const rawLang = this.$cookies.get("i18n_redirected")
    const lang = this.langs.find(x => x.abbreviation.toLowerCase() === rawLang.toLowerCase())
    if (lang) this.activeLang = lang
  },
  methods: {
    ...mapActions(['reloadTranslations']),
    onChange(newLang) {
      this.$i18n.setLocale(newLang.abbreviation.toLowerCase())
      this.activeLang = newLang
      this.isOpen = false
      this.reloadTranslations()
    }
  }
}
</script>

<style lang="scss">
.help-change-lang {
  position: relative;
  border-radius: 7px;
  $gap-y: 10px;
  transition: box-shadow 0.3s;

  &.absolute-top {
    position: absolute;
    top: 0;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
    }
  }

  &__items {
    position: absolute;
    left: -8px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: $gap-y;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    transition: opacity 0.3s;
    background-color: white;
    padding: 4px 8px;
    padding-bottom: 8px;
    border-radius: 5px;

    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__item {
    display: flex;
    gap: 10px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}
</style>