<template>
  <div v-if="meetingPoint !== null" class="preview__section">
    <div class="preview__title">
      {{ translations.meetingPointTitle.text }}
    </div>
    <p class="preview__text">{{ meetingPoint.address }}</p>
    <br v-if="meetingPoint.description">
    <p v-if="meetingPoint.description" class="preview__text">{{ meetingPoint.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'MeetingPoint',
  props: {
    meetingPoint: { type: Object, default: () => {} },
    translations: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss">
</style>
