var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pricing-change-modal-second-page" }, [
    _vm.isPerson
      ? _c(
          "div",
          { staticClass: "person" },
          [
            _c(
              "div",
              { staticClass: "pricing-change-modal-second-page__title" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.translations.popupPersonSecondPageTitle.text) +
                    "\n    "
                ),
              ]
            ),
            _c("age-form", {
              ref: "ageForm",
              attrs: {
                "is-group-pricing": _vm.isPerson,
                translates: _vm.translations,
                members: _vm.members,
              },
              on: { "update:members": _vm.updateMembers },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "group" }, [
          _c(
            "div",
            { staticClass: "pricing-change-modal-second-page__title" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translations.popupGroupSecondPageTitle.text) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "pricing-change-modal-second-page__subtitle" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translations.popupGroupSecondPageSubtitle.text) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "pricing-change-modal-second-page__package" },
            [
              _c("v-select", {
                attrs: {
                  error: _vm.$v.typeName.$error,
                  items: _vm.groupTypes,
                  translates: _vm.groupTypesTranslations,
                  placeholder: _vm.translations.groupTypePlaceholder.text,
                },
                model: {
                  value: _vm.$v.typeName.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.typeName, "$model", $$v)
                  },
                  expression: "$v.typeName.$model",
                },
              }),
            ],
            1
          ),
        ]),
    _c(
      "div",
      { staticClass: "change-pricing-type__nav" },
      [
        _c("v-btn", {
          attrs: { outline: "", name: _vm.translations.popupNavBack.text },
          on: {
            click: function ($event) {
              return _vm.$emit("back")
            },
          },
        }),
        _c(
          "div",
          { staticClass: "nav__pagination row" },
          _vm._l(_vm.pageCount, function (index) {
            return _c("div", { key: `pageNum${index}` }, [
              _c("div", {
                staticClass: "dot dot_page",
                class: { dot_page_active: index - 1 == _vm.currentPageIndex },
              }),
            ])
          }),
          0
        ),
        _c("v-btn", {
          attrs: { base: "", name: _vm.translations.popupNavNext.text },
          on: { click: _vm.next },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }