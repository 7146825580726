<template>
  <div class="__main-wrapper">
    <Nuxt class="empty-main" />
    <footer class="footer">
      <div class="footer__wave">
        <svg class="editorial" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" width="100%">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0
                    58-18 88-18s
                    58 18 88 18
                    58-18 88-18
                    58 18 88 18
                    v44h-352z" />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="50" y="6" fill="#D1EBFD" />
          </g>
        </svg>
      </div>
      <div class="footer__content">
        <div class="container">
          <div class="footer__inner">
            <!-- <div class="language">
                            <nuxt-link :to="switchLocalePath('en')" @click="footerTranslation" class="language__item">English</nuxt-link>
                            <nuxt-link :to="switchLocalePath('es')" @click="footerTranslation" class="language__item">Español</nuxt-link>
                        </div> -->
            <div class="rights">
              {{ fixYear(footerTranslate.fields.footerAllRights.text) }}
              <!-- <nuxt-link class="rights__item" :to="localePath(`/supplier-agreement`)">{{footerTranslate.fields.footerLink.text}}</nuxt-link> -->
            </div>
          </div>
        </div>
      </div>
    </footer>
    <PortalTarget name="popups" multiple></PortalTarget>
    <notification-view />
    <screenResolutionNotice />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    NotificationView: () => import('@/components/common/VNotificationsView'),
    screenResolutionNotice: () => import('@/components/pop-up/screenResolutionNotice.vue')
  },
  computed: {
    ...mapState(['footerTranslate'])
  },
  methods: {
    ...mapActions(['footerTranslation']),
    fixYear(text) {
      return text.replace(/20\d{2}/, new Date().getFullYear())
    }
  }
}
</script>

<style lang="scss">
.empty-main {
  flex: 1 1 auto;
  padding: 30px;
}

.footer {
  height: 20%;
  width: 100%;

  &__wave {
    margin-bottom: 0px;

    ::selection {
      background-color: salmon;
      color: white;
    }

    .parallax>use {
      animation: move-forever 5s linear infinite;

      &:nth-child(1) {
        animation-delay: -2s;
      }

      &:nth-child(2) {
        animation-delay: -2s;
        animation-duration: 5s;
      }

      &:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 3s;
      }
    }

    @keyframes move-forever {
      0% {
        transform: translate(-90px, 0%);
      }

      100% {
        transform: translate(85px, 0%);
      }
    }

    .editorial {
      display: block;
      width: 100%;
      height: 4em;
      max-height: 100vh;
      margin: 0;
    }
  }

  &__inner {
    padding: 75px 100px 20px 15px;
    display: flex;
    background: #d1ebfd;

    .language {
      display: flex;

      &__item {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 15px;
        }

        &:hover {
          color: $orange;
        }
      }
    }

    .rights {
      margin: auto;

      &__item {
        cursor: pointer;
        color: #1e2843;
        text-decoration: none;
        border-bottom: 1px solid #1e2843;
        font-size: 18px;

        &:hover {
          color: $orange;
        }
      }
    }
  }
}
</style>
