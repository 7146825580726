import { format as _format } from 'date-fns';
import { formatTime } from './utils';
import { es, enGB } from 'date-fns/locale'

const LOCALES = { es, en: enGB};

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};
export function formatDate(date, locale) {
  return new Date(date).toLocaleDateString(locale, options);
}

export function formatDateMonthDayYear(date, locale) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  
  return new Date(date).toLocaleDateString(locale, options);
}

export function formatTimeLocale(date, locale) {
  const options = {
    weekday: 'long', 
    year: 'numeric',   
    month: 'short',   
    day: 'numeric',    
    hour: '2-digit',
    minute: '2-digit', 
  };

  return new Date(date).toLocaleTimeString(locale, options);
}

export function format(date, formatString = 'MMM d, YYYY', options, lang = 'en') {
  const locale = LOCALES[lang];
  return _format(new Date(date), formatString, {...options, locale });
}

export function daysToSeconds(days) {
  return days * 24 * 60 * 60;
}

export function getDayNames(lang) {
  const en = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const es = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];
  const all = { es, en };
  return all[lang] ?? en;
}

export function getPriceDayNames(lang) {
  const target = getDayNames(lang);
  return target.map((name, index) => ({name, day: (index+1).toString(), is_active: false }));
}