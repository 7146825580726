var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    _vm._b({ staticClass: "v-table" }, "table", _vm.$attrs, false),
    [
      _c("thead", [
        _c(
          "tr",
          { staticClass: "v-table__headers" },
          _vm._l(_vm.headers, function (header) {
            return _c(
              "th",
              {
                key: header.value,
                staticClass: "v-table__header v-table__cell",
                style: { width: header.width, minWidth: header.width },
              },
              [
                _vm._t(
                  `header:${header.value}`,
                  function () {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      ),
                    ]
                  },
                  null,
                  header
                ),
              ],
              2
            )
          }),
          0
        ),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "tr",
            { key: `item-${index}`, staticClass: "v-table__items" },
            _vm._l(_vm.headers, function (header, headerIndex) {
              return _c(
                "td",
                {
                  key: `key-${headerIndex}`,
                  staticClass: "v-table__cell v-table__item",
                  attrs: { align: `${header.align || ""}` },
                },
                [
                  _vm._t(
                    `item:${header.value}`,
                    function () {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(item[header.value]) +
                            "\n        "
                        ),
                      ]
                    },
                    null,
                    { item, header }
                  ),
                ],
                2
              )
            }),
            0
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }