var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview__section", attrs: { id: "canellcation-policy" } },
    [
      _c("div", { staticClass: "preview__title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.translations.cancellationPolicyTitle.text) +
            "\n  "
        ),
      ]),
      _c("div", { staticClass: "two-columns" }, [
        _c(
          "div",
          { staticClass: "two-columns__left" },
          _vm._l(_vm.cancellationPolicy.left, function (text, index) {
            return _c("div", { key: index, staticClass: "preview__include" }, [
              _c("div", { staticClass: "preview__dot preview__dot" }),
              _c("span", { staticClass: "preview__include-text" }, [
                _vm._v(_vm._s(text.description)),
              ]),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "two-columns__right" },
          _vm._l(_vm.cancellationPolicy.right, function (text, index) {
            return _c("div", { key: index, staticClass: "preview__include" }, [
              _c("div", { staticClass: "preview__dot preview__dot" }),
              _c("span", { staticClass: "preview__include-text" }, [
                _vm._v(_vm._s(text.description)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }