var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.attractions.length
    ? _c("div", { staticClass: "attractions-list" }, [
        _c("div", { staticClass: "attractions-list__title" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.translations.attractionsText.text) + "\n  "
          ),
        ]),
        _c(
          "ul",
          { staticClass: "attractions-list__list" },
          _vm._l(_vm.attractions, function (attraction) {
            return _c(
              "li",
              { key: attraction.id, staticClass: "attractions-list__item" },
              [
                _c(
                  "div",
                  { staticClass: "attractions-list__item-photo" },
                  [
                    _c("LocationIcon", {
                      staticClass: "attractions-list__item-photo-icon",
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "attractions-list__item-content" }, [
                  _c("div", { staticClass: "attractions-list__item-address" }, [
                    _vm._v(
                      "\n          " + _vm._s(attraction.address) + "\n        "
                    ),
                  ]),
                  _c("div", {
                    staticClass: "attractions-list__item-description",
                    domProps: { textContent: _vm._s(attraction.description) },
                  }),
                  _c("div", { staticClass: "attractions-list__info" }, [
                    _c("span", { staticClass: "attractions-list__info-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(attraction.duration_time) +
                          " " +
                          _vm._s(
                            _vm.durationTranslates[
                              attraction.duration_time_unit
                            ]
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("span", { staticClass: "attractions-list__info-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ticketRedemption) +
                          "\n          "
                      ),
                    ]),
                    attraction.is_skip_line
                      ? _c(
                          "span",
                          { staticClass: "attractions-list__info-item" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.translations.textSkipLine.text) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }