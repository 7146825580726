var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        size: "large-plus",
        title: _vm.translation.sentCode.text,
        "dialog-classes": "pop-up-confirm-email-code-title",
      },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c("div", { staticClass: "pop-up-confirm-email-code" }, [
        _c(
          "div",
          { staticClass: "pop-up-confirm-email-code__content" },
          [
            _c("div", { staticClass: "pop-up-confirm-email-code__title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.translation.sentWay.text) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "VField",
              {
                staticClass: "pop-up-confirm-email-code__field",
                style: {
                  marginBottom: _vm.attemptsCount >= 3 ? 27 + "px" : "",
                },
                attrs: {
                  label: _vm.translation.sentEnter.text,
                  error: _vm.$v.code.$error,
                  "error-message": _vm.getErrorMessage(),
                },
              },
              [
                _c("VInput", {
                  attrs: {
                    error: _vm.$v.code.$error,
                    placeholder: _vm.translation.sentYour.text,
                  },
                  model: {
                    value: _vm.$v.code.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.code, "$model", $$v)
                    },
                    expression: "$v.code.$model",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.attemptsCount < 3
              ? _c(
                  "div",
                  {
                    staticClass: "pop-up-confirm-email-code__resend-code",
                    on: { click: _vm.resendCode },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translation.sentResend.text) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pop-up-confirm-email-code__actions" },
          [
            _c("VCheckbox", {
              attrs: {
                label: _vm.translation.sentTrust.text,
                className: "blue-border",
              },
              model: {
                value: _vm.isTrustDevice,
                callback: function ($$v) {
                  _vm.isTrustDevice = $$v
                },
                expression: "isTrustDevice",
              },
            }),
            _vm._v(" "),
            _c("v-btn", {
              staticClass: "pop-up-confirm-email-code__confirm",
              attrs: {
                base: "",
                name: _vm.translation.sendConfirm.text,
                loading: _vm.loading,
                "loading-name": _vm.translation.statusConfirming.text,
              },
              on: {
                click: function ($event) {
                  return _vm.confirmEmailCode()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }