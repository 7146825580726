<template>
  <div class="index-price-type-page">
    <div v-if="loadingPage">
      <local-loader />
      <div class="index-price-type-page__loading-title">{{ translations.loader.text }}</div>
    </div>
    <div v-else>
      <BackLink v-if="isBuild" />
      <div class="index-price-type-page__title">
        {{ translations.title.text }}
      </div>
      <div class="index-price-type-page__content-title">
        {{ translations.howPriceTitle.text }}
      </div>
      <div class="index-price-type-page__how-price">
        <div @click="checkPersonType()">
          <RadioButton
            default
            :name="translations.howPriceRadio1.text"
            radio-name="how-price"
            :checked="isPersonType"
          />
          <div class="index-price-type-page__how-price--description">{{ translations.infoPrice.text }}</div>
        </div>
        <div @click="checkPersonType()">
          <RadioButton
            default
            :name="translations.howPriceRadio2.text"
            radio-name="how-price"
            :checked="!isPersonType"
          />
          <div class="index-price-type-page__how-price--description">{{ translations.infoPriceGroup.text }}</div>
        </div>
        <div v-if="!isPersonType" class="index-price-type-page__package">
          <selection default :default-name="groupTypeName" :items="selectionTitles" @name="changeGroupName" />
        </div>
      </div>

      <AgeForm
        v-if="isPersonType"
        ref="ageForm"
        :is-group-pricing="tour.is_group_pricing"
        :translates="translations"
        :members.sync="members"
        :tour-members="tourMembers"
      />
      <div
        v-if="isPersonType && isSelectedOneChildrenGroups"
        class="index-price-type-page__child-accompanied"
        @click="accompanied = !accompanied"
      >
        <div class="index-price-type-page__child-accompanied--title">
          {{ translations.accompanied.text }}
        </div>
        <RadioButton
          default
          radio-name="child-accompanied"
          :name="translations.accompaniedYesRadio.text"
          :checked="accompanied"
        />
        <RadioButton
          default
          radio-name="child-accompanied"
          :name="translations.accompaniedNoRadio.text"
          :checked="!accompanied"
        />
      </div>
      <div v-if="isPersonType" class="index-price-page__max-per-booking">
        <div class="index-price-type-page__child-accompanied--title">
          {{ translations.maxTravelersPerBooking.text }}
        </div>
        <v-input
          v-model="maxTravelersPerBooking"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          onpaste="return false;"
          :error="errorMaxTravelersPerBooking"
          type="number"
          @blur="blurMaxTravelers"
        />
      </div>
      <v-btn base :name="isBuild ? translations.btnSaveAndCont.text : translations.saveBtn.text" @click="savePricing" />
      <pricing-change
        :lang="lang"
        :is-active="pricingChange"
        :is-saving="savingPricing"
        :selection-titles="selectionTitles"
        :person-pricing="isPersonType"
        :tour="tour"
        :group-pricing="groupPricing"
        :tour-members="tourMembers"
        :translations="translations"
        :members="members"
        :old-schedule="oldSchedule"
        @update:isActive="closePricingChange()"
        @save="savingPricingChange()"
      />
    </div>
  </div>
</template>
<script>
  import '@/assets/css/setup-product.scss'
  import AgeForm from '@/components/price/AgeForm.vue'
  import RadioButton from '@/components/controls/RadioButton.vue'
  import VBtn from '@/components/controls/VBtn.vue'
  import VInput from '@/components/controls/VInput.vue'
  import Selection from '@/components/controls/Selection.vue'
  import PricingChange from '@/components/pop-up/price/PricingChange.vue'
  import LocalLoader from '@/components/common/LocalLoader'
  import BackLink from '@/components/Product/BackLink.vue'

  export default {
    components: {
      AgeForm,
      RadioButton,
      VBtn,
      Selection,
      PricingChange,
      LocalLoader,
      VInput,
      BackLink,
    },
    layout: 'accountHeader',
    props: {
      isBuild: { type: Boolean, default: false },
      tourOb: { type: Object, default: () => ({}) },
      translations: { type: Object, default: () => ({}) },
      params: { type: Object, default: () => ({}) },
      lang: { type: String, default: '' },
    },
    data: () => ({
      loadingPage: false,
      tour: {},
      members: [],
      accompanied: false,
      isPersonType: false,
      isPersonTypeOld: false,
      oldSchedule: [],
      groupTypeName: '',
      selectionTitles: [],
      tourMembers: [],

      unchecked: [],
      pricingChange: false,
      personPricing: false,
      groupPricing: false,
      savingPricing: false,
      maxTravelersPerBooking: '',
      errorMaxTravelersPerBooking: false,
    }),
    async fetch() {
      this.loadingPage = true
      this.tour = this.tourOb
      this.members = await this.$axios.$get(`/api/v1/get-members/?tour_id=${this.tour.id}&language=${this.lang}`)
      for (let i = 0; i < this.members.length; i++) {
        this.members[i].min_age = ''
        this.members[i].max_age = ''
        this.members[i].is_active = false
        this.members[i].ageTieredGroups = [{ minAge: null, maxAge: null, price: null, feePrice: null }]
      }
      this.tourMembers = this.tour.travelers
      this.accompanied = this.tour.children_accompanying
      for (let i = 0; i < this.tourMembers.length; i++) {
        for (let j = 0; j < this.members.length; j++) {
          if (this.tourMembers[i].age_category.id === this.members[j].id) {
            this.members[j].min_age = this.tourMembers[i].min_age.toString()
            this.members[j].max_age = this.tourMembers[i].max_age.toString()
            this.members[j].is_active = true
            this.members[j].checked = true
            continue
          }
        }
      }
      this.isPersonType = !this.tour.is_group_pricing
      this.isPersonTypeOld = this.isPersonType
      this.maxTravelersPerBooking = this.tour.max_travelers_per_booking
      this.groupTypeName = this.translations.groupTypePlaceholder.text
      const lang = this.$cookies.get('i18n_redirected') ?? 'en'
      this.selectionTitles = await this.$axios.$get(`/api/v1/get-tour-group-types/?language=${lang}`)
      for (let i = 0; i < this.selectionTitles.length; i++) this.selectionTitles[i].is_active = false

      if (this.tour.group_type) this.groupTypeName = this.tour.group_type.name

      if (this.tour.get_tour_schedule.length !== 0) this.oldSchedule = this.tour.get_tour_schedule

      this.loadingPage = false
    },

    computed: {
      checkedMembers() {
        return this.members.filter(({ checked }) => checked).map(({ id }) => id)
      },
      isSelectedOneChildrenGroups() {
        return (
          this.members.find((e) => e.id === 3).checked ||
          this.members.find((e) => e.id === 4).checked ||
          this.members.find((e) => e.id === 5).checked
        )
      },
    },

    watch: {
      checkedMembers(newCheckedMembers) {
        this.tourMembers.forEach((tourMember) => {
          const memberId = tourMember.age_category.id
          const memberIsChecked = newCheckedMembers.includes(memberId)

          if (!memberIsChecked && !this.unchecked.includes(memberId)) {
            this.unchecked = [...this.unchecked, memberId]
          } else if (memberIsChecked) {
            this.unchecked = this.unchecked.filter((id) => memberId !== id)
          }
        })
      },
    },

    methods: {
      blurMaxTravelers() {
        if (!this.tour.is_private && this.maxTravelersPerBooking > 0) {
          this.maxTravelersPerBooking = Math.min(this.maxTravelersPerBooking, this.tour.max_travelers)
        }
      },
      closePricingChange() {
        this.pricingChange = !this.pricingChange
        if (!this.savingPricing) {
          this.isPersonType = this.isPersonTypeOld
        }
      },
      savingPricingChange() {
        this.savingPricing = true
      },
      checkPersonType() {
        if (process.browser) {
          this.isPersonType = !this.isPersonType
          if (this.isPersonType && !this.isPersonTypeOld) {
            if (this.tour.get_tour_schedule.length !== 0) {
              this.pricingChange = true
              this.groupPricing = false
              for (let i = 0; i < this.members.length; i++) {
                this.members[i].min_age = ''
                this.members[i].max_age = ''
                this.members[i].is_active = false
                this.members[i].ageTieredGroups = [{ minAge: null, maxAge: null, price: null, feePrice: null }]
              }
            }
            this.personPricing = true
            this.savingPricing = false
          } else if (!this.isPersonType && this.isPersonTypeOld) {
            if (this.tour.get_tour_schedule.length !== 0) {
              this.pricingChange = true
              this.personPricing = false
            }
            this.groupPricing = true
            this.savingPricing = false
          }
        }
      },
      changeGroupName(defaultName) {
        this.groupTypeName = defaultName
      },

      async savePricing() {
        let error = false
        if (this.isPersonType) {
          const ageFormValid = this.$refs.ageForm.validate()
          this.errorMaxTravelersPerBooking = this.maxTravelersPerBooking <= 0
          error = !ageFormValid || this.errorMaxTravelersPerBooking
        } else if (this.groupTypeName === 'Choose one') {
          error = true
        }

        if (!error) {
          if (this.isPersonType) {
            await this.$axios.$put('/api/v1/edit-tour/', null, {
              params: {
                tour_id: this.tour.id,
                is_group_pricing: false,
                children_accompanying: this.accompanied,
                max_travelers_per_booking: this.maxTravelersPerBooking,
              },
            })
            for (let i = 0; i < this.members.length; i++) {
              if (this.members[i].checked) {
                await this.$axios.$post(`/api/v1/add-tour-member/`, null, {
                  params: {
                    tour_id: this.tour.id,
                    age_category_id: this.members[i].id,
                    min_age: this.members[i].min_age,
                    max_age: this.members[i].max_age,
                  },
                })
              }
            }
            for (let i = 0; i < this.unchecked.length; i++) {
              await this.$axios.$delete(
                `/api/v1/delete-tour-member/?tour_id=${this.tour.id}&age_category_id=${this.unchecked[i]}`
              )
            }
          } else {
            let groupTypeId = 0
            for (let i = 0; i < this.selectionTitles.length; i++) {
              if (this.groupTypeName === this.selectionTitles[i].name) {
                groupTypeId = this.selectionTitles[i].id
              }
            }
            await this.$axios.$put(
              `/api/v1/edit-tour/?tour_id=${this.tour.id}&is_group_pricing=true&group_type_id=${groupTypeId}`
            )
            if (this.tourMembers.length === 0) {
              await this.$axios.$post(
                `/api/v1/add-tour-member/?tour_id=${this.tour.id}&age_category_id=${this.members[0].id}&min_age=1&max_age=100`
              )
            }
          }

          if (this.isBuild) {
            const productStepBody = new FormData()
            productStepBody.append('pricing_type', true)
            await this.$axios.$put(`api/v1/set-tour-creation-steps/${this.tour.id}`, productStepBody).then(() => {
              this.$router.push(this.localePath(`/product/${this.tour.slug}/build/schedules-and-pricing/schedules`))
            })
          } else {
            this.$router.push(this.localePath(`/product/${this.tour.slug}/schedules-and-prices`))
          }
        }
      },
    },
  }
</script>

<style lang="scss" scope>
  .index-price-type-page {
    .v-local-loader {
      margin-top: 150px;
    }
    &__loading-title {
      text-align: center;
      margin-top: 30px;
    }
    &__title {
      margin-bottom: 39px;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      word-wrap: break-word;
    }
    &__content-title {
      font-weight: bold;
      margin-bottom: 17px;
    }
    &__package {
      margin-top: 7px;
      margin-left: 36px;
      .selection {
        &__preview {
          width: 258px;
        }
        &__expand {
          width: 258px;
        }
      }
    }
    &__how-price {
      margin-bottom: 66px;
      &--description {
        font-size: 13px;
        line-height: 15px;
        color: #9498a4;
        margin-top: 5px;
        margin-bottom: 8px;
        margin-left: 35px;
      }
    }

    &__child-accompanied {
      margin-bottom: 29px;
      &--title {
        font-weight: bold;
        margin-bottom: 16px;
      }
      .radio-button {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
</style>
