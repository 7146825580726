var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-add-exclude" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm.have_error && _vm.errorText
            ? _c("div", { staticClass: "pop-up-add-exclude__errors" }, [
                _vm._m(0),
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    " " + _vm._s(_vm.excludeTranslate.textError.text) + " "
                  ),
                ]),
                _c("div", { staticClass: "error-type" }, [
                  _c("div", { staticClass: "description" }, [
                    _vm._v(" " + _vm._s(_vm.errorText) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "pop-up-add-exclude__title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.excludeTranslate.title.text) + "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-add-exclude__subtitle" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.excludeTranslate.subtitle.text) + "\n    "
            ),
          ]),
          _c("div", { staticClass: "pop-up-add-exclude__content" }, [
            _c("div", { staticClass: "category" }, [
              _c("div", { staticClass: "category__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.excludeTranslate.contentCategory.text) +
                    "\n        "
                ),
              ]),
              _c(
                "div",
                { staticClass: "category__selection" },
                [
                  _c("selection", {
                    attrs: {
                      default: "",
                      "default-name": _vm.categoryName,
                      items: _vm.selectionCategory,
                    },
                    on: { name: _vm.changeCategory },
                  }),
                ],
                1
              ),
            ]),
            _vm.categoryName == _vm.excludeTranslate.ownCategory.text ||
            _vm.categoryName == _vm.excludeTranslate.feesCategory.text
              ? _c("div", { staticClass: "info-type" }, [
                  _vm.categoryName == _vm.excludeTranslate.ownCategory.text
                    ? _c("div", { staticClass: "info-type__other" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "description" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.excludeTranslate.infotypeOwn.text) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.categoryName == _vm.excludeTranslate.feesCategory.text
                    ? _c("div", { staticClass: "info-type__fees" }, [
                        _vm._m(2),
                        _c("div", { staticClass: "description" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.excludeTranslate.infotypeFees.text) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.categoryName == _vm.excludeTranslate.ownCategory.text
              ? _c("div", { staticClass: "own-exclusion" }, [
                  _c(
                    "div",
                    { staticClass: "category__selection" },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: _vm.excludeTranslate.ownPlaceholder.text,
                          label: _vm.excludeTranslate.contentTitle.text,
                          "max-length": 100,
                        },
                        model: {
                          value: _vm.ownExclusion,
                          callback: function ($$v) {
                            _vm.ownExclusion = $$v
                          },
                          expression: "ownExclusion",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.categoryName != _vm.excludeTranslate.categoryDefault.text &&
            _vm.categoryName != _vm.excludeTranslate.ownCategory.text
              ? _c("div", { staticClass: "exclude" }, [
                  _c("div", { staticClass: "category__title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.excludeTranslate.contentTitle.text) +
                        "\n        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "category__selection" },
                    [
                      _c("selection", {
                        attrs: {
                          default: "",
                          "default-name": _vm.excludeName,
                          items: _vm.selectionTitles,
                        },
                        on: { name: _vm.changeExcludes },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "pop-up-add-exclude__nav" }, [
            _c(
              "div",
              { staticClass: "cancel", on: { click: _vm.closeWindow } },
              [
                _c("Btn", {
                  attrs: {
                    outline: "",
                    name: _vm.excludeTranslate.cancelBtn.text,
                    disabled: _vm.BtnDesabled,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Add", on: { click: _vm.saveNewExclude } },
              [
                _c("Btn", {
                  attrs: {
                    base: "",
                    name: _vm.excludeTranslate.addBtn.text,
                    disabled: _vm.BtnDesabled,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/svg/error.svg"), alt: "info" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/svg/info.svg"), alt: "info" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/svg/info.svg"), alt: "info" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }