var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Portal",
    { attrs: { to: "popups" } },
    [
      _c("fade-transition", [
        _vm.visible
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "scroll-lock",
                    rawName: "v-scroll-lock",
                    value: _vm.visible,
                    expression: "visible",
                  },
                ],
                staticClass: "v-dialog-wrapper",
              },
              [
                _c("div", {
                  staticClass: "v-dialog__overlay",
                  on: { click: _vm.close },
                }),
                _c(
                  "div",
                  { class: ["v-dialog", _vm.dialogClasses, _vm.classes] },
                  [
                    _vm._t("document", function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-dialog__window",
                            attrs: { role: "document" },
                          },
                          [
                            _vm.title
                              ? _c("div", { staticClass: "v-dialog__header" }, [
                                  _c("h1", { staticClass: "v-dialog__title" }, [
                                    _vm._v(_vm._s(_vm.title)),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "v-dialog__close",
                                      attrs: {
                                        type: "button",
                                        "aria-label": "Close",
                                      },
                                      on: { click: _vm.close },
                                    },
                                    [
                                      _c("BaseIcon", {
                                        attrs: { "icon-name": "Close" },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "v-dialog__content" },
                              [_vm._t("default")],
                              2
                            ),
                            _vm.$slots.footer
                              ? _c(
                                  "div",
                                  { staticClass: "v-dialog__footer" },
                                  [_vm._t("footer")],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }