var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _c(
      "div",
      { staticClass: "__main-wrapper" },
      [
        _c(
          "header",
          { staticClass: "account-header" },
          [
            _c("div", { staticClass: "account-header__logo" }, [
              _c("a", { attrs: { href: _vm.localePath(`/`) } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/header/logo.svg"),
                    alt: "logo",
                  },
                }),
              ]),
            ]),
            _c("HelpSelectLanguage"),
          ],
          1
        ),
        _c("Nuxt"),
        _c("setupPassword", {
          attrs: {
            "is-active":
              _vm.$cookies.get("setupAccount") &&
              _vm.$cookies.get("setupAccount").length < 0 &&
              !_vm.userInfo.is_password_entered,
            "header-translate": _vm.headerTranslate,
          },
          on: {
            close: function ($event) {
              _vm.userInfo.is_password_entered =
                !_vm.userInfo.is_password_entered
            },
          },
        }),
        _c("PortalTarget", { attrs: { name: "popups", multiple: "" } }),
        _c("notification-view"),
        _c("screenResolutionNotice"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }