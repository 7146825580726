var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "v-textarea" }, [
    _vm.label || _vm.$slots.label
      ? _c(
          "div",
          { staticClass: "v-textarea__label" },
          [
            _vm._t("label", function () {
              return [
                _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                _vm.optional.length > 0
                  ? _c("span", { staticClass: "v-textarea__label--optional" }, [
                      _vm._v("(" + _vm._s(_vm.optional) + ")"),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.subLabel
      ? _c("div", { staticClass: "v-textarea__sub-label" }, [
          _vm._v("\n    " + _vm._s(_vm.subLabel) + "\n  "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "v-textarea__wrapper" }, [
      _c(
        "textarea",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localModel,
                expression: "localModel",
              },
            ],
            staticClass: "v-textarea__textarea",
            class: _vm.classes,
            attrs: {
              maxlength: _vm.maxLength ? _vm.maxLength : "",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.localModel },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.localModel = $event.target.value
              },
            },
          },
          "textarea",
          _vm.$attrs,
          false
        )
      ),
      _vm.maxLength
        ? _c(
            "div",
            {
              staticClass: "v-textarea__counter",
              class: { "v-textarea__counter--error": _vm.errorLength },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.localModel.length) +
                  "/" +
                  _vm._s(_vm.maxLength) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm.error && _vm.errorMessage
      ? _c("div", { staticClass: "validation-error" }, [
          _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }