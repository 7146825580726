var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        size: "large-plus",
        title: _vm.translation.verifyEmail.text,
        "dialog-classes": "pop-up-send-email-code-title",
      },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "pop-up-send-email-code" },
        [
          _c("div", { staticClass: "pop-up-send-email-code__content" }, [
            _c("div", { staticClass: "pop-up-send-email-code__title" }, [
              _vm._v(_vm._s(_vm.translation.verifySend.text)),
            ]),
            _c("div", { staticClass: "pop-up-send-email-code__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translation.verifyWillBeSent.text) +
                  " "
              ),
              _c("b", [_vm._v(_vm._s(_vm.hiddenEmail))]),
              _c("div", { staticClass: "pop-up-send-email-code__subtitle" }, [
                _vm._v(_vm._s(_vm.translation.verifyThisEmail.text)),
              ]),
            ]),
          ]),
          _c("v-btn", {
            staticClass: "pop-up-send-email-code__send",
            attrs: {
              base: "",
              name: _vm.translation.verifySendCode.text,
              loading: _vm.loading,
              "loading-name": "Sending",
            },
            on: {
              click: function ($event) {
                return _vm.sendEmailCode()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }