<template>
  <VDialog v-model="modelVisible" :title="editTranslate.title.text">
    <div class="edit-schedule-pop-up">
      <div>{{ editTranslate.contentStart.text }}</div>
      <date-picker
        ref="start"
        v-model="time1"
        :placeholder="editTranslate.dateStartPlaceholder.text"
        :class="{ 'input-error': dateError }"
        value-type="YYYY-MM-DD"
        :format="calendarExtra.format"
        :lang="calendarExtra.lang"
        :disabled-date="disabledDates"
        :editable="false"        
        @change="checkDateEndError()"
      >
        <template #mx-datepicker-popup><button class="mx-btn mx-btn-text">sss</button></template>
      </date-picker>
      <div v-if="!endDate" class="edit-schedule-pop-up__end-date-hide">
        <div class="edit-schedule-pop-up__end-date-hide--text" @click="endDate = !endDate">
          {{ editTranslate.contentEnd.text }}
        </div>
        <div class="edit-schedule-pop-up__end-date-hide--optional">
          {{ editTranslate.optional.text }}
        </div>
        <v-question-info :description="editTranslate.endQuestion.text" />
      </div>
      <div v-else class="edit-schedule-pop-up__end-date-show">
        <div class="edit-schedule-pop-up__end-date-show--title">
          {{ editTranslate.contentEnd.text }}
          <div class="edit-schedule-pop-up__end-date-show--title--optional">
            {{ editTranslate.optional.text }}
          </div>
          <v-question-info :description="editTranslate.endQuestion.text" />
        </div>
        <div class="edit-schedule-pop-up__end-date-show--date">
          <date-picker
            ref="dateEnd"
            v-model="time2"
            :placeholder="editTranslate.dateEndPlaceholder.text"
            value-type="YYYY-MM-DD"
            :format="calendarExtra.format"
            :lang="calendarExtra.lang"
            :disabled-date="disabledDates"
            :editable="false"
            @change="checkDateEndError()"
          />
          <div class="edit-schedule-pop-up__end-date-show--date--clear" @click="time2 = ''">
            {{ editTranslate.clearDate.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="edit-schedule-pop-up__nav">
      <v-btn outline :name="editTranslate.cancelBtn.text" @click="modelVisible = false" />
      <v-btn base :name="editTranslate.saveBtn.text" @click="editSchedule" />
    </div>
  </VDialog>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import VQuestionInfo from '@/components/common/VQuestionInfo.vue'
import VBtn from '@/components/controls/VBtn.vue'
import 'vue2-datepicker/index.css'
import VDialog from '@/components/common/VDialog.vue'
import { checkDateEnd } from '@/utils/validations'
import notificationService from '@/services/notification'
import { calendarExtra } from '~/utils/componentHelpers'

export default {
  components: {
    VQuestionInfo,
    VBtn,
    VDialog,
    DatePicker
  },
  props: {
    isActive: { type: Boolean, default: false },
    tour: { type: Object, default: () => ({}) },
    tourLang: { type: String, default: '' },
    id: { type: Number, default: 0 },
    tourStartTime: { type: String, default: '' },
    tourEndTime: { type: String, default: '' },
    editTranslate: { type: Object, default: () => ({}) },
  },
  data: () => ({
    time1: null,
    dateError: false,
    time2: null,
    endDate: false
  }),
  fetch() {
    this.time1 = this.tourStartTime
    if (this.tourEndTime <= '2098-03-12') {
      this.time2 = this.tourEndTime
      this.endDate = true
    }
  },
  computed: {
    calendarExtra,
    modelVisible: {
      get() {
        return this.isActive
      },
      set(visible) {
        this.$emit('update:isActive', visible)
      }
    }
  },
  methods: {
    checkDateEnd,
    checkDateEndError() {
      if (!this.checkDateEnd(this.time1, this.time2)) {
        this.time2 = null
        notificationService.error({ title: this.editTranslate.endDateCannotBeEarlierStart.text })
      }

      if (this.time1 != null && this.time1 === this.time2) {
        this.time2 = null
        notificationService.error({ title: this.editTranslate.startDateCannotBeEndDate.text })
      }
    },
    disabledDates(date) {
      const newDate = new Date()
      newDate.setDate(newDate.getDate() - 1)
      return newDate > date
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? '0' + s : s
      }
      const d = new Date(inputFormat)
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')
    },
    editSchedule() {
      this.$emit('loading')
      if (this.time1 != null && this.time1.length > 0) {
        this.$axios
          .$put(`/api/v1/edit-tour-schedule/`, null, {
            params: {
              schedule_id: this.id,
              edit_language: this.tourLang,
              start_date: this.convertDate(this.time1),
              end_date:
                this.time2 != null && this.time2.length > 0 && new Date(this.time1) < new Date(this.time2)
                  ? this.convertDate(this.time2)
                  : '12.02.2222'
            }
          })
          .then(() => {
            this.$router.go(this.localePath('/'))
          })
      } else if (this.time1 == null) {
        this.dateError = true
      }

      this.$router.go(this.localePath('/'))
    }
  }
}
</script>

<style lang="scss">
.edit-schedule-pop-up {
  background: $white;
  position: relative;
  width: 580px;
  margin-top: 16px;
  margin-bottom: 70px;
  &__end-date-hide {
    margin-top: 26px;
    margin-bottom: 47px;
    display: flex;
    &--text {
      cursor: pointer;
      margin-right: 8px;
      color: $primary;
    }
    &--optional {
      margin-right: 8px;
      color: #999999;
    }
  }
  &__end-date-show {
    margin-top: 26px;
    margin-bottom: 42px;
    &--title {
      display: flex;
      &--optional {
        margin-left: 8px;
        color: #999999;
      }
    }
    &--date {
      display: flex;
      align-items: center;
      margin-top: 5px;
      &--clear {
        cursor: pointer;
        margin-left: 20px;
        font-weight: bold;
        color: $primary;
      }
    }
  }
  &__max-per-booking {
    margin-bottom: 20px;
    .v-input {
      width: 80px;
      margin-top: 0px;
    }
  }
  &__nav {
    border-top: 1px solid #ececec;
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
    .v-btn {
      width: 150px;
      min-width: 150px;
    }
  }
}
</style>
