var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-icon" },
    [
      _c("base-icon", {
        staticClass: "activity-icon__icon",
        attrs: { "icon-name": _vm.iconName },
      }),
      _c("div", { staticClass: "activity-icon__description" }, [
        _c("div", { staticClass: "activity-icon__title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
        _c("div", { staticClass: "activity-icon__text" }, [
          _vm._v("\n      " + _vm._s(_vm.description) + "\n    "),
        ]),
        _c(
          "div",
          { staticClass: "activity-icon__text row" },
          _vm._l(_vm.languages, function (lang, index) {
            return _c("div", { key: `lang-${index}` }, [
              index !== _vm.languages.length - 1
                ? _c(
                    "div",
                    {
                      staticClass: "activity-icon__language",
                      staticStyle: { "margin-right": "5px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(`${lang.language}, `) +
                          "\n        "
                      ),
                    ]
                  )
                : _c("div", { staticClass: "activity-icon__language" }, [
                    _vm._v(
                      "\n          " + _vm._s(`${lang.language}`) + "\n        "
                    ),
                  ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }