var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "43",
        height: "51",
        fill: "none",
        viewBox: "0 0 43 51",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "#fff",
          "fill-rule": "evenodd",
          d: "M21.5 0C9.626 0 0 9.4 0 20.996c0 9.006 5.807 16.688 13.965 19.67.301.11.556.319.71.595l5.054 9.079c.473.85 1.709.886 2.234.066l5.748-8.985c.164-.255.416-.444.708-.54C36.899 38.068 43 30.227 43 20.996 43 9.4 33.374 0 21.5 0Z",
          "clip-rule": "evenodd",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#00A1FF",
          d: "M40.496 20.858c0 10.244-8.504 18.549-18.995 18.549-10.49 0-18.995-8.305-18.995-18.55 0-10.244 8.505-18.549 18.995-18.549s18.995 8.305 18.995 18.55Z",
        },
      }),
      _c("path", {
        attrs: {
          fill: "#fff",
          d: "M30.345 15.503H27.38a.746.746 0 0 1-.483-.239l-1.304-1.958a.736.736 0 0 0-.069-.089 2.226 2.226 0 0 0-.765-.569 2.328 2.328 0 0 0-.946-.207H19.19a2.33 2.33 0 0 0-.946.207 2.224 2.224 0 0 0-.764.57.746.746 0 0 0-.069.088l-1.307 1.961a.665.665 0 0 1-.433.24v-.384a.748.748 0 0 0-.235-.541.825.825 0 0 0-.569-.224h-1.206a.825.825 0 0 0-.569.224.748.748 0 0 0-.235.541v.38h-.201c-.64 0-1.253.242-1.706.673a2.242 2.242 0 0 0-.706 1.624v9.186c0 .61.254 1.194.706 1.624.453.431 1.066.673 1.706.673h17.689c.64 0 1.253-.242 1.706-.673.452-.43.706-1.014.706-1.624V17.8c0-.61-.254-1.194-.706-1.624a2.475 2.475 0 0 0-1.706-.673ZM21.5 26.221a4.975 4.975 0 0 1-3.061-1.04 4.56 4.56 0 0 1-1.674-2.654 4.4 4.4 0 0 1 .475-3.062 4.748 4.748 0 0 1 2.407-2.08 5.05 5.05 0 0 1 3.246-.154 4.813 4.813 0 0 1 2.613 1.842c.62.883.9 1.944.792 3.002a4.506 4.506 0 0 1-1.388 2.799 4.95 4.95 0 0 1-3.41 1.347Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }