var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.optionsFrom != null && _vm.optionsTo != null
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "translation-block" },
            [
              _c("div", { staticClass: "translation-block__title" }, [
                _vm._v(_vm._s(_vm.translations.schedulesPricing.text)),
              ]),
              _vm._l(_vm.optionsSchedule, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "options-schedule" + index,
                    class: [
                      _vm.notLastBlock(index)
                        ? "translation-block__schedules"
                        : "",
                    ],
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "translation-row translation-block__container",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "translation-row__english" },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.translations.scheduleName.text)
                              ),
                            ]),
                            _c("VTextarea", {
                              attrs: { disabled: "", rows: "2" },
                              model: {
                                value: _vm.optionsFrom[index].name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.optionsFrom[index], "name", $$v)
                                },
                                expression: "optionsFrom[index].name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "translation-row__new-lang" },
                          [
                            _c("div", [_vm._v(_vm._s("\xa0"))]),
                            _c("VTextarea", {
                              attrs: { "max-length": 250, rows: "2" },
                              model: {
                                value: _vm.optionsTo[index].name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.optionsTo[index], "name", $$v)
                                },
                                expression: "optionsTo[index].name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._l(item.additional_info, function (info, indexInfo) {
                      return _c("div", { key: "additional-info" + indexInfo }, [
                        _c(
                          "div",
                          { staticClass: "translation-additional-info__title" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.optionsSchedule[index].additional_info[
                                    indexInfo
                                  ].info_type.title
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "translation-row translation-block__container",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "translation-row__english" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.translations.nameItem.text)
                                  ),
                                ]),
                                _c("VTextarea", {
                                  attrs: { disabled: "", rows: "1" },
                                  model: {
                                    value:
                                      _vm.optionsFrom[index].additional_info[
                                        indexInfo
                                      ].name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.optionsFrom[index].additional_info[
                                          indexInfo
                                        ],
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "optionsFrom[index].additional_info[indexInfo].name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "translation-row__new-lang" },
                              [
                                _c("div", [_vm._v(_vm._s("\xa0"))]),
                                _c("VTextarea", {
                                  attrs: { "max-length": 100, rows: "1" },
                                  model: {
                                    value:
                                      _vm.optionsTo[index].additional_info[
                                        indexInfo
                                      ].name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.optionsTo[index].additional_info[
                                          indexInfo
                                        ],
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "optionsTo[index].additional_info[indexInfo].name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "translation-row translation-block__container",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "translation-row__english" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.translations.description.text)
                                  ),
                                ]),
                                _c("VTextarea", {
                                  attrs: { disabled: "", rows: "2" },
                                  model: {
                                    value:
                                      _vm.optionsFrom[index].additional_info[
                                        indexInfo
                                      ].description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.optionsFrom[index].additional_info[
                                          indexInfo
                                        ],
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "optionsFrom[index].additional_info[indexInfo].description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "translation-row__new-lang" },
                              [
                                _c("div", [_vm._v(_vm._s("\xa0"))]),
                                _c("VTextarea", {
                                  attrs: { "max-length": 250, rows: "2" },
                                  model: {
                                    value:
                                      _vm.optionsTo[index].additional_info[
                                        indexInfo
                                      ].description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.optionsTo[index].additional_info[
                                          indexInfo
                                        ],
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "optionsTo[index].additional_info[indexInfo].description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _c("v-btn", {
            attrs: {
              base: "",
              name: _vm.translations.saveContinue.text,
              loading: _vm.loading,
              "loading-name": "...",
            },
            on: { click: _vm.saveTranslate },
          }),
          _vm.loading ? _c("loader") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }