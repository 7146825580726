<template>
  <div class="v-input v-input2" :class="{ 'v-input2__active': isFocus }">
    <label v-if="label" class="v-input__label">{{ label }} <span v-if="optional" class="v-input__label-optional">({{
      optional }})</span></label>
    <div v-if="subLabel" class="v-input__sub-label">
      {{ subLabel }}
    </div>
    <div class="v-input__row">
      <div v-if="prependText.length > 0" class="v-input__prepend-text">{{ prependText }}</div>
      <div :class="[prependText.length > 0 ? 'v-input__prepend-text-input' : '', 'v-input__field-wrapper']">
        <input :id="id" v-model="localModel" :type="type" :class="{ 'v-input2__input': true, 'is-invalid': error }"
          :placeholder="placeholder" :maxLength="maxLength" :disabled="isDisabled" :onkeypress="onkeypress"
          :onpaste="onpaste" @focus="gotFocus" @blur="lostFocus" />
        <div v-if="true" class="v-input2__passive">{{ virtualValue }}</div>
        <div v-if="maxLength > 0 && !hideCounter" class="v-input__counter">{{ value.length }}/{{ maxLength }}</div>
      </div>
    </div>
    <div v-if="error && errorMessage" class="validation-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/utils'

export default {
  name: 'VInput',
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    optional: { type: String, default: '' },
    subLabel: { type: String, default: '' },
    type: { type: String, default: 'text' },
    value: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '' },
    maxLength: { type: Number, default: null },
    hideCounter: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    onkeypress: { type: String, default: '' },
    onpaste: { type: String, default: '' },
    prependText: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      isFocus: false
    }
  },
  computed: {
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    virtualValue() {
      const lang = this.$cookies.get("i18n_redirected")
      return formatCurrency(this.value, lang, 'none')
    }
  },
  methods: {
    gotFocus(ev) {
      this.isFocus = true
      this.$emit('focus', ev)
    },
    lostFocus(ev) {
      this.isFocus = false
      this.$emit('blur', ev)
    }
  }
}
</script>

<style lang="scss">
.v-input2 {
  &__input {
    color: transparent;
  }

  &__passive {
    position: absolute;
    z-index: 5;
    inset: 0;
    left: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;
    max-width: calc(100% - 30px);
    overflow: hidden;
  }

  &.text-right {
    .v-input2__passive {
      justify-content: end;
      left: unset;
      right: 16px;
    }

    .v-input2__input {
      text-align: right;
    }
  }

  &.v-input2__active {
    .v-input2__input {
      color: unset;
    }

    .v-input2__passive {
      opacity: 0;
    }
  }
}
</style>
