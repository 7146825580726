var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pricing-change-modal-group-page" }, [
    _c("div", { staticClass: "pricing-change-modal-group-page__title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.getDateSpan(_vm.oldSchedule, _vm.translations)) +
          "\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "pricing-change-modal-group-page__block" },
      [
        _vm.isHavePriceError || _vm.isHaveMaxTravelersError
          ? _c("ErrorBanner", {
              staticClass:
                "change-pricing-type__error-banner change-pricing-type__error-banner--tiered",
              attrs: { errors: _vm.errorList },
            })
          : _vm._e(),
        _c("div", { staticClass: "pricing-change-modal-group-page__price" }, [
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _c(
                "div",
                {
                  staticClass: "pricing-change-modal-group-page__price--title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.translations.popupGroupThirdPageMaxTravelers.text
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _c("v-input", {
                attrs: {
                  error: _vm.$v.maxTravelers.$error,
                  onkeypress:
                    "return event.charCode >= 48 && event.charCode <= 57",
                  onpaste: "return false;",
                },
                on: {
                  input: function ($event) {
                    return _vm.checkMaxTravelersError()
                  },
                  blur: _vm.maxTravelersBlur,
                },
                model: {
                  value: _vm.$v.maxTravelers.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.maxTravelers,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.maxTravelers.$model",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pricing-change-modal-group-page__retail-price" },
            [
              _c(
                "div",
                {
                  staticClass: "pricing-change-modal-group-page__price--title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.translations.popupPersonThirdPageSuggested.text
                      ) +
                      "\n          "
                  ),
                  _c("v-question-info", {
                    attrs: {
                      description:
                        _vm.translations
                          .popupPersonThirdPageSuggestedDescription.text,
                    },
                  }),
                ],
                1
              ),
              _c("v-input2", {
                attrs: {
                  error: _vm.$v.retailPrice.$error,
                  type: "number",
                  onpaste: "return false;",
                  "prepend-text": "EUR",
                },
                on: {
                  blur: function ($event) {
                    return _vm.blurPrice()
                  },
                  input: function ($event) {
                    return _vm.changePrice()
                  },
                },
                model: {
                  value: _vm.$v.retailPrice.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.retailPrice,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.retailPrice.$model",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pricing-change-modal-group-page__get-paid" },
            [
              _c(
                "div",
                {
                  staticClass: "pricing-change-modal-group-page__price--title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.translations.popupPersonThirdPageGetPaid.text
                      ) +
                      "\n          "
                  ),
                  _c("v-question-info", {
                    attrs: {
                      description:
                        _vm.translations.popupPersonThirdPageGetPaidDescription
                          .text,
                    },
                  }),
                ],
                1
              ),
              _c("v-input", {
                attrs: {
                  value: _vm.showPrice(_vm.groupFeePrice),
                  "is-disabled": "",
                  "prepend-text": "EUR",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "change-pricing-type__nav" },
      [
        _c("v-btn", {
          attrs: { outline: "", name: _vm.translations.popupNavBack.text },
          on: { click: _vm.back },
        }),
        _c(
          "div",
          { staticClass: "nav__pagination row" },
          _vm._l(_vm.pageCount, function (index) {
            return _c("div", { key: `pageNum${index}` }, [
              _c("div", {
                staticClass: "dot dot_page",
                class: { dot_page_active: index - 1 == _vm.currentPageIndex },
              }),
            ])
          }),
          0
        ),
        _c("v-btn", {
          attrs: {
            base: "",
            name: _vm.isLastPage
              ? _vm.translations.popupNavSave.text
              : _vm.translations.popupNavNext.text,
            disabled: _vm.isHavePriceError || _vm.isHaveMaxTravelersError,
          },
          on: { click: _vm.next },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }