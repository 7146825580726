var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "selection",
      class: _vm.setClass,
      on: {
        mouseleave: function ($event) {
          _vm.isOpen = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "selection__preview",
          class: {
            selection__preview_open: _vm.isOpen,
            "selection__preview--error": _vm.error,
          },
          on: {
            click: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _vm.defaultName
            ? _c("div", { staticClass: "name" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.translates
                        ? _vm.translates[_vm.defaultName]
                        : _vm.defaultName
                    ) +
                    "\n    "
                ),
              ])
            : _c("div", { staticClass: "name selection__placeholder" }, [
                _vm._v("\n      " + _vm._s(_vm.placeholder) + "\n    "),
              ]),
          _vm._m(0),
        ]
      ),
      _vm.isOpen && !_vm.disabled
        ? _c(
            "div",
            { staticClass: "selection__expand" },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "div",
                {
                  key: `item-${index}`,
                  staticClass: "selection__item",
                  class: { item_active: item.is_active },
                  on: {
                    click: function ($event) {
                      return _vm.activeByName(item, _vm.items)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.translates ? _vm.translates[item.name] : item.name
                      ) +
                      "\n    "
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.isOpen && _vm.pickup
        ? _c(
            "div",
            { staticClass: "selection__expand" },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "div",
                {
                  key: `item-${index}`,
                  staticClass: "selection__item",
                  class: { item_active: item.is_active },
                  on: {
                    click: function ($event) {
                      return _vm.activeByPickup(index, item, _vm.items)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "arrow" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/svg/drop-down-arrow.svg"),
          alt: "drop",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }