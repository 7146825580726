var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "rootRef", staticClass: "v-select-country" },
    [
      _c(
        "country-select",
        _vm._b(
          {
            staticClass: "v-select-country__select",
            class: {
              "v-select-country__select--error": _vm.error,
              "v-select-country__select--empty": !_vm.modelValue,
            },
            attrs: { country: _vm.modelValue },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          },
          "country-select",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm.errorMessage && _vm.error
        ? _c("div", { staticClass: "v-select-country__error-message" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }