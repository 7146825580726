import { getStepIndex, getNextStep } from '@/services/registration';
import { commonSteps } from '@/constants/profileSteps';
import { stringStartsWith } from '@/utils/utils';
import { daysToSeconds } from '@/utils/date';
export const strict = false;

export const MUTATION_SET_CURRENCIES = 'setCurrencies';
export const MUTATION_SET_STEP = 'setStep';
export const MUTATION_SET_STEP_INDEX = 'setStepIndex';

export const ACTION_LOAD_CURRENCIES = 'loadCurrencies';
export const ACTION_UPDATE_STEP = 'updateStep';

export const state = () => ({
  userLogged: false,
  userInfo: {},
  userUid: '',
  headerTranslate: {},
  commonTranslate: {},
  footerTranslate: [],
  step: null,
  stepIndex: 0,
  currencies: [],
});

export const mutations = {
  SET_USER_IS_LOGIN(state) {
    state.userLogged = true;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_STEP(state, stepIndex) {
    state.step = stepIndex;
    this.$cookies.set('step', state.step);
  },
  [MUTATION_SET_STEP](state, step) {
    state.step = step;
    this.$cookies.set('step', step);
  },
  [MUTATION_SET_STEP_INDEX](state, stepIndex) {
    state.stepIndex = stepIndex;
    this.$cookies.set('stepIndex', stepIndex);
  },
  [MUTATION_SET_CURRENCIES](state, currencies) {
    state.currencies = currencies;
  },
};

export const actions = {
  getUserInfoFromCookie({ commit }) {
    // console.log('getUserInfoFromCookie')

    const userInfo = {
      email: this.$cookies.get('email'),
      password: this.$cookies.get('password'),
      is_setup: this.$cookies.get('is_setup'),
      is_password_entered: this.$cookies.get('is_password_entered'),

      // declaration
      is_seller_company: this.$cookies.get('is_seller_company'),

      // public -> your information
      seller_full: this.$cookies.get('seller_full'),
      company_name: this.$cookies.get('company_name'),
      seller_tagline: this.$cookies.get('seller_tagline'),
      bio: this.$cookies.get('bio'),
      company_site_link: this.$cookies.get('company_site_link'),
      // public -> company information (for company)
      company_trading_name: this.$cookies.get('company_trading_name'),
      contact_email: this.$cookies.get('contact_email'),
      contact_phone: this.$cookies.get('contact_phone'),
      contact_phone_code: this.$cookies.get('contact_phone_code'),

      // public -> office-information or office-info (for company)
      office_country: this.$cookies.get('office_country'),
      office_city: this.$cookies.get('office_city'),
      office_address_code: this.$cookies.get('office_address_code'),
      office_address: this.$cookies.get('office_address'),
      office_second_address: this.$cookies.get('office_second_address'),

      // public -> experience-type
      category: this.$cookies.get('category'),
      subcategory: this.$cookies.get('subcategory'),
      tags: this.$cookies.get('tags'),
      seller_place: this.$cookies.get('seller_place'),

      // public -> your-expertise
      is_seller_professional: this.$cookies.get('is_seller_professional'),
      have_license: this.$cookies.get('have_license'),
      // private -> personal-info && public -> your-expertise
      job_title: this.$cookies.get('job_title'),

      // public -> your-profile-picture or company-image (image saved in localStorage)
      company_logo: this.$cookies.get('company_logo'),
      // public -> company-image
      company_info: this.$cookies.get('company_info'),

      // private -> personal-details and personal-info
      birth: this.$cookies.get('birth'),
      personal_phone: this.$cookies.get('personal_phone'),
      personal_phone_code: this.$cookies.get('personal_phone_code'),
      // private -> personal-info
      personal_phone_type: this.$cookies.get('personal_phone_type'),
      contact_first_name: this.$cookies.get('contact_first_name'),
      contact_last_name: this.$cookies.get('contact_last_name'),

      // private -> address-information
      seller_country: this.$cookies.get('seller_country'),
      seller_city: this.$cookies.get('seller_city'),
      seller_address_code: this.$cookies.get('seller_address_code'),
      seller_address: this.$cookies.get('seller_address'),
      seller_second_address: this.$cookies.get('seller_second_address'),

      // private -> booking-management
      reservation_system_type: this.$cookies.get('reservation_system_type'),
    };

    // console.log('userInfo = ', userInfo)

    commit('SET_USER_INFO', userInfo);
  },
  async loadUserInfo({ commit, dispatch }) {
    console.log('LOAD USER INFO');
    const token = this.$cookies.get('ulkid');
    const userType = this.$cookies.get('userlk');

    if (userType === 'client' && token) {
      console.log('get user from api');
      const userInfo = await this.$axios.$get('/api/v1/users/seller');
      commit('SET_USER_INFO', userInfo);
    } else if (userType === undefined) {
      dispatch('getUserInfoFromCookie');
    }
  },
  async nuxtServerInit(store, server) {
    const { state, dispatch } = store;
    const { redirect, route, localePath, getRouteBaseName } = server;
    
    dispatch(ACTION_LOAD_CURRENCIES);

    const token = this.$cookies.get('ulkid');
    const userType = this.$cookies.get('userlk');

    if (userType === 'client' && token) {
      try {
        this.$axios.setToken(token, 'JWT');
        const userInfo = await this.$axios.$get('/api/v1/users/seller');
        state.userInfo = userInfo;
      } catch (error) {
        console.error(error)
      }
    } else if (userType === undefined && token) {
      this.$axios.setToken(token, 'Token');
      dispatch('getUserInfoFromCookie');
    }

    this.$cookies.set('setupAccount', state.userInfo.is_setup);
    state.userUid = token;
    state.userLogged = !(
      this.$cookies.get('userlk') === undefined ||
      this.$cookies.get('userlk') === undefined ||
      token == undefined
    );
    const lang = this.$cookies.get('i18n_redirected');
    const currentRouteName = getRouteBaseName(route);
    // пользователь не вошёл
    if (!state.userLogged) {
      const isUserSetup = state.userInfo.is_setup;
      const supplierAgreementRoute = 'supplier-agreement';

      const unauthorizedRoutes = [
        'reset-password',
        'supplier-agreement',
        'code-of-conduct',
        'e-tickets',
        'login',
        'account-setup',
        'help'
      ];
      if (!stringStartsWith(currentRouteName, unauthorizedRoutes)) {
        const authorizationCodeParam = route.query.authorization_code;
        let redirectPath = '/login';
        if (authorizationCodeParam) {
          redirectPath += '?authorization_code=' + authorizationCodeParam;
        }
        redirect(localePath(redirectPath));
      }
      if (
        isUserSetup === false &&
        currentRouteName !== supplierAgreementRoute &&
        currentRouteName !== 'help-category'
      ) {
        if (!this.$cookies.get('step')) {
          dispatch(ACTION_UPDATE_STEP, commonSteps[0]);
          redirect(localePath({ name: state.step.routeName }));
        } else {
          dispatch(ACTION_UPDATE_STEP, this.$cookies.get('step'));
          redirect(localePath({ name: state.step.routeName }));
        }
      }
    }
    // пользователь вошёл
    else if (currentRouteName === 'login') {
      redirect(localePath('/'));
    }

    // #region HEADER TRANSLATE
    const headerTranslate = await this.$axios.$get(`/api/v1/pages/lk-header?language=${lang}`);
    state.headerTranslate = headerTranslate.fields;
    if (
      route.name === localePath({ name: 'login' }).name ||
      route.panameth === localePath({ name: 'reset-password' }).name
    ) {
      state.footerTranslate = await this.$axios.$get(`/api/v1/pages/lk-footer?language=${lang}`);
    }
    // #endregion HEADER TRANSLATE
    const commonTranslate = await this.$axios.$get(`/api/v1/pages/lk-common?language=${lang}`);
    state.commonTranslate = commonTranslate.fields;
  },
  async reloadTranslations({ state, dispatch }) {
    dispatch('headerTranslation');
    dispatch('footerTranslation');
    const lang = this.$cookies.get('i18n_redirected');
    const commonTranslate = await this.$axios.$get(`/api/v1/pages/lk-common?language=${lang}`);
    state.commonTranslate = commonTranslate.fields;
  },
  async logout({ state, dispatch }) {
    state.userLogged = false;
    const lang = this.$cookies.get('i18n_redirected');
    const isTrusted = this.$cookies.get('trusted_device');
    this.$cookies.removeAll();
    this.$cookies.set('i18n_redirected', lang, { maxAge: daysToSeconds(30) });
    this.$cookies.set('trusted_device', isTrusted, { maxAge: daysToSeconds(30) });
    window.location.href = this.localePath('/login');
  },
  async headerTranslation({ state, $axios }) {
    const lang = this.$cookies.get('i18n_redirected');
    const headerTranslate = await this.$axios.$get(`/api/v1/pages/lk-header?language=${lang}`);
    state.headerTranslate = headerTranslate.fields;
  },
  async footerTranslation({ state, $axios }) {
    const lang = this.$cookies.get('i18n_redirected');
    state.footerTranslate = await this.$axios.$get(`/api/v1/pages/lk-footer?language=${lang}`);
  },

  setUserIsLogin({ commit }) {
    commit('SET_USER_IS_LOGIN');
  },

  nextStep({ state, dispatch }, step) {
    const nextStep = getNextStep(step || state.step, state.userInfo);

    dispatch(ACTION_UPDATE_STEP, nextStep);
  },

  [ACTION_UPDATE_STEP]({ state, commit }, step) {
    const index = getStepIndex(step, state.userInfo);

    commit(MUTATION_SET_STEP_INDEX, index);
    commit(MUTATION_SET_STEP, step);
  },

  async loadCurrencies({ commit, $axios }) {
    const currencies = await this.$axios.$get('/api/v1/currency/');
    commit(MUTATION_SET_CURRENCIES, currencies);
  },
};
