<template>
  <div class="translations-list">
    <div class="translations-list__title">{{ translations.headerTranslations?.text }}</div>
    <div class="translations-list__desc">{{ translations.warnReview?.text }}.</div>

    <div v-for="(item, index) in getTranslateLanguages" :key="index" class="translate-card">
      <div class="translate-card__title">{{ item.name }}</div>
      <div @click="goToPage(item.code)"><base-icon icon-name="Edit" /></div>
    </div>
  </div>
</template>

<script>
import VBtn from '@/components/controls/VBtn.vue';
import translateSelect from '@/components/pop-up/translateSelect.vue';
import BaseIcon from '@/components/common/BaseIcon.vue';
import VConfirmDialog from '@/components/VConfirmDialog.vue';

const LANGUAGES = {
  spain: 'Spain',
  germany: 'Germany',
  english: 'English',
};
const LANGUAGES_CODES = {
  [LANGUAGES.english]: 'en',
  [LANGUAGES.spain]: 'es',
  [LANGUAGES.germany]: 'de',
};

export default {
  components: {
    VBtn,
    translateSelect,
    BaseIcon,
    VConfirmDialog,
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    selectTranslations: { type: Object, default: () => ({}) },
  },
  data: () => ({
    translateSelect: false,
  }),
  computed: {
    languagesTranslations() {
      return {
        [LANGUAGES.spain]: this.selectTranslations.selection1.text,
        [LANGUAGES.germany]: this.selectTranslations.selection2.text,
      };
    },

    getTranslateLanguages() {
      console.log(LANGUAGES_CODES);
      const languages = [
        {
          code: LANGUAGES_CODES.English,
          name: this.selectTranslations.selection3.text,
        },
        {
          code: LANGUAGES_CODES.Spain,
          name: this.selectTranslations.selection1.text,
        },
        {
          code: LANGUAGES_CODES.Germany,
          name: this.selectTranslations.selection2.text,
        },
      ];
      console.log(languages);
      console.log(this.tour.input_language.abbreviation);
      return languages.filter((el) => el.code.toLowerCase() !== this.tour.input_language.abbreviation.toLowerCase());
    },

    getLanguages() {
      console.log(this.tour.work_translate);
      return this.tour.work_translate.map((language) => {
        return {
          name: this.languagesTranslations[language] || language,
          language,
          abbreviation: LANGUAGES_CODES[language],
        };
      });
    },
  },
  methods: {
    async deleteItem(language) {
      const needDelete = await this.$refs.deleteDialog.open();
      if (needDelete) {
        await this.$axios
          .$post(`api/v1/delete-tour-language/?tour_id=${this.tour.id}&language=${language.abbreviation}`)
          .then((res) => {
            this.$emit('deleteItem', language.language);
          });
      }
    },

    goToPage(language) {
      this.$router.push(
        this.localePath(`/product/${this.tour.slug}/translations/product-descriptions?lang=${language}`)
      );
    },
  },
};
</script>

<style lang="scss">
.translations-list {
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  &__desc {
    font-size: 18px;
    font-weight: 500;
    width: 555px;
    margin-bottom: 35px;
  }
  &__btn {
    margin-top: 35px;
  }
  .translate-card {
    border: 1px solid #ececec;
    border-radius: 14px;
    padding: 20px;
    width: 335px;
    margin-top: 17px;
    margin-bottom: 17px;
    display: flex;
    &__title {
      font-size: 18px;
      line-height: 21px;
      width: 300px;
    }
    svg {
      color: $dark-blue;
      margin-left: 8px;
      &:hover {
        color: $primary;
        cursor: pointer;
      }
    }
  }
}
.product-descriptions {
  &__content {
    .translation-block {
      &__container {
        margin-left: 0;
      }
    }
  }
}
</style>
